import DataUtil from "../../../common/dataUtil";
import NodeFocus from "../develop/function/editor/proc/focus/nodeFocus";
import VariableChooser from "../develop/function/editor/proc/variableChooser";
import PrefixUtil from "../develop/function/util/prefixUtil";
import ReaderUtil from "./readerUtil";

namespace SystemFunctionUtil {

    export type DefName =
        'sys_date_time' |
        'sys_date' |
        'sys_time' |
        'invalidate' |
        'set'|
        'get'
        ;

    export const getMappedFuncValue = (name: DefName, appCache: ReaderUtil.GlobalAppCache) => {
        switch (name) {
            case 'sys_date_time': return DataUtil.getSystemTime();
            case 'sys_date': return DataUtil.getSystemDate();
            case 'sys_time': return '';
            case 'invalidate': return appCache.dynamics.utils.partialInvalidate;
            case 'set': return (key: string, value: any) => set(key, value, appCache);
            case 'get': return (key: string) => get(key, appCache);
        }
    }

    export const set = (key: string, value: any, appCache: ReaderUtil.GlobalAppCache) => {
        // console.log(appCache.dynamics);
        const items = key.split('.');
        const prefix = items[0];
        const rootId = items[1];
        const levelProps = items.slice(2);
        const getTarget = (): VariableChooser.RootTargetType => {
            switch (prefix) {
                case PrefixUtil.STATE: return 'state';
                case PrefixUtil.VARIABLE: return 'variable';
                default: throw new Error(`prefixが何にも該当しない[${prefix}]`);
            }
        }
        const focus: NodeFocus.Data = {
            target: getTarget(),
            rootId,
            levelProps,
            items: []
        }
        const focusUtil = ReaderUtil.getChoosedFocusUtil(focus, appCache);
        focusUtil.executeAssign(value);
    }
    export const get = (key: string, appCache: ReaderUtil.GlobalAppCache) => {
        // console.log(appCache.dynamics.componentStates);
        const items = key.split('.');
        const prefix = items[0];
        const rootId = items[1];
        const levelProps = items.slice(2);
        const getTarget = (): VariableChooser.RootTargetType => {
            switch (prefix) {
                case PrefixUtil.STATE: return 'state';
                case PrefixUtil.VARIABLE: return 'variable';
                default: throw new Error(`prefixが何にも該当しない[${prefix}]`);
            }
        }
        const focus: NodeFocus.Data = {
            target: getTarget(),
            rootId,
            levelProps,
            items: []
        }
        const focusUtil = ReaderUtil.getChoosedFocusUtil(focus, appCache);
        return focusUtil.getFocusObject();
    }
}

export default SystemFunctionUtil;