import assert from "assert";
import TreeUtil from "../../../../../common/component/tree/treeUtil";
import DataUtil from "../../../../../common/dataUtil";
import ValidateUtil from "../../../../../common/component/form/validateUtil";
import ModelElementUtil from "./modelElementUtil";
import NodeUpdate from "../editor/var/nodeUpdate";
import VariableChooser from "../editor/proc/variableChooser";
import NodeField from "../editor/var/nodeField";
import ReaderUtil from "../../../gui/readerUtil";
import NodeCompuse from "../editor/ui/nodeCompuse";

namespace ModelUtil {

    // export const FieldTypes = ['text', 'number', 'multiline', 'list', 'checkbox', 'time', 'model'] as const;
    // export type FieldType = typeof FieldTypes[number];

    // export const TimeTypes = ['date-only', 'time-only', 'full'] as const;
    // export type TimeType = typeof TimeTypes[number];
    /**
     * IDに利用可能な文字列チェック
     * @param str 検証する文字列
     * @returns 判定結果
     */
    export const checkIdChars = (str: string) => {
        // 1文字目が数値でないこと
        const isNoHeadNumber = !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].
            includes(str.substring(0, 1));
        const list = '0123456789abcdefghijklmnopqrstuvwxyz_';
        return isNoHeadNumber && ValidateUtil.checkIncludesChars(str, list);
    }

    export const checkCamelCase = (str: string) => {
        const head = str.substring(0, 1);
        // 1文字目が数値でないこと
        const isNoHeadNumber = !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].
            includes(head);

        const isHeadUpper = () => {
            return head === head.toUpperCase();
        }
        const list = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        return !isHeadUpper() && isNoHeadNumber && ValidateUtil.checkIncludesChars(str, list);
    }

    export const checkPascalCase = (str: string) => {
        const head = str.substring(0, 1);
        // 1文字目が数値でないこと
        const isNoHeadNumber = !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].
            includes(head);

        const isHeadUpper = () => {
            return head === head.toUpperCase();
        }
        const list = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        return isHeadUpper() && isNoHeadNumber && ValidateUtil.checkIncludesChars(str, list);
    }

    export type ManageItems = {
        focusNode: TreeUtil.ElementNode;
        setFocusNode: (node: TreeUtil.ElementNode) => void;
        // model: StoreProject.Props;
        invalidate: () => void;
    }

    export type NodeType =
        'project' |
        'elements' |
        'retention' |
        // 'regulation' |
        'release' |
        'launchers' |
        'launcher' |
        'permissions' |
        'permission' |
        'block' |
        'apps' |
        'compdef' |
        'tagdef' |
        'props' |
        'prpfld' |
        'prpclbk' |
        'app' |
        'lncargs' |
        'lncarg' |
        'declares' |
        'structs' |
        'struct' |
        'field' |
        'store' |
        'states' |
        'state' |
        'initial' |
        'return' |
        'native' |
        'log' |
        'func' |
        'execute' |
        'args' |
        'arg' |
        'proc' |
        'update' |
        'assignbak' |
        'focus' |
        'arrdel' |
        'arradd' |
        'arrcat' |
        'arreff' |
        'arrsort' |
        'assign' |
        'callback' |
        'asnmtch' |
        'mtchidx' |
        'fetches' |
        'fetch' |
        'then' |
        'catch' |
        'entry' |
        'tag' |
        'custtagdef' |
        'custtaguse' |
        'compuse' |
        'child' |
        'iterate' |
        'continue' |
        'break' |
        'tabs' |
        'span' |
        'button' |
        'input' |
        'table-mng' |
        'table' |
        'border' |
        'accept' |
        'case' |
        'switch' |
        'bool' |
        'when' |
        'variable' |
        'style' |
        'stlarg' |
        'common' |
        'styles' |
        'tags' |
        'funcs' |
        'comps' |
        'temp' |
        'trigger' |
        'text' |
        'effect' |
        'invalidate' |
        'promise' |

        // ダミー用
        'xxx'
        ;

    export type WrapElement = {
        type: NodeType;
        data: any;
        disabled?: true;
    }
    export interface GenericElements {
        elements: WrapElement[];
    }
    export type NodeGenericMngs = {
        mngs: WrapElement[];
    }
    export type NodeGenericItems = {
        items: WrapElement[];
    }
    export type NodeElements = {
        elements: WrapElement[];
    }
    export type NodeMngTop = {
        mngs: WrapElement[];
    }
    export type NodeGlobal = {
        mngs: WrapElement[];
    }
    export type NodeApps = {
        apps: WrapElement[];
    }
    export type NodeComps = {
        comps: WrapElement[];
    }
    export type NodeFields = {
        fields: WrapElement[];
    }
    export type NodeProps = {
        props: WrapElement[];
    }
    export type NodeAuthorityMng = {
    }
    export interface NodeModelField extends Field {
        id: string;
    }
    export type NodeBoolData = {
        bool: boolean;
        elements: ModelUtil.WrapElement[];
    }

    export const getFieldListItem = (f: ModelUtil.NodeModelField) => {
        // console.log(f);
        const type = f.structId == undefined ? f.dataType : f.structId;
        const array = '[]'.repeat(f.array);
        // const labelText = `${f.id}: @${type}${array}`;
        const labelText = getFieldType(f);
        return { value: f.id, labelText }
    }

    export const getField = (f: ModelUtil.Field) => {
        // const type = f.structId == undefined ? f.dataType : f.structId;
        let type = f.dataType;
        if (f.structId != undefined) {
            type += ('.' + f.structId);
        }
        const array = '[]'.repeat(f.array);
        return `@${type}${array}`;
    }
    export const getFieldType = (f: ModelUtil.NodeModelField) => {
        const type = f.structId == undefined ? f.dataType : f.structId;
        const array = '[]'.repeat(f.array);
        return `${f.id}: @${type}${array}`;
    }

    export const getCacheListItem = (v: NodeField.VariableData) => {
        const labelText = v.id;
        return { value: v.id, labelText }
    }

    export type NodeInitial = {
        items: WrapElement[];
    }
    export const DataTypes = ['string', 'number', 'boolean', 'color', 'struct', 'function', 'any'] as const;
    // export type DataType = 'string' | 'number'| 'boolean'| 'color'| 'struct'| 'name';
    export type DataType = typeof DataTypes[number];

    export type NodeFormula = {
        id: string;
        args: string[];
        source: string;
    }
    export interface ArgsData {
        args: WrapElement[];
    }
    export type NodeProcedure = {
        items: WrapElement[];
    }
    // const ProcTypeModState = 'modify'
    // export const ProcMethods = ['modify-state', 'fetch-dao'] as const;
    // export type ProcMethod = typeof ProcMethods[number];

    export interface NodeAssignBak extends VariableChooser.Chooser {
        dest: string;
        cloneType?: NodeUpdate.CloneType;
    }

    export type NodeRetention = {
        items: WrapElement[];
    }
    export const CatcheMethods = ['clone', 'build'] as const;
    // export type CacheMethod = 'clone' | 'build';
    export type CacheMethod = typeof CatcheMethods[number];

    export interface Field {
        dataType: DataType;
        array: number;
        structId?: string;
    }

    export const isObjectField = (field: Field) => {
        const OBJECT_TARGETS: ModelUtil.DataType[] = ['struct', 'any', 'function'];
        return field.array >= 1 || OBJECT_TARGETS.includes(field.dataType);
    }

    export interface Variables {
        states: NodeModelField[];
        caches: NodeModelField[];
        propFields: NodeModelField[];
        funcargs?: NodeModelField[];
        structs: ReaderUtil.StructObject[];
    }

    export type CloneProps = {
        target: VariableChooser.RootTargetType;
        rootId: string;
        levelProps: string[];
    }

    export type NodeButton = {
        label: string;
        width: number;
        designId: string;
        actionId?: string;
    }
    export type NodeSetup = {
        mngs: WrapElement[];
    }

    /** Divのレイアウト指定方法 */
    export const LayoutTypes = ['wrap', 'free'] as const;
    export type LayoutType = typeof LayoutTypes[number];

    /** Divの子要素の横の詰め方 */
    export const DivAlignTypes = ['left', 'center', 'right'] as const;
    export type DivAlignType = typeof DivAlignTypes[number];

    /** Divのレイアウト「wrap」選択時のプロパティ */
    export type DivWrapProps = {
        margin: number;
        border?: DivBorder;
    }
    /** Divのボーダーのプロパティ */
    export type DivBorder = {
        size: number;
        color: string;
        roundSize: number;
    };
    /** Divのレイアウト「free」選択時のプロパティ */
    export type DivFreeProps = {
        wRate: number;
        wPixel: number;
        hRate: number;
        hPixel: number;
        left: number;
        top: number;
    }
    /**
     * Span要素
     */
    export type NodeSpan = {
        text: string;
        fontSize: number;
        fontColor: string;
        bgColor?: string;
    }

    export type NodeTabs = {
        tabIndex?: string;
        tabs: WrapElement[];
    }


    export type NodeTableMng = {
        requestId?: string;
        mngs: WrapElement[];
    }

    export type NodeFormMng = {
        requestId?: string;
        mngs: WrapElement[];
    }
    export type NodeTextMng = {
        requestId?: string;
        mngs: WrapElement[];
    }

    export type NodeTab = {
        label: string;
        enableCondition: string;
        requestId?: string;
        elements: WrapElement[];
    }
};

export default ModelUtil;
