import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ModelUtil from "../../util/modelUtil";
import { GlobalContext } from "../../../../entry/systemEntry";
import ModelEditDialog from "../modelEditDialog";
import ModelElementUtil from "../../util/modelElementUtil";
import EditorUtil from "../ui/common/editorUtil";

namespace NodeLog {

    type LocalState = {
        method: FormUtil.CheckableValue;
        fml: FormUtil.CheckableValue;
    }

    export const Methods = ['console', 'alert'] as const;
    // export type Method = 'console' | 'alert';
    export type Method = typeof Methods[number];
    export type Data = {
        method: Method;
        fml: string;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const [ls, setLs] = useState<LocalState>({
            method: { value: 'console', errors: [] },
            fml: { value: '', errors: [] },
        });
        const invalidate = () => setLs({ ...ls });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        useEffect(() => {

            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                ls.method.value = data.method;
                ls.fml.value = data.fml;
            }
            invalidate();
        }, []);

        const targetFroms = [
            ls.method, ls.fml,
        ];

        useEffect(() => {
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            const data: Data = {
                method: ls.method.value as Method,
                fml: ls.fml.value,
            }
            setTempData(data);
        }, [ls]);

        return (<>
            <FormUtil.FormRecord
                titleLabel="Method"
                jsx={<FormUtil.Combobox
                    width={300}
                    checkable={ls.method}
                    setCheckable={(checkable) => {
                        ls.method = checkable;
                        invalidate();
                    }}
                    list={Methods.map(item => ({
                        value: item
                    }))}
                    isEnabled={true}
                />}
            />
            <EditorUtil.FormulaEditor checkable={ls.fml} invalidate={invalidate} />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'log';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeLog;