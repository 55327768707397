import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../../abstractModelEditor";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../../common/component/form/validateUtil";
import TreeUtil from "../../../../../../../common/component/tree/treeUtil";
import ModelUtil from "../../../util/modelUtil";
import DataUtil from "../../../../../../../common/dataUtil";
import { GlobalContext } from "../../../../../entry/systemEntry";
import ModelEditDialog from "../../modelEditDialog";
import ModelElementUtil from "../../../util/modelElementUtil";
import assert from "assert";
import VariableChooser from "../variableChooser";
import NodeUpdate from "../../var/nodeUpdate";
import NodeFocus from "./nodeFocus";
import DirectForm from "./directForm";
import EditorUtil from "../../ui/common/editorUtil";

namespace NodeAssign {

    export const CloneTypes = ['shallow', 'deep'] as const;
    // export type CloneTypes = 'cache' | 'direct';
    export type CloneType = typeof CloneTypes[number];

    export interface ReferValue extends VariableChooser.Chooser {
        cloneType?: CloneType;
    }

    export interface Data {
        assignType: NodeFocus.AssignType;
        refer?: ReferValue;
        formula?: string;
        direct?: string;
    }

    const INITIAL_ASSIGN_TYPE: NodeFocus.AssignType = 'refer';

    type LocalState = {
        assignType: FormUtil.CheckableValue;
        direct: FormUtil.CheckableValue;
        formula: FormUtil.CheckableValue;
        rootTarget: FormUtil.CheckableValue;
        rootId: FormUtil.CheckableValue;
        innerFieldForms: VariableChooser.InnerField[];
        cloneType: FormUtil.CheckableValue;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const [ls, setLs] = useState<LocalState>({
            assignType: { value: INITIAL_ASSIGN_TYPE, errors: [] },
            direct: { value: '', errors: [] },
            formula: { value: '', errors: [] },
            rootTarget: { value: '', errors: [] },
            rootId: { value: '', errors: [] },
            innerFieldForms: [],
            cloneType: { value: '', errors: [] },
        });
        const invalidate = () => setLs({ ...ls });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        // const [focusInfo, structs, states, funcargs, caches, propFields] = useMemo(() => {
        const [focusInfo, structs, states, variables] = useMemo(() => {

            const cur = manageItems.focusNode;

            // const scope = store.system.scope;
            // const stateValueKeys = scope.stateValueKeys as ScopeManager.ValueKeyField[];

            let variables = ModelElementUtil.getRetentionDatasFromCurrent(cur, 'variable');
            // .concat(ModelElementUtil.getFetchThensFromCurrent(cur));
            // console.log(caches);
            const states = ModelElementUtil.getReferableStates(cur);
            const structs = ModelElementUtil.getReferableStructs(cur);

            const isDispatchSub = () => ModelElementUtil.isFunctionSub(cur);
            const funcargs = !isDispatchSub() ? undefined : ModelElementUtil.getArgumentFromCurrent(cur);
            const propFields = ModelElementUtil.getPropFieldsFromCurrent(cur);

            variables = variables.concat(funcargs ?? [], propFields);

            const focusInfo = NodeFocus.getFocusInfo(cur, {
                // states, variables: caches, prpflds: propFields, funcargs, structs: dtypes
                states, variables, structs: structs
            });

            // return [focusInfo, structs, states, funcargs, variables, propFields];
            return [focusInfo, structs, states, variables];
        }, []);

        const isObject = ModelUtil.isObjectField(focusInfo.lastField);
        const nowAssignType = ls.assignType.value as NodeFocus.AssignType;
        const isObjectTarget = nowAssignType === 'refer' && ModelUtil.isObjectField(focusInfo.lastField);

        // console.log(caches);
        const arg: VariableChooser.Argument = {
            // localState: ls, states, variables: caches, structs: dtypes, propFields, funcargs
            localState: ls, states, variables: variables, structs
        }

        useEffect(() => {

            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                ls.assignType.value = data.assignType;
                // if (data.objVal != undefined) {
                //     VariableChooser.mappingDataToForm(arg, data.objVal);
                //     localState.cloneType.value = data.objVal.cloneType ?? '';
                //     // console.log(localState.cloneType.value);
                // } else if (data.fmlVal != undefined) {
                //     localState.directVal.value = data.fmlVal;
                // }
                switch (data.assignType) {
                    case 'refer': {
                        if (data.refer != undefined) {
                            VariableChooser.mappingDataToForm(arg, data.refer);
                            ls.cloneType.value = data.refer.cloneType ?? '';
                            // console.log(localState.cloneType.value);
                        }
                    } break;
                    case 'direct': {
                        if (data.direct != undefined) {
                            ls.direct.value = data.direct;
                        }
                    } break;
                    case 'formula': {
                        if (data.formula != undefined) {
                            ls.formula.value = data.formula;
                        }
                    } break;
                    case 'initial': {
                    } break;
                }
            }
            invalidate();
        }, []);

        const [last, requestType, directName] = useMemo(() => {
            const last = VariableChooser.getLastFieldInfo(arg);

            const getRequestType = (): null | ModelUtil.Field => {
                if (last == null) return null;
                let array = last.array;
                if (last.isIndex) array--;
                return {
                    dataType: last.dataType,
                    array,
                    structId: last.structId
                };
            }

            const requestType = getRequestType();

            let directName = '-';
            if (requestType != null) {
                directName = requestType.dataType;
                // switch (requestType.dataType) {
                //     case 'string': directName = 'String'; break;
                //     case 'color': directName = 'Color'; break;
                //     case 'number': directName = 'Number'; break;
                //     case 'boolean': directName = 'Boolean'; break;
                //     case 'any': directName = 'Any'; break;
                // }
            }
            return [last, requestType, directName];
        }, [ls.rootId, ls.innerFieldForms, ls.direct]);
        const isObjectAssign = requestType != null && ModelUtil.isObjectField(requestType);

        const [compareMsg, isMatch] = useMemo(() => {
            const focusDataType = ModelUtil.getField(focusInfo.lastField);
            const assignDataType = requestType == null ? '...?' : ModelUtil.getField(requestType);
            const isMatch = focusDataType === assignDataType;
            return [`${focusDataType} ← ${assignDataType}`, isMatch];
        }, [requestType]);

        const directForms = [ls.direct];
        const formulaForms = [ls.formula];
        const referForms = useMemo(() => {
            return [ls.rootId, ls.cloneType].concat(ls.innerFieldForms.map(inner => inner.form));
        }, [ls.innerFieldForms]);
        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            // if (targetFroms.find(form => form.errors.length > 0) != undefined
            // ) {
            //     setInputOK(false);
            //     return;
            // }
            // 1つでも入力エラーがあると処理しない
            switch (nowAssignType) {
                case 'refer': {
                    // console.log(isMatch);
                    // if (referForms.find(form => form.errors.length > 0) != undefined
                    //     || !isMatch
                    // ) {
                    if (!isMatch) {
                        // console.log(referForms.map(r => r.errors));
                        setInputOK(false);
                        return;
                    }
                } break;
                case 'direct': {
                    if (directForms.find(form => form.errors.length > 0) != undefined) {
                        setInputOK(false);
                        return;
                    }
                } break;
                case 'formula': {
                    if (formulaForms.find(form => form.errors.length > 0) != undefined) {
                        setInputOK(false);
                        return;
                    }
                } break;
                case 'initial': {
                } break;
            }
            setInputOK(true);

            const levelProps: string[] = ls.innerFieldForms.map(inner => {
                return inner.form.value;
            });

            let refer: undefined | ReferValue = undefined;
            let direct: undefined | string = undefined;
            let formula: undefined | string = undefined;
            // if (isObject) {
            //     objVal = {
            //         target: localState.rootTarget.value as VariableChooser.RootTargetType,
            //         rootId: localState.rootId.value,
            //         levelProps,
            //         cloneType: DataUtil.blankToUndefined(localState.cloneType.value) as NodeUpdate.CloneType | undefined
            //     }
            // } else {
            //     // console.log('set: ' + localState.directVal.value);
            //     directVal = localState.directVal.value;
            // }

            switch (nowAssignType) {
                case 'refer': {
                    refer = {
                        target: ls.rootTarget.value as VariableChooser.RootTargetType,
                        rootId: ls.rootId.value,
                        levelProps,
                        cloneType: DataUtil.blankToUndefined(ls.cloneType.value) as NodeUpdate.CloneType | undefined
                    }
                } break;
                case 'direct': {
                    direct = ls.direct.value;
                } break;
                case 'formula': {
                    formula = ls.formula.value;
                } break;
                case 'initial': {
                } break;
            }
            const data: Data = {
                assignType: ls.assignType.value as NodeFocus.AssignType,
                refer,
                direct,
                formula
            }
            setTempData(data);
            // }, [...directForms, localState.directVal, pickoutForms, isMatch]);
        }, [...directForms, ls, referForms, isMatch]);

        return (<>
            <NodeFocus.Refer
                focusInfo={focusInfo}
            />
            <FormUtil.BorderPanel
                title="method"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Assign Type"
                        jsx={<FormUtil.Combobox
                            width={210}
                            checkable={ls.assignType}
                            setCheckable={(checkable) => {
                                ls.assignType = checkable;
                                invalidate();
                            }}
                            extend={() => {
                                // console.log('change');
                                const type = focusInfo.lastField.dataType;
                                if (ls.assignType.value as NodeFocus.AssignType === 'refer') {
                                    ls.direct.value = '';
                                    (ls.cloneType.value as NodeUpdate.CloneType) = 'shallow';
                                } else {
                                    ls.rootTarget.value = '';
                                    ls.rootId.value = '';
                                    ls.innerFieldForms = [];
                                    if (type === 'boolean') ls.direct.value = String(false);
                                }
                            }}
                            list={NodeFocus.AssignTypes
                                .filter(t => !(isObject && t === 'direct'))
                                .map(item => {
                                    return { value: item, labelText: item }
                                })}
                        />}
                    />
                </>}
            />
            {(() => {
                switch (nowAssignType) {
                    case 'direct': return (
                        <FormUtil.BorderPanel
                            title="direct"
                            innerJsx={
                                <DirectForm.Component
                                    focusInfo={focusInfo}
                                    localState={ls}
                                    invalidate={invalidate}
                                    isObject={isObject}
                                    structs={structs}
                                />
                            }
                        />
                    );
                    case 'formula': return (
                        <FormUtil.BorderPanel
                            title="formula"
                            innerJsx={
                                <EditorUtil.FormulaEditor checkable={ls.formula} invalidate={invalidate} />
                            }
                        />
                    );
                    case 'refer': return (
                        <VariableChooser.Component
                            arg={arg}
                            invalidate={invalidate}
                        />
                    );
                    case 'initial': return (
                        <></>
                    );
                }
            })()}
            <FormUtil.BorderPanel
                title="object matching"
                isVisible={nowAssignType === 'refer'}
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Compare"
                        jsx={<FormUtil.FixedText
                            value={compareMsg}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Status"
                        jsx={<FormUtil.FixedText
                            value={isMatch ? 'OK' : 'NG'}
                        />}
                    />
                </>}
            />
            <FormUtil.BorderPanel
                title="extend"
                isVisible={isObjectTarget}
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Instance"
                        jsx={<FormUtil.Combobox
                            width={210}
                            checkable={ls.cloneType}
                            setCheckable={(checkable) => {
                                ls.cloneType = checkable;
                                invalidate();
                            }}
                            headBlank
                            list={NodeUpdate.CloneTypes.map(item => {
                                return { value: item, labelText: item }
                            })}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'assign';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeAssign;
