
import { useEffect, useState, useContext, useMemo } from "react";
import AbstractModelEditor from "../../abstractModelEditor";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ModelUtil from "../../../util/modelUtil";
import ModelEditDialog from "../../modelEditDialog";
import { GlobalContext } from "../../../../../entry/systemEntry";
import assert from "assert";
import ScopeManagerBak from "../../../option/scopeManagerBak";
import DataUtil from "../../../../../../../common/dataUtil";
import EditorUtil from "../common/editorUtil";
namespace NodeInnerText {

    export const Methods = ['direct', 'formula'] as const;
    export type Method = typeof Methods[number];

    type LocalState = {
        method: FormUtil.CheckableValue;
        direct: FormUtil.CheckableValue;
        fml: FormUtil.CheckableValue;
    }

    export type Data = {
        direct?: string;
        fml?: string;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;
        const [valueKeys, styleItems, dispatchItems] = useMemo(() => {

            const scope = store.system.scope;
            const functionItems = scope.functionItems as ScopeManagerBak.FunctionItem[];
            const stateValueKeys = scope.stateValueKeys as ScopeManagerBak.ValueKeyField[];
            const cacheValueKeys = scope.variableValueKeys as ScopeManagerBak.ValueKeyField[];
            const valueKeys = stateValueKeys
                .concat(cacheValueKeys)

            const styleItems = scope.styleItems as ScopeManagerBak.StyleItem[];
            return [valueKeys, styleItems, functionItems];
        }, []);

        const [ls, setLs] = useState<LocalState>({
            method: { value: 'direct', errors: [] },
            direct: { value: '', errors: [] },
            fml: { value: '', errors: [] },
        });
        const invalidate = () => setLs({ ...ls });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                const getMethod = (): Method => {
                    return data.direct != undefined ? 'direct' : 'formula';
                }
                ls.method.value = getMethod();
                ls.direct.value = data.direct ?? '';
                ls.fml.value = data.fml ?? '';
                invalidate();
            }
        }, []);

        const targetFroms = [
            ls.method,
        ];
        switch (ls.method.value as Method) {
            case 'direct': targetFroms.push(ls.direct); break;
            case 'formula': targetFroms.push(ls.fml); break;
        }

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            const data: Data = {
                direct: DataUtil.blankToUndefined(ls.direct.value),
                fml: DataUtil.blankToUndefined(ls.fml.value),
            }
            setTempData(data);
        }, [ls]);

        return (<>
            <FormUtil.BorderPanel
                title="Info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Method"
                        jsx={<FormUtil.Combobox
                            list={Methods.map(m => ({ value: m }))}
                            width={400}
                            checkable={ls.method}
                            setCheckable={(checkable) => {
                                ls.method = checkable;
                                invalidate();
                            }}
                            extend={(value) => {
                                switch (value as Method) {
                                    case 'direct': ls.fml.value = ''; break;
                                    case 'formula': ls.direct.value = ''; break;
                                }
                            }}
                        />}
                    />
                </>}
            />
            <FormUtil.BorderPanel
                title="inner text"
                innerJsx={<>
                    {(() => {
                        switch (ls.method.value as Method) {
                            case 'direct': return (
                                <FormUtil.FormRecord
                                    titleLabel="Direct"
                                    jsx={<FormUtil.TextField
                                        width={400}
                                        checkable={ls.direct}
                                        setCheckable={(checkable) => {
                                            ls.direct = checkable;
                                            invalidate();
                                        }}
                                    />}
                                />
                            );
                            case 'formula': return (
                                <EditorUtil.FormulaEditor checkable={ls.fml} invalidate={invalidate} />
                            );
                        }
                    })()}
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'text';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeInnerText;

