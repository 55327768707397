import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../../abstractModelEditor";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../../common/component/form/validateUtil";
import ModelUtil from "../../../util/modelUtil";
import { GlobalContext } from "../../../../../entry/systemEntry";
import ModelEditDialog from "../../modelEditDialog";
import ModelElementUtil from "../../../util/modelElementUtil";
import NodeFocus from "./nodeFocus";

namespace NodeArrayDel {

    export interface Data {
        index: string;
        delCnt: string;
    }
    type LocalState = {
        index: FormUtil.CheckableValue;
        delCnt: FormUtil.CheckableValue;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const [localState, setLocalState] = useState<LocalState>({
            index: { value: '', errors: [] },
            delCnt: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [focusInfo, dtypes, states, funcargs, caches, propFields] = useMemo(() => {

            const cur = manageItems.focusNode;

            // const scope = store.system.scope;
            // const stateValueKeys = scope.stateValueKeys as ScopeManager.ValueKeyField[];

            const variables = ModelElementUtil.getRetentionDatasFromCurrent(cur, 'variable');
            const states = ModelElementUtil.getReferableStates(cur);
            const structs = ModelElementUtil.getReferableStructs(cur);

            const isDispatchSub = () => ModelElementUtil.isFunctionSub(cur);
            const funcargs = !isDispatchSub() ? undefined : ModelElementUtil.getArgumentFromCurrent(cur);
            const propFields = ModelElementUtil.getPropFieldsFromCurrent(cur);

            const focusInfo = NodeFocus.getFocusInfo(cur, {
                states, variables, structs: structs
            });

            return [focusInfo, structs, states, funcargs, variables, propFields];
        }, []);


        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;

                localState.index.value = data.index;
                localState.delCnt.value = data.delCnt;
            }
            invalidate();
        }, []);



        const targetFroms = [localState.index, localState.delCnt];
        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined
            ) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            const data: Data = {
                index: localState.index.value,
                delCnt: localState.delCnt.value
            }
            setTempData(data);
        }, targetFroms);

        return (<>
            <NodeFocus.Refer
                focusInfo={focusInfo}
            />
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Index"
                        jsx={
                            <FormUtil.TextField
                                width={220}
                                checkable={localState.index}
                                setCheckable={(checkable) => {
                                    localState.index = checkable;
                                    invalidate();
                                }}
                                validates={[
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    }
                                ]}
                            />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Delete Count"
                        jsx={
                            <FormUtil.TextField
                                width={220}
                                checkable={localState.delCnt}
                                setCheckable={(checkable) => {
                                    localState.delCnt = checkable;
                                    invalidate();
                                }}
                                validates={[
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    }
                                ]}
                            />
                        }
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'arrdel';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeArrayDel;
