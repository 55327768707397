import { useEffect, useState, useMemo, useContext } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import TreeUtil from "../../../../../../common/component/tree/treeUtil";
import ModelUtil from "../../util/modelUtil";
import ModelElementUtil from "../../util/modelElementUtil";
import DataUtil from "../../../../../../common/dataUtil";
import ModelEditDialog from "../modelEditDialog";
import { TabbedPane } from "../../../../../../common/component/tab/tabbedPane";
import styled, { css } from "styled-components";
import { GlobalContext } from "../../../../entry/systemEntry";
import ScopeManagerBak from "../../option/scopeManagerBak";
import { inherits } from "util";
import NodeStlarg from "./nodeStlarg";
import assert from "assert";
import StyleChooser from "./styleChooser";
import StoreDesign from "../../../../../redux/store/storeDesign";
import StoreSystem from "../../../../../redux/store/storeSystem";
import PrefixUtil from "../../util/prefixUtil";
import DummyUtil from "../../util/dummyUtil";
import StylePropDefiner from "./style/stylePropDefiner";
import StyleFormulaDefiner from "./style/styleFormulaDefiner";

namespace NodeStyle {

    export type UnitArg = {
        id: string;
        defVal?: string;
    }

    export type Data = {
        id: string;
        // src: string;
        fmls: StyleFormulaDefiner.Record[];
        defs: StylePropDefiner.Record[];
        inherits: StyleChooser.Refer[];
        args: ModelUtil.WrapElement[];
    }

    export type ReferArgState = {
        argId: string;
        isOverride: boolean;
        val: FormUtil.CheckableValue;
    }
    type LocalState = {
        tabIndex: number;
        id: FormUtil.CheckableValue;
        // src: FormUtil.CheckableValue;
        fmls: StyleFormulaDefiner.Record[];
        defs: StylePropDefiner.Record[];
        inherits: StyleChooser.ReferState[];

        isPropEdit: boolean;
        isFmlEdit: boolean;
    }

    export const getStyleRefsJsx = (paramName: string, styles: StyleChooser.Refer[]) => {
        let jsx = <></>;
        if (styles.length > 0) {
            const refers = styles.map(d => d.refId).join(', ');
            jsx = <>: <_Span color="#d1dfa0">{paramName}[{refers}]</_Span></>;
        }
        return jsx
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);
        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [localState, setLocalState] = useState<LocalState>({
            tabIndex: 0,
            id: { value: '', errors: [] },
            // src: { value: '', errors: [] },
            fmls: [],
            defs: [],
            inherits: [],
            isPropEdit: false,
            isFmlEdit: false,
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const [valueKeys, styleItems, stlargItems] = useMemo(() => {
            const scope = store.system.scope;
            const valueKeyList: ScopeManagerBak.ValueKeyField[] = [];
            const layer = ModelElementUtil.getTreeDepthLayer(manageItems.focusNode);
            switch (layer) {
                case 'project': {

                } break;
                case 'app': {

                } break;
                case 'compdef': {
                    const stateValueKeys = scope.stateValueKeys as ScopeManagerBak.ValueKeyField[];
                    const cacheValueKeys = scope.variableValueKeys as ScopeManagerBak.ValueKeyField[];
                    // const propFieldValueKeys = scope.propFieldValueKeys as ScopeManager.ValueKeyField[];
                    valueKeyList.push(...stateValueKeys);
                    valueKeyList.push(...cacheValueKeys)
                    // valueKeyList.push(...propFieldValueKeys);
                }
            }
            const styleItems = scope.styleItems as ScopeManagerBak.StyleItem[];
            const stlargItems = ((): ScopeManagerBak.StlargItem[] => {
                if (props.temp.data == null) return [];
                else {
                    const data = props.temp.data as Data;
                    return data.args.map(arg => {
                        const argData = arg.data as NodeStlarg.Data;
                        return {
                            id: argData.id
                        };
                    });
                }
            })();
            return [valueKeyList, styleItems, stlargItems];
        }, []);


        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                localState.id.value = data.id;
                // localState.src.value = data.src;
                localState.defs = JSON.parse(JSON.stringify(data.defs));
                // localState.defs = data.defs.map(def => {
                //     return {
                //         prop: { value: def.prop, errors: [] },
                //         val: def.val != undefined ? { value: def.val, errors: [] } : undefined,
                //         defs: def.defs != undefined ? def.defs
                //             .map(r => {
                //                 const val = r.val;
                //                 assert(val != undefined, 'valがundefinedであってはいけない。');
                //                 return {
                //                     prop: { value: r.prop, errors: [] },
                //                     val: { value: val, errors: [] }
                //                 }
                //             }) : undefined
                //     }
                // });
                localState.fmls = data.fmls.slice();
                localState.inherits = StyleChooser.getMappedStyleStates(data.inherits, { styleItems, phase: 'inherit' });

                invalidate();
            }
        }, []);

        const hasErrorInherits = StyleChooser.hasErrorDesignRefers(localState.inherits);
        useEffect(() => {
            const targetFroms = [localState.id];
            // 1つでも入力エラーがあると処理しない
            if (ValidateUtil.hasError(targetFroms) || localState.isPropEdit) {
                setInputOK(false);
                return;
            }
            if (hasErrorInherits) {
                setInputOK(false);
                return;
            }
            props.temp.inputOK = true;

            let args: ModelUtil.WrapElement[] = [];
            if (props.temp.data != null) {
                args = (props.temp.data as Data).args;
            }
            // const defs: StylePropDefiner.Record[] = localState.defs.map(def => {
            //     let val: string | undefined = undefined;
            //     let defs: StylePropDefiner.Record[] | undefined = undefined;
            //     if (def.val != undefined) {
            //         val = def.val.value;
            //     } else if (def.defs != undefined) {
            //         defs = def.defs.map(def => {
            //             const val = def.val?.value;
            //             assert(val != undefined, 'valはundefinedであってはならない。');
            //             return { prop: def.prop.value, val };
            //         });
            //     }
            //     return {
            //         prop: def.prop.value,
            //         val, defs
            //     };
            // });
            const data: Data = {
                id: localState.id.value,
                // src: localState.src.value,
                // src: '',
                fmls: localState.fmls,
                defs: localState.defs,
                args,
                inherits: StyleChooser.getMappedReferDatas(localState.inherits),
            }
            setTempData(data);
        }, [localState]);

        const masterTabJsx = (<>
            <FormUtil.FormRecord
                titleLabel="Id"
                jsx={<FormUtil.TextField
                    width={200}
                    checkable={localState.id}
                    setCheckable={(checkable) => {
                        localState.id = checkable;
                        invalidate();
                    }}
                    isEnabled={true}
                    validates={[
                        {
                            checker: (value) => ValidateUtil.checkRequired(value),
                            errorType: "required"
                        },
                        {
                            checker: (value) => ValidateUtil.checkStringLength(value, 1, 16),
                            errorType: "length"
                        },
                        {
                            checker: (value) => ModelUtil.checkIdChars(value),
                            errorType: "value"
                        }
                    ]}
                />}
            />
            {localState.tabIndex !== 0 ? <></> :
                StyleChooser.getTotalStyleMonitor(localState.inherits, { styleItems, phase: 'inherit' }, localState.defs)
            }
        </>);
        const propTabJsx = (<>
            <StyleFormulaDefiner.Component recieveState={localState} invalidate={invalidate} stlargItems={stlargItems} />
            <StylePropDefiner.Component recieveState={localState} invalidate={invalidate} stlargItems={stlargItems} />
        </>);

        const InheritTab = StyleChooser.Component({
            localState: { refers: localState.inherits },
            invalidate,
            props: { styleItems, phase: 'inherit' }
        });
        return (<_TabFrame>
            <TabbedPane
                tabElements={[
                    { name: 'Master', cont: <_TabItemWrap>{masterTabJsx}</_TabItemWrap>, enable: !hasErrorInherits },
                    { name: 'Properties', cont: <_TabItemWrap>{propTabJsx}</_TabItemWrap>, enable: !hasErrorInherits },
                    { name: 'Inherit', cont: <_TabItemWrap>{InheritTab}</_TabItemWrap>, enable: true },
                ]}
                activeNo={localState.tabIndex}
                selectTabIndex={(index) => {
                    localState.tabIndex = index;
                    invalidate();
                }}
            />
        </_TabFrame>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'style';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeStyle;

const _TabFrame = styled.div`
    display: inline-block;
    margin: 3px 0 0 3px;
    width: calc(100% - 6px);
    height: calc(100% - 46px);
    `;
const _TabItemWrap = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #c5d3dd;
`;

const _Span = styled.span<{
    color: string;
}>`
    color: ${props => props.color};
`;