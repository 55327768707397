import DefineUtil from "../../../system/contents/develop/function/util/defineUtil";
import ModelUtil from "../../../system/contents/develop/function/util/modelUtil";
import FormUtil from "./formUtiil";

/**
 * 入力チェックを行うユーティリティ。
 */
namespace ValidateUtil {

    const ASCII_CHARS = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const PASSWORD_CHARS = ASCII_CHARS + '!_@#={}';

    export type ErrorType = 'required' | 'length' | 'value' | 'relation';

    export type Validate = {
        checker: (value: string) => boolean;
        errorType: ErrorType;
        message?: string;
    }

    export const hasError = (targetFroms: FormUtil.CheckableValue[]) => {
        return targetFroms.find(form => form.errors.length > 0) != undefined;
    }

    /**
     * 必須チェック
     * @param str 検証する文字列
     * @returns 判定結果
     */
    export const checkRequired = (str: string) => {
        return str.length > 0;
    }

    /**
     * 文字列の長さチェック
     * @param str 検証する文字列
     * @param min 最小値
     * @param max 最大値
     * @returns 判定結果
     */
    export const checkStringLength = (str: string, min: number, max: number) => {
        return str.length === 0 || (str.length >= min && str.length <= max);
    }

    /**
     * 半角英数時チェック
     * @param str 検証する文字列
     * @returns 判定結果
     */
    export const checkAsciiChars = (str: string) => {
        return checkIncludesChars(str, ASCII_CHARS);
    }
    export const checkPasswordChars = (str: string) => {
        return checkIncludesChars(str, PASSWORD_CHARS);
    }

    /**
     * 指定の文字列で構成されているかチェック
     * @param str 検証する文字列
     * @param list 入力を許す対象文字列
     * @returns 判定結果
     */
    export const checkIncludesChars = (str: string, list: string) => {
        for (let i = 0; i < str.length; i++) {
            const ch = str.charAt(i);
            if (list.indexOf(ch) === -1) return false;
        }
        return true;
    }

    /**
     * 指定の文字列が数値に変換できるかチェック
     * @param str 検証する文字列
     * @returns 判定結果
     */
    export const checkNumber = (str: string) => {
        return !isNaN(Number(str));
    }

    /**
     * 数値の値の範囲をチェック
     * @param str 検証する文字列
     * @param min 下限
     * @param max 上限
     * @returns 判定結果
     */
    export const checkNumberRange = (str: string, min: number, max: number) => {
        if (!isNaN(Number(str))) {
            const num = Number(str);
            return num >= min && num <= max;
        }
        return true;
    }

    /**
     * 16進数のカラーコードとして有効かどうかチェック
     * @param str 検証する文字列
     * @returns 判定結果
     */
    export const checkValidHexColor = (str: string) => {
        const hexColorPattern = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
        return hexColorPattern.test(str);
    }

    /**
     * 
     * @param conditions 
     * @returns 
     */
    export const checkAll = (conditions: boolean[]): boolean => {
        let result = true;
        for (let i = 0; i < conditions.length; i++) {
            if (!conditions[i]) {
                result = false;
                break;
            }
        }
        return result;
    }

    export const checkArrayNumber = (value: string) => value.split(',').find(v => isNaN(Number(v))) == undefined;

    export const idValidates: ValidateUtil.Validate[] = [
        {
            checker: (value) => ValidateUtil.checkRequired(value),
            errorType: "required"
        },
        {
            checker: (value) => ValidateUtil.checkStringLength(value, 1, DefineUtil.LENGTH_ID),
            errorType: "length"
        },
        {
            checker: (value) => ModelUtil.checkCamelCase(value),
            errorType: "value"
        }
    ];
}
export default ValidateUtil;
