import React, { useEffect, useMemo, useState, useContext, StrictMode } from "react";
import ModelUtil from "../develop/function/util/modelUtil";
import ModelElementUtil from "../develop/function/util/modelElementUtil";
import styled from "styled-components";
import assert from "assert";
import DataUtil from "../../../common/dataUtil";
import { GlobalContext } from "../entry/systemEntry";
import ReaderUtil from "./readerUtil";
import NodeStyle from "../develop/function/editor/decrare/nodeStyle";
import NodeCompdef from "../develop/function/editor/decrare/nodeCompdef";
import NodeCase from "../develop/function/editor/condition/nodeCase";
import NodeApp from "../develop/function/editor/nodeApp";
import NodeAccept from "../develop/function/editor/condition/nodeAccept";
import NodeStruct from "../develop/function/editor/nodeStruct";
import NodeEntry from "../develop/function/editor/ui/nodeEntry";
import { AiOutlineClose } from "react-icons/ai";
import { MdOutlineOpenInFull } from "react-icons/md";
import { BsBoxArrowInDownLeft } from "react-icons/bs";
import NodeIterate from "../develop/function/editor/nodeIterate";
import NodeLauncher from "../develop/function/editor/release/nodeLauncher";
import ErrorFrame from "./errorFrame";
import NodeTag from "../develop/function/editor/ui/tag/nodeTag";
import AppPartialTag from "./appPartialTag";
import NodeInnerText from "../develop/function/editor/ui/tag/nodeInnerText";
import NodeFunction from "../develop/function/editor/var/func/nodeFunction";
import NodeBlock from "../develop/function/editor/var/nodeBlock";
import CompuseManager from "./compuseManager";
import PrefixUtil from "../develop/function/util/prefixUtil";

namespace AppReader {

    export const TestDialog = (props: {
        projectRootWrap: ModelUtil.WrapElement;
        launchNo: number;
    }) => {
        const { store, setStore, dispatcher } = useContext(GlobalContext);

        const [isWnd, setWnd] = useState<boolean>(true);

        // const [tabIndex, setTabIndex] = useState<number>(0);

        const margin = isWnd ? 40 : 0;
        return (
            <_AppFrame
                marginLeft={margin}
                marginTop={margin}
            >
                <_AppHeader>
                    {isWnd ?
                        (<_HeaderIcon onClick={() => {
                            setWnd(false);
                        }}><MdOutlineOpenInFull /></_HeaderIcon>) :
                        (<_HeaderIcon onClick={() => {
                            setWnd(true);
                        }}><BsBoxArrowInDownLeft /></_HeaderIcon>)
                    }
                    <_HeaderIcon onClick={() => {
                        store.system.dialog = null;
                        dispatcher.updateStore();
                    }}><AiOutlineClose /></_HeaderIcon>
                </_AppHeader>
                <_AppBody>
                    <Component
                        projectRootWrap={props.projectRootWrap}
                        launchNo={props.launchNo}
                    />
                </_AppBody>
            </_AppFrame>
        );
    }

    export const Component = (props: {
        projectRootWrap: ModelUtil.WrapElement;
        launchNo: number;
    }) => {

        /** アプリで利用するステート群 */
        const [readerState, setReaderState] = useState<ReaderUtil.ReaderState>({ globals: null, error: null });

        const prjRootWrap = props.projectRootWrap;

        /** 再描画 */
        const invalidate = () => {
            // console.log('トップのInvalidate');
            setReaderState({ ...readerState });
        };

        const globalStates = readerState.globals;

        /**
         * プログラムを停止し、スタックとレースを表示する。
         * @param element 要素
         * @param error エラー
         */
        const dispStackTrace = (element: ModelUtil.WrapElement, error: unknown) => {
            if (error instanceof Error) {
                readerState.error = {
                    nodeType: element.type,
                    nodeData: JSON.stringify(element.data, null, 2),
                    stack: error.stack ?? ''
                }
                invalidate();
            }
        }
        const [appWrap, globalFixedProps, appCache, globalCompdefs] = useMemo(() => {

            const launchersData = ModelElementUtil.getInnerWrapFixed(prjRootWrap, 'release', 'launchers').data as ModelUtil.NodeGenericItems;
            const appsData = ModelElementUtil.getInnerWrapFixed(prjRootWrap, 'apps').data as ModelUtil.NodeApps;
            const launcherWrap = launchersData.items
                .find(l => (l.data as NodeLauncher.Data).no === props.launchNo);
            assert(launcherWrap != undefined, 'launchersData.itemsにprops.launchNoと合致する連番が見つからない');
            const launcher = launcherWrap.data as NodeLauncher.Data;
            const appWrap = appsData.apps.find(a => (a.data as NodeApp.Data).id === launcher.appId);
            assert(appWrap != undefined, 'appsData.appsにlauncher.appIdと合致する連番が見つからない');

            const globalStructDatas = ModelElementUtil.getProjectCommonBelongDeclares(prjRootWrap, 'structs') as NodeStruct.Data[];
            const appStructDatas = ModelElementUtil.getAppStructs(appWrap);
            /** ストラクト定義をオブジェクトに変換 */
            const structs = ReaderUtil.convertStructDataToObject(globalStructDatas.concat(appStructDatas));

            const styles = ModelElementUtil.getProjectCommonBelongDeclares(prjRootWrap, 'styles') as NodeStyle.Data[];
            const funcs = ModelElementUtil.getProjectCommonBelongDeclares(prjRootWrap, 'funcs') as NodeFunction.Data[];

            let globalCompdefs = ModelElementUtil.getProjectCommonBelongDeclares(prjRootWrap, 'comps') as NodeCompdef.Data[];
            globalCompdefs = globalCompdefs.concat(ModelElementUtil.getCompsFromApp(appWrap));

            const states = ModelElementUtil.getGlobalStatesFromApp(appWrap);

            // モデル情報を取得
            const buildInitialStructureObject = (field: ModelUtil.NodeModelField) => ReaderUtil.buildInitialStructureObject(field, structs);

            /** ランチャー引数 */
            const launchArgs: ReaderUtil.FieldObject[] = launcher.args.map(ks => {
                // キーと値をセット
                return {
                    field: { id: ks.key, dataType: 'string', array: 0 },
                    value: ks.value
                };
            });
            const globalFixedProps: ReaderUtil.GlobalFixedProps = { states, launchArgs, structs, buildInitialStructureObject };

            const initialDynamics: ReaderUtil.DynamicProps = {
                iteraters: [], variables: [], temps: [], funcargs: [], funcs: [], effects: [],
                prpflds: [], prpclbks: [], styles: [], refs: [],
                partialSets: [],
                componentStates: [],
                compdefs: globalCompdefs,
                utils: {
                    globalInvalidate: invalidate,
                    partialInvalidate: invalidate
                },
                belengComponent: ''
            };
            // パーシャルIDに対応した再描画関数の初期化
            ReaderUtil.updateInvalidateCallback(initialDynamics);

            const appStates = states.map(s => buildInitialStructureObject(s));
            const appCache: ReaderUtil.GlobalAppCache = {
                dynamics: initialDynamics,
                appStates,
                compdefMap: [],
                globalFixedProps,
                dispStackTrace,
                isError: () => readerState.error != null,
            };

            /** グローバルのスタイル群を生成する */
            const setupGlobalStyles = (dynamicProps: ReaderUtil.DynamicProps) => {
                const appStyles = ModelElementUtil.getAppDeclareBelongDatas(appWrap, 'styles') as NodeStyle.Data[];
                styles.concat(appStyles).forEach(data => {
                    const obj = ReaderUtil.getStyleObjFromData(data, appCache);
                    dynamicProps.styles.push(obj);
                });
            }
            /** グローバルの関数群を生成する */
            const setupGlobalFunctions = (dynamicProps: ReaderUtil.DynamicProps) => {
                const appFuncs = ModelElementUtil.getAppDeclareBelongDatas(appWrap, 'funcs') as NodeFunction.Data[];

                funcs.concat(appFuncs).forEach(data => {
                    const obj = ReaderUtil.buildFunctionObjectFromData(data, appCache);
                    dynamicProps.funcs.push(obj);
                });
            }
            setupGlobalStyles(appCache.dynamics);
            setupGlobalFunctions(appCache.dynamics);

            // const tempCompStates = appCache.dynamics.componentStates;
            const dynamics = ReaderUtil.cloneDynamicProps(appCache.dynamics);
            // dynamics.componentStates = tempCompStates;

            return [appWrap, globalFixedProps, appCache, globalCompdefs];
        }, []);

        // アプリ共通のコンポーネント定義を初期化
        appCache.compdefMap.length = 0;
        globalCompdefs.forEach(def => {
            appCache.compdefMap.push({
                def,
                dynamics: appCache.dynamics
            });
        });

        // /**
        //  * ローカルID配下を部分再描画
        //  */
        // const partialInvalidate = (localPoints: ReaderUtil.LocalPoint[], localId?: string) => {
        //     if (localId == undefined) {
        //         setReaderState({ ...readerState });
        //         return;
        //     }

        //     const localPoint = localPoints.find(lp => lp.id === localId);

        //     assert(localPoint != undefined,
        //         `[存在しないlocalId(${localId})が指定された。利用可能なlocalId[${localPoints.
        //             map(l => l.id).join(', ')}]`
        //     );
        //     console.log('ローカル処理');
        //     localPoint.invalidate();
        // }
        // // 再描画関数をローカル対応の関数で上書き
        // appCache.invalidate = (localId?: string) => partialInvalidate(appCache.dynamics.localPoints, localId);

        // アプリ起動時に一度だけ実行される
        useEffect(() => {
            const statesData = ModelElementUtil.getGlobalStatesFromApp(appWrap);

            // グローバルステートの初期化
            readerState.globals = statesData.map((stateData) => {
                const obj = globalFixedProps.buildInitialStructureObject(stateData);

                // 初期値が設定されている場合
                if (stateData.items != undefined && stateData.items.length > 0) {
                    const dynamics = ReaderUtil.cloneDynamicProps(appCache.dynamics);
                    // フレームワークで初期値を設定するために、このスコープでのみ一時的に追加
                    ReaderUtil.addFieldObject(dynamics.componentStates, obj);
                    ReaderUtil.affectOperation({
                        data: {
                            target: 'state',
                            rootId: obj.field.id,
                            levelProps: [],
                            items: stateData.items,
                        }, appCache: { ...appCache, dynamics }
                    });
                }
                return obj;
            });

            invalidate();
        }, [appWrap]);

        const innerJsxes: JSX.Element[] = useMemo(() => {
            if (globalStates == null) return [];

            /**
             * 再帰的に要素のJSXを生成する
             * @param elements 子要素
             * @param dynamicProps 動的キャッシュ
             * @returns JSX
             */
            const getElementsJsxRec = (elements: ModelUtil.WrapElement[], dynamicProps: ReaderUtil.DynamicProps): JSX.Element[] => {

                // const upperLevelCompStates = dynamicProps.componentStates;
                // const localDynamics: ReaderUtil.DynamicProps = { ...dynamicProps };
                const localDynamics = ReaderUtil.cloneDynamicProps(dynamicProps);
                // localDynamics.componentStates = upperLevelCompStates;
                /**
                 * 内部要素を引数のelementsで初期化するが、
                 * retention要素を含む場合、retention配下を走査した上で、
                 * 同列のelements要素を内部要素として取得する
                 */
                let innerElements = elements;

                /** リテンション要素 */
                const retentionWrap = elements.find(e => e.type === 'retention');
                if (retentionWrap != undefined) {
                    const retention = retentionWrap.data as ModelUtil.NodeRetention;

                    // try {
                    //     ReaderUtil.applyProcedure(retention.items, appCache, dynamicProps);
                    // } catch (e) {
                    //     appCache.dispStackTrace(retentionWrap, e);
                    // }
                    ReaderUtil.applyProcedure(retention.items, { ...appCache, dynamics: localDynamics });
                    // ReaderUtil.applyProcedure(retention.items, appCache);

                    // elementsを上書きする
                    const innerElementsWrap = elements.find(e => e.type === 'elements');
                    assert(innerElementsWrap != undefined, 'retentionの対になるelements要素が見つからないため、不正な構成。');
                    innerElements = (innerElementsWrap.data as ModelUtil.NodeElements).elements;
                }

                const elementToCompJsxList: JSX.Element[] = [];
                /**
                 * useEffect用の仮想コンポーネントと描画用のコンポーネントを同じJSXで扱うため配列の長さをキーとする
                 * @returns インデックス
                 */
                const getIndexKey = () => {
                    return elementToCompJsxList.length;
                }

                // エフェクトの処理
                if (localDynamics.effects.length > 0) {
                    // console.log(localDynamics.effects.length);
                    localDynamics.effects.forEach((effect) => {
                        elementToCompJsxList.push(<AppPartialTag.EffectTemp key={getIndexKey()} depItems={effect.depItems} callback={effect.callback} />);
                    });
                    // エフェクトはこのコンポーネントでのみ利用するためリセットする
                    localDynamics.effects = [];
                }

                // 内部コンポーネントの処理
                innerElements.forEach(el => {
                    const compJsxes = convertElementToCompJsxes(el, { ...appCache, dynamics: localDynamics });
                    compJsxes.forEach(jsx => {
                        elementToCompJsxList.push(<React.Fragment key={getIndexKey()}>{jsx}</React.Fragment>);
                    });
                });

                return elementToCompJsxList;
            }

            /**
             * ツリーの要素よりコンポーネントのJSXを生成する
             * @param element 要素
             * @param appCache
             * @returns コンポーネントのJSX
             */
            const convertElementToCompJsxes = (element: ModelUtil.WrapElement, appCache: ReaderUtil.GlobalAppCache): JSX.Element[] => {

                // 非活性配下は表示しない
                if (element.disabled) return [];

                const dynamicProps = appCache.dynamics;

                /**
                 * コンポーネント内の例外処理
                 * @param e 例外
                 */
                const dispStackTraceComponent = (e: unknown) => {
                    dispStackTrace(element, e);
                }

                switch (element.type) {
                    case 'tag': {
                        const data = element.data as NodeTag.Data;

                        return [
                            <AppPartialTag.Component
                                appCache={appCache}
                                getElementsJsxRec={getElementsJsxRec}
                                data={data}
                            />
                        ];
                    }
                    case 'text': {
                        const data = element.data as NodeInnerText.Data;
                        const getText = () => {
                            if (data.direct != undefined) {
                                return data.direct;
                            } else if (data.fml != undefined) {
                                try {
                                    return ReaderUtil.getFomulaAplliedValue(data.fml, appCache);
                                } catch (e) {
                                    dispStackTraceComponent(e);
                                }
                            } else throw new Error('NodeInnerText.Dataが全てundefined');
                        }
                        return [<React.Fragment>{getText()}</React.Fragment>];
                    }
                    case 'compuse': {
                        return [
                            <CompuseManager.Component
                                appCache={appCache}
                                getElementsJsxRec={getElementsJsxRec}
                                data={element.data}
                            />
                        ];
                    }
                    case 'child': {
                        const childId = PrefixUtil.getCompChildId(dynamicProps.belengComponent);
                        // const childCompdef = dynamicProps.compdefs.find(compdef => compdef.id === childId);
                        // assert(childCompdef != undefined, 'childCompdefがnullであってはならない。');
                        // console.log(dynamicProps.compdefs);
                        
                        if(appCache.dynamics.compdefs.find(c => c.id === childId) == undefined) {
                            return [];
                        }
                        return [
                            <CompuseManager.Component
                                key={0}
                                appCache={appCache}
                                getElementsJsxRec={getElementsJsxRec}
                                data={{ compId: childId, props: [] }}
                            />
                        ];
                    }
                    case 'iterate': {
                        const iterate = element.data as NodeIterate.Data;

                        const jsxList: JSX.Element[] = [];
                        try {
                            const loopCnt = ReaderUtil.getFomulaAplliedValue(iterate.itrCntFml, appCache);
                            // loopCntがnumberであるかの検証
                            DataUtil.testAcceptAssign({ dataType: 'number', array: 0 }, loopCnt);

                            for (let i = 0; i < loopCnt; i++) {
                                const nextDynamicProps = ReaderUtil.cloneDynamicProps(dynamicProps);
                                // イテレータのコピーを取得
                                const cloneIteraters = nextDynamicProps.iteraters.slice();
                                const sameIndex = cloneIteraters.findIndex(i => i.id === iterate.id);
                                if (sameIndex !== -1) cloneIteraters.splice(sameIndex, 1);
                                cloneIteraters.push({ id: iterate.id, self: i, max: loopCnt });
                                nextDynamicProps.variables = nextDynamicProps.variables.slice();
                                nextDynamicProps.iteraters = cloneIteraters;
                                // const jsx = getElementsJsxRec(iterate.elements, { ...dynamicProps, iteraters: cloneIteraters, actions }, `${index}-${i}`);
                                const jsx = getElementsJsxRec(iterate.elements, nextDynamicProps);
                                // jsxList.push(<div key={`${index}-${i}`}>{jsx}</div>);

                                jsxList.push(...jsx);
                            }
                        } catch (e) {
                            dispStackTrace(element, e);
                        }
                        return jsxList;
                    }
                    case 'accept': {
                        const accept = element.data as NodeAccept.Data;
                        let jsxList: JSX.Element[] = [];
                        try {
                            const isAccept = ReaderUtil.isTrue(accept.condition, appCache);
                            if (isAccept) {
                                jsxList = getElementsJsxRec(accept.elements, dynamicProps);
                            }
                        } catch (e) {
                            dispStackTrace(element, e);
                        }
                        // console.log(jsxList.length);
                        return jsxList;
                    }
                    case 'case': {
                        const data = element.data as NodeCase.Data;
                        let jsxList: JSX.Element[] = [];

                        /** 要素配下を再帰的に処理する。ユーティリティに渡すコールバック関数 */
                        const rec = (elements: ModelUtil.WrapElement[]) => {
                            jsxList = getElementsJsxRec(elements, dynamicProps);
                        }
                        // switch (data.method) {
                        //     case 'bool': {
                        //         const condition = data.condition;
                        //         assert(condition != undefined, 'メソッドがboolの場合、conditionがundefinedであってはならない。');
                        //         const isAccept = ReaderUtil.isTrue(condition, appCache);
                        //         const whenTrue = data.items[0].data as ModelUtil.NodeBoolData;
                        //         const whenFalse = data.items[1].data as ModelUtil.NodeBoolData;
                        //         assert(whenTrue.bool === true && whenFalse.bool === false, 'case->boolに紐づいている要素が不正。')
                        //         if (isAccept) {
                        //             // console.log('true');
                        //             jsxList = getElementsJsxRec(whenTrue.elements, dynamicProps);
                        //         } else {
                        //             // console.log('false');
                        //             jsxList = getElementsJsxRec(whenFalse.elements, dynamicProps);
                        //         }
                        //     } break;
                        // }
                        ReaderUtil.deligateCaseExecute(data, rec, appCache);

                        return jsxList;
                    }
                    case 'block': {
                        const data = element.data as NodeBlock.Data;
                        return getElementsJsxRec(data.items, dynamicProps);
                    }
                    default: throw new Error(`element{${element.type}}は想定されていない。（未実装）`);
                }
            }

            /**
             * エントリー要素を取得する。
             * @returns エントリー要素
             */
            const getEntryElements = () => {
                const entryData = ModelElementUtil.getInnerWrapFixed(appWrap, 'entry');
                const view = entryData.data as NodeEntry.Data;
                try {
                    if (view.compId == undefined) throw new Error('エントリーポイントのコンポーネントが未設定です。');
                } catch (err) {
                    dispStackTrace(entryData, err);
                    return [];
                }
                // const entryComponent = appCache.compdefMap.find(comp => comp.def.id === view.compId);
                // assert(entryComponent != null, 'entryComponent is null.');

                // const wrap: ModelUtil.WrapElement = { type: 'compdef', data: entryComponent.def };
                // const data = ModelElementUtil.getInnerWrapFixed(wrap, 'elements').data as ModelUtil.NodeElements;
                // return data.elements;

                return [
                    <CompuseManager.Component
                        key={0}
                        appCache={appCache}
                        getElementsJsxRec={getElementsJsxRec}
                        data={{ compId: view.compId, props: [] }}
                    />
                ];
                // const data: NodeCompuse.Data = { compId: view.compId, props: [] };
                // return getElementsJsxRec([{ type: 'compuse', data }], appCache.dynamics);
            }

            // エラーがある場合、エラー情報を表示
            if (readerState.error != null) {
                return [<ErrorFrame.Componrnt key={0} error={readerState.error} />];
            }
            // return getElementsJsxRec(getEntryElements(), appCache.dynamics);
            return getEntryElements();
        }, [readerState]);

        return <>{innerJsxes}</>;
    }
}

export default AppReader;

const _AppFrame = styled.div<{
    marginTop: number;
    marginLeft: number;
}>`
    display: inline-block;
    position: absolute;
    z-index: 11;
    top: ${props => props.marginTop}px;
    left: ${props => props.marginLeft}px;
    width: calc(100% - ${props => props.marginLeft * 2}px);
    height: calc(100% - ${props => props.marginTop * 2}px);
    background-color: #ffffff;
    /* border: 1px solid #474747; */
    border-radius: 4px;
    box-shadow: 10px 15px 15px #0000004b;
    overflow: hidden;
`;

const _AppHeader = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 30px;
    background-color: #c0c0c0;
    text-align: right;
`;
const _HeaderIcon = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 36px;
    height: 26px;
    background-color: #a35656;
    margin: 2px 4px 0 0;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    border-radius: 2px;
    :hover {
        background-color: #dfb5b5
    }
`;
const _AppBody = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    height: calc(100% - 30px);
    /* background-color: #000000; */
    overflow-y: auto;
    overflow-x: auto;
`;