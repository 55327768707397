import { useEffect, useState } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import ModelUtil from "../../util/modelUtil";
import ModelElementUtil from "../../util/modelElementUtil";
import DataUtil from "../../../../../../common/dataUtil";
import ModelEditDialog from "../modelEditDialog";
import RefactoringUtil from "../../util/refactoringUtil";

namespace NodeCompdef {

    export type Data = {
        id: string;
        partialId?: string;
        useChild?: boolean;
        mngs: ModelUtil.WrapElement[];
    }

    type LocalState = {
        id: FormUtil.CheckableValue;
        partialId: FormUtil.CheckableValue;
        useChild: boolean;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {

        const [ls, setLs] = useState<LocalState>({
            id: { value: '', errors: [] },
            partialId: { value: '', errors: [] },
            useChild: false,
        });
        const invalidate = () => setLs({ ...ls });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [ls.id, ls.partialId];

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                ls.id.value = data.id;
                ls.partialId.value = data.partialId ?? '';
                ls.useChild = data.useChild ?? false;
                invalidate();
            }
        }, []);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            props.temp.inputOK = true;

            let mngs: ModelUtil.WrapElement[] = [];
            if (props.temp.data != null) {
                mngs = (props.temp.data as Data).mngs;
            }
            const data: Data = {
                id: ls.id.value,
                partialId: DataUtil.blankToUndefined(ls.partialId.value),
                useChild: ls.useChild ? true : undefined,
                mngs
            }
            setTempData(data);
        }, [...targetFroms, ls.useChild]);

        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Id"
                        jsx={<FormUtil.TextField
                            width={200}
                            checkable={ls.id}
                            setCheckable={(checkable) => {
                                ls.id = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkStringLength(value, 1, 16),
                                    errorType: "length"
                                },
                                {
                                    checker: (value) => ModelUtil.checkPascalCase(value),
                                    errorType: "value"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Partial Id"
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={ls.partialId}
                            setCheckable={(checkable) => {
                                ls.partialId = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Use Child"
                        jsx={<FormUtil.SwitchTwoFace
                            label1="NO"
                            label2="YES"
                            width={160}
                            rate1={50}
                            rate2={50}
                            isUse={ls.useChild}
                            callback={() => {
                                ls.useChild = !ls.useChild;
                                invalidate();
                            }}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'compdef';
        }

        override createAction(manageItems: ModelUtil.ManageItems, tempData: object): void {
            const node = manageItems.focusNode;
            const element = node.data as ModelUtil.WrapElement;
            element.data = tempData;
            // const modelData = element.data as ModelUtil.NodeFunc;

            ModelElementUtil.addElementNodeDeep(node, {
                type: 'props',
                data: {
                    props: []
                } as ModelUtil.NodeProps
            });
            ModelElementUtil.addElementNodeDeep(node, ModelElementUtil.getStoreInitialWrap());
            ModelElementUtil.addElementNodeDeep(node, {
                type: 'retention',
                data: {
                    items: []
                } as ModelUtil.NodeRetention
            });
            ModelElementUtil.addElementNodeDeep(node, {
                type: 'elements',
                data: {
                    elements: []
                } as ModelUtil.NodeElements
            });
            manageItems.invalidate();
        }

        override modifyAction(manageItems: ModelUtil.ManageItems, tempData: object): void {
            const node = manageItems.focusNode;
            (node.data as ModelUtil.WrapElement).data = tempData;
            manageItems.invalidate();
        }

        // override modifyExtendAction(props: ModelEditDialog.ExtendModProps): void {

        //     const projectRoot = ModelElementUtil.getProjectRootFromCurrent(props.focusNode);
        //     const getData = (data: any) => data as Data;

        //     RefactoringUtil.syncCompdefId({
        //         projectRoot,
        //         oldId: getData(props.prevData).id,
        //         newId: getData(props.newData).id
        //     })
        // }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeCompdef;
