import { useEffect, useState, useContext, useMemo } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import ModelUtil from "../../util/modelUtil";
import DataUtil from "../../../../../../common/dataUtil";
import ModelEditDialog from "../modelEditDialog";
import { GlobalContext } from "../../../../entry/systemEntry";
import assert from "assert";
import ModelElementUtil from "../../util/modelElementUtil";

namespace NodeEntry {

    export type Data = {
        compId?: string;
    }
    type LocalState = {
        compId: FormUtil.CheckableValue;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);
        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [comps] = useMemo(() => {
            const appRootwrap = ModelElementUtil.getAppRootWrap(manageItems.focusNode);

            const comps = ModelElementUtil.getCompsFromApp(appRootwrap);
            /** 引数が存在しないコンポーネントのみ表示する */
            const filteredComps = comps.filter(c => {
                const propsWrap = ModelElementUtil.getInnerWrapFixed({ type: 'compdef', data: c }, 'props');
                return (propsWrap.data as ModelUtil.NodeProps).props.length === 0;
            });
            return [filteredComps];
        }, []);

        const [localState, setLocalState] = useState<LocalState>({
            compId: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [localState.compId];

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                localState.compId.value = data.compId ?? '';
                invalidate();
            }
        }, []);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);
            const compId = localState.compId.value;
            const data: Data = {
                compId: compId === '' ? undefined : compId,
            }
            setTempData(data);
        }, targetFroms);

        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Component"
                        jsx={<FormUtil.Combobox
                            width={300}
                            checkable={localState.compId}
                            setCheckable={(checkable) => {
                                localState.compId = checkable;
                                invalidate();
                            }}
                            headBlank
                            list={comps.map(comp => {
                                return { value: comp.id, labelText: comp.id };
                            })}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'entry';
        }

        override modifyAction(manageItems: ModelUtil.ManageItems, tempData: object): void {
            const node = manageItems.focusNode;
            (node.data as ModelUtil.WrapElement).data = tempData;
            manageItems.invalidate();
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeEntry;
