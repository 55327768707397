import { useEffect, useState } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import TreeUtil from "../../../../../../common/component/tree/treeUtil";
import ModelUtil from "../../util/modelUtil";
import ModelElementUtil from "../../util/modelElementUtil";
import DataUtil from "../../../../../../common/dataUtil";
import ModelEditDialog from "../modelEditDialog";
import NodeEntry from "../ui/nodeEntry";

namespace NodeTrigger {

    type LocalState = {
        method: FormUtil.CheckableValue;
    }
    export type Data = {
        method: Method;
        mngs: ModelUtil.WrapElement[];
    }

    export const Methods = ['init'] as const;
    export type Method = typeof Methods[number];

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {

        const [localState, setLocalState] = useState<LocalState>({
            method: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [localState.method];

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                localState.method.value = data.method;
                invalidate();
            }
        }, []);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            props.temp.inputOK = true;

            let mngs: ModelUtil.WrapElement[] = [];
            if (props.temp.data != null) {
                mngs = (props.temp.data as Data).mngs;
            }
            const data: Data = {
                method: localState.method.value as Method,
                mngs
            }
            setTempData(data);
        }, targetFroms);

        return (<>
            <FormUtil.BorderPanel
                title="trigger"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Method"
                        jsx={<FormUtil.Combobox
                            width={200}
                            checkable={localState.method}
                            setCheckable={(checkable) => {
                                localState.method = checkable;
                                invalidate();
                            }}
                            headBlank
                            list={Methods.map(type => {
                                return { value: type, labelText: type }
                            })}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'trigger';
        }

        override createAction(manageItems: ModelUtil.ManageItems, tempData: object): void {
            const node = manageItems.focusNode;
            const element = node.data as ModelUtil.WrapElement;
            element.data = tempData;
            // const modelData = element.data as ModelUtil.NodeFunc;

            ModelElementUtil.addElementNodeDeep(node,
                {
                    type: 'proc',
                    data: {
                        items: []
                    } as ModelUtil.NodeProcedure
                }
            );
            manageItems.invalidate();
        }

        override modifyAction(manageItems: ModelUtil.ManageItems, tempData: object): void {
            const node = manageItems.focusNode;
            (node.data as ModelUtil.WrapElement).data = tempData;
            manageItems.invalidate();
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeTrigger;
