import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../../abstractModelEditor";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../../common/component/form/validateUtil";
import ModelUtil from "../../../util/modelUtil";
import DataUtil from "../../../../../../../common/dataUtil";
import { GlobalContext } from "../../../../../entry/systemEntry";
import ModelEditDialog from "../../modelEditDialog";
import assert from "assert";
import ModelElementUtil from "../../../util/modelElementUtil";
import StoreSystem from "../../../../../../redux/store/storeSystem";
import ScopeManagerBak from "../../../option/scopeManagerBak";
import VariableChooser from "../variableChooser";
import TreeUtil from "../../../../../../../common/component/tree/treeUtil";
import NodeField from "../../var/nodeField";

namespace NodeAssignMatch {

    export interface Data extends VariableChooser.Chooser {
        left: string;
        right: string;
    }

    type LocalState = {
        rootTarget: FormUtil.CheckableValue;
        rootId: FormUtil.CheckableValue;
        innerFieldForms: VariableChooser.InnerField[];
        left: FormUtil.CheckableValue;
        right: FormUtil.CheckableValue;
    }
    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const [localState, setLocalState] = useState<LocalState>({
            rootTarget: { value: '', errors: [] },
            rootId: { value: '', errors: [] },
            innerFieldForms: [],
            left: { value: '', errors: [] },
            right: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [dtypes, states, caches] = useMemo(() => {
            const cur = manageItems.focusNode;

            const caches = ModelElementUtil.getRetentionDatasFromCurrent(cur, 'variable');
                // .concat(ModelElementUtil.getFetchThensFromCurrent(cur));
            const states = ModelElementUtil.getReferableStates(cur);
            const dtypes = ModelElementUtil.getReferableStructs(cur);
            return [dtypes, states, caches];
        }, []);

        const arg: VariableChooser.Argument = {
            localState, states, variables: caches, structs: dtypes
        }

        useEffect(() => {
            const getTargetInitail = (): VariableChooser.RootTargetType | '' => {
                if (states == null) return 'variable'
                else if (caches == null) return 'state';
                return '';
            }

            localState.rootTarget.value = getTargetInitail();
            if (props.temp.data != null) {
                const data = props.temp.data as Data;

                VariableChooser.mappingDataToForm(arg, data);
                localState.left.value = data.left;
                localState.right.value = data.right;
            }
            invalidate();
        }, []);

        useEffect(() => {
        }, [localState.rootId, localState.innerFieldForms]);

        const targetFroms = [
            localState.rootId,
            localState.left,
            localState.right
        ];
        const targetFormsModInner = useMemo(() => {
            return localState.innerFieldForms.map(inner => inner.form);
        }, [localState.innerFieldForms]);

        useEffect(() => {
            if (targetFroms.concat(targetFormsModInner).find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            const levelProps: string[] = localState.innerFieldForms.map(inner => {
                return inner.form.value;
            });

            const data: Data = {
                target: localState.rootTarget.value as VariableChooser.RootTargetType,
                rootId: localState.rootId.value,
                levelProps,
                left: localState.left.value,
                right: localState.right.value
            }
            setTempData(data);
        }, [...targetFroms, targetFormsModInner]);

        return (<>
            <VariableChooser.Component
                arg={arg}
                invalidate={invalidate}
            />
            <FormUtil.BorderPanel
                title="match condition"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Left Side"
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={localState.left}
                            setCheckable={(checkable) => {
                                localState.left = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkStringLength(value, 1, 124),
                                    errorType: "length"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Right Side"
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={localState.right}
                            setCheckable={(checkable) => {
                                localState.right = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkStringLength(value, 1, 124),
                                    errorType: "length"
                                }
                            ]}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        private nodeType: ModelUtil.NodeType;
        
        constructor(nodeType: ModelUtil.NodeType) {
            super();
            this.nodeType = nodeType;
        }

        getNodeType(): ModelUtil.NodeType {
            return this.nodeType;
        }
        // getNodeType(): ModelUtil.NodeType {
        //     return 'asnmtch';
        // }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeAssignMatch;
