import styled from "styled-components";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { GlobalContext } from "../entry/systemEntry";
import { TabbedPane } from "../../../common/component/tab/tabbedPane";
import DatabaseManageTab from "./table/databaseManageTab";
import DevManageTab from "./manage/devManageTab";
import StructManageTab from "./function/tree/structManageTab";
import JsonTab from "./source/jsonTab";
import ProjectFrame from "../gui/projectFrame";

const DevelopChannel = () => {
    const { store, setStore, dispatcher } = useContext(GlobalContext);

    const project = store.project;
    const hasProject = project != null;
    const isEditManage = store.develop.isEditManage;

    /** 開発可否 */
    const isEnableDev = !isEditManage && hasProject;
    /** ログイン有無 */
    const isLogin = store.user != null;

    const getTestCont = () => {
        let cont = <></>;
        if(project != null) cont = <ProjectFrame.Component prjRootWrap={project.rootData} devmd={store.develop.manageData}/>;
        return cont;
    }
    return (
        <_TabWrap>
            <TabbedPane
                tabElements={[
                    { name: 'Json', cont: <JsonTab.Component />, enable: !isEditManage },
                    { name: 'Manage', cont: <DevManageTab.Component />, enable: hasProject && isLogin },
                    { name: 'Struct', cont: <StructManageTab.Component />, enable: isEnableDev, keepInstance: false },
                    // { name: 'Database', cont: <DatabaseManageTab.Component />, enable: true, keepInstance: false },
                    { name: 'Test', cont: getTestCont(), enable: isEnableDev, keepInstance: false },
                ]}
                activeNo={store.system.devTabIndex}
                selectTabIndex={(index: number) => {
                    store.system.devTabIndex = index;
                    dispatcher.updateStore();
                }}
            />
        </_TabWrap>
    );
};

export default DevelopChannel;

const _TabWrap = styled.div<{
}>`
    display: inline-block;
    margin: 4px 0 0 4px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
`;
