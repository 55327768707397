import styled, { css } from "styled-components";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import { useState } from "react";
import ScopeManagerBak from "../../../option/scopeManagerBak";
import DummyUtil from "../../../util/dummyUtil";
import PrefixUtil from "../../../util/prefixUtil";
import assert from "assert";

namespace StyleFormulaDefiner {

    export type Record = {
        id: string;
        fml: string;
    }

    type LocalState = {
        focusIndex: number;
        id: FormUtil.CheckableValue;
        fml: FormUtil.CheckableValue;
    }

    type RecieveState = {
        fmls: Record[];
        isFmlEdit: boolean;
    }

    export const Component = (props: {
        recieveState: RecieveState;
        invalidate: () => void;
        stlargItems: ScopeManagerBak.StlargItem[];
    }) => {

        const [localState] = useState<LocalState>({
            focusIndex: -1,
            id: { value: '', errors: [] },
            fml: { value: '', errors: [] },
        });

        const recieveState = props.recieveState;
        const invalidate = props.invalidate;
        const stlargItems = props.stlargItems;


        const listFncs = {
            resetForm: () => {
                localState.id.value = '';
                localState.fml.value = '';
            },
            swapOrder: (isDown: boolean) => {
                const subset = !isDown ? -1 : 1;
                const index = localState.focusIndex;
                const cur = recieveState.fmls[index];
                recieveState.fmls[index] = recieveState.fmls[index + subset];
                recieveState.fmls[index + subset] = cur;
                localState.focusIndex += subset;
            },
            isAcceptSwapOrder: (isDown: boolean) => {
                const index = localState.focusIndex;
                if (recieveState.isFmlEdit || index === -1) return false;
                if (!isDown) return index > 0;
                else return index < recieveState.fmls.length - 1;
            }
        }

        return (
            <FormUtil.BorderPanel
                title="formulas"
                height={254}
                innerJsx={<>
                    <FormUtil.ButtonRecord align='left' buttons={[
                        {
                            label: '+Formula', width: 130,
                            isEnable: !recieveState.isFmlEdit,
                            callback: () => {
                                recieveState.fmls.push({ id: '', fml: '' });
                                localState.focusIndex = recieveState.fmls.length - 1;
                                localState.id.value = '';
                                localState.fml.value = '';
                                recieveState.isFmlEdit = true;
                                invalidate();
                            }
                        },
                        {
                            label: !recieveState.isFmlEdit ? 'Edit' : 'Enter', width: 65,
                            isEnable: localState.focusIndex !== -1,
                            callback: () => {
                                const target = recieveState.fmls[localState.focusIndex];
                                if(!recieveState.isFmlEdit) {
                                    localState.id.value = target.id;
                                    localState.fml.value = target.fml;
                                    recieveState.isFmlEdit = true;
                                } else {
                                    target.id = localState.id.value;
                                    target.fml = localState.fml.value;
                                    recieveState.isFmlEdit = false;
                                }
                                invalidate();
                            }
                        },
                        {
                            label: 'Cancel', width: 95,
                            isEnable: recieveState.isFmlEdit,
                            callback: () => {
                                const target = recieveState.fmls[localState.focusIndex];
                                const isCreate = target.id === '';
                                if(isCreate) {
                                    recieveState.fmls.splice(localState.focusIndex, 1);
                                }
                                recieveState.isFmlEdit = false;
                                invalidate();
                            }
                        },
                        {
                            label: 'Delete', width: 95,
                            isEnable: !recieveState.isFmlEdit && localState.focusIndex !== -1,
                            callback: () => {
                                recieveState.fmls.splice(localState.focusIndex, 1);
                                localState.focusIndex --;
                                invalidate();
                            }
                        },
                    ]} />
                    {(() => {
                        const listJsx = recieveState.fmls.map((def, i) => {
                            const getFormJsx = (checkable: FormUtil.CheckableValue) => {
                                return <_Input value={checkable.value} onChange={(e) => {
                                    checkable.value = e.target.value;
                                    invalidate();
                                }} />;
                            };
                            const getInfoJsx = (targetDef: Record, target: 'id' | 'fml', isCurrentFocus: boolean) => {
                                const isEdit = isCurrentFocus && recieveState.isFmlEdit;

                                if (!isEdit) {
                                    const base = isEdit ? (
                                        target === 'id' ? localState.id.value : localState.fml.value
                                    ) : (
                                        target === 'id' ? targetDef.id : targetDef.fml
                                    );
                                    if (target === 'id') {
                                        return <_Fixed>{`\${${PrefixUtil.STYLE_FOUMULA}.`}<_Span color='#008b17'>{base}</_Span>{'}'}</_Fixed>;
                                    } else if (target === 'fml') {
                                        let value = base;
                                        const splitItem = '***';
                                        const argKeys = stlargItems.map(argItem => {
                                            return `\${${PrefixUtil.STYLE_ARGUMENT}.${argItem.id}}`;
                                        });
                                        argKeys.forEach(argkey => {
                                            value = value.replaceAll(argkey, `${splitItem}${argkey}${splitItem}`);
                                        });
                                        const jsxes = value.split(splitItem).map((item, i) => {
                                            const isArgkey = argKeys.includes(item);
                                            if (!isArgkey) return <span key={i}>{item}</span>;
                                            else return <_Span key={i} color="#0400ff">{item}</_Span>;
                                        })
                                        return <_Fixed>{jsxes}</_Fixed>;
                                    }
                                } else {
                                    return getFormJsx(target === 'id' ? localState.id : localState.fml);
                                }
                            }
                            const isCurrentFocus = localState.focusIndex === i;
                            const focusAction = () => {
                                if (recieveState.isFmlEdit) return;
                                if (localState.focusIndex !== i) localState.focusIndex = i;
                                else localState.focusIndex = -1;
                                invalidate();
                            };
                            return (<_Record key={i}
                                isFocus={localState.focusIndex === i}
                                onClick={focusAction}
                            >
                                <_Id>{getInfoJsx(def, 'id', isCurrentFocus)}</_Id>
                                <_Fml>{getInfoJsx(def, 'fml', isCurrentFocus)}</_Fml>
                            </_Record>);
                        });
                        return (<>
                            <_ListFrame>{listJsx}</_ListFrame>
                            <_OrderButtonFrame>
                                <FormUtil.ButtonItem button={{
                                    label: '↑', width: 40,
                                    isEnable: listFncs.isAcceptSwapOrder(false),
                                    callback: () => {
                                        listFncs.swapOrder(false);
                                        invalidate();
                                    }
                                }} />
                                <FormUtil.ButtonItem button={{
                                    label: '↓', width: 40,
                                    isEnable: listFncs.isAcceptSwapOrder(true),
                                    callback: () => {
                                        listFncs.swapOrder(true);
                                        invalidate();
                                    }
                                }} />
                            </_OrderButtonFrame>
                        </>);
                    })()}
                </>}
            />
        );
    }
};

export default StyleFormulaDefiner;


const ListFrameWidth = 520;
const _ListFrame = styled.div<{
}>`
    display: inline-block;
    width: ${ListFrameWidth}px;
    height: calc(100% - 42px);
    background-color: #edf1ef97;
    vertical-align: top;
    overflow-y: auto;
`;

const _Record = styled.div<{
    isFocus: boolean;
}>`
    display: inline-block;
    width: 100%;
    height: 30px;
    /* background-color: #7bd89e; */
    ${props => !props.isFocus ? '' : css`
        background-color: #d87b8f9e;
    `}
`;

const PROP_WIDTH = 140;
const _Id = styled.div<{
}>`
    display: inline-block;
    width: ${PROP_WIDTH}px;
    height: 100%;
    /* background-color: #fcfcfc; */
    border: 1px solid #000;
    box-sizing: border-box;
    vertical-align: top;
    border-radius: 4px;
`;
const _Fml = styled.div<{
}>`
    display: inline-block;
    width: calc(100% - ${PROP_WIDTH}px);
    height: 100%;
    /* background-color: #fcfcfc; */
    border: 1px solid #000;
    box-sizing: border-box;
    vertical-align: top;
    border-radius: 4px;
`;

const _Input = styled.input<{
}>`
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #fcfcfc;
    border: none;
    box-sizing: border-box;
    font-size: 18px;
    /* font-weight: 600; */
    color: #000007;
`;
const _Fixed = styled.span<{
}>`
    display: inline-block;
    width: 100%;
    height: 100%;
    /* background-color: #fcfcfc; */
    border: none;
    padding: 0 0 0 2px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 600;
    color: #666666;
    overflow-x: hidden;
`;
const _OrderButtonFrame = styled.div<{
}>`
    display: inline-block;
    width: calc(100% - ${ListFrameWidth}px);
    height: calc(100% - 45px);
    /* background-color: #53c7ee3d; */
    vertical-align: top;
    padding: 60px 0 0 0;
    box-sizing: border-box;
`;

const _Span = styled.span<{
    color: string;
}>`
    color: ${props => props.color};
`;