import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../../abstractModelEditor";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ModelUtil from "../../../util/modelUtil";
import { GlobalContext } from "../../../../../entry/systemEntry";
import ModelEditDialog from "../../modelEditDialog";
import ModelElementUtil from "../../../util/modelElementUtil";
import EditorUtil from "../../ui/common/editorUtil";
import DataUtil from "../../../../../../../common/dataUtil";

namespace NodeReturn {

    type LocalState = {
        fml: FormUtil.CheckableValue;
    }

    export type Data = {
        fml?: string;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const [ls, setLs] = useState<LocalState>({
            fml: { value: '', errors: [] },
        });
        const invalidate = () => setLs({ ...ls });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [dtypes, states, funcargs, caches, propFields] = useMemo(() => {

            const cur = manageItems.focusNode;

            const caches = ModelElementUtil.getRetentionDatasFromCurrent(cur, 'variable');
                // .concat(ModelElementUtil.getFetchThensFromCurrent(cur));
            // console.log(caches);
            const states = ModelElementUtil.getReferableStates(cur);
            const dtypes = ModelElementUtil.getReferableStructs(cur);

            const isDispatchSub = () => ModelElementUtil.isFunctionSub(cur);
            const funcargs = !isDispatchSub() ? undefined : ModelElementUtil.getArgumentFromCurrent(cur);
            const propFields = ModelElementUtil.getPropFieldsFromCurrent(cur);


            return [dtypes, states, funcargs, caches, propFields];
        }, []);


        useEffect(() => {

            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                ls.fml.value = data.fml ?? '';
            }
            invalidate();
        }, []);

        const targetFroms = [
            ls.fml,
        ];

        useEffect(() => {
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            const data: Data = {
                fml: DataUtil.blankToUndefined(ls.fml.value),
            }
            setTempData(data);
        }, [ls]);

        return (<>

            <EditorUtil.FormulaEditor checkable={ls.fml} invalidate={invalidate} />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'return';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeReturn;
