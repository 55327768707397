import ScopeManagerBak from "../../contents/develop/function/option/scopeManagerBak";
import ModelElementUtil from "../../contents/develop/function/util/modelElementUtil";
import ModelUtil from "../../contents/develop/function/util/modelUtil";
import ReaderUtil from "../../contents/gui/readerUtil";

namespace StoreSystem {

    export type Param = {
        channelIndex: number;
        devTabIndex: number;
        isSplash: boolean;
        dialog: null | JSX.Element;
        canDialogClose: boolean;
        /** スマートホンモード */
        isSPMode: boolean;
        logoutActions: (() => void)[];

        freeCache: object | null;
        scope: Scope;
        clipboad: null | Clipboard;
        fileHandle: FileSystemFileHandle | null;
    }

    export type Clipboard = {
        source: ModelUtil.WrapElement;
        cutAction: () => void;
    }

    export type Scope = {
        structItems?: ReaderUtil.StructObject[];
        stateValueKeys?: ScopeManagerBak.ValueKeyField[];
        variableValueKeys?: ScopeManagerBak.ValueKeyField[];
        // argValueKeys?: ScopeManager.ValueKeyField[];
        // propFieldValueKeys?: ScopeManager.ValueKeyField[];
        // prpclbkItems?: ScopeManager.FunctionItem[];
        iterateValueKeys?: ScopeManagerBak.ValueKeyField[];
        functionItems?: ScopeManagerBak.FunctionItem[];
        styleItems?: ScopeManagerBak.StyleItem[];
        stlargItems?: ScopeManagerBak.StlargItem[];
    }

    export const getBlankScope = (): Scope => {
        return {};
    }

    export type Env = {
        serverUrl: string;
        screenRate: number;
    }
}

export default StoreSystem;