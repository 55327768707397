import { useContext, useEffect, useState } from "react";

import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ModelUtil from "../../../util/modelUtil";
import { GlobalContext } from "../../../../../entry/systemEntry";
import ModelEditDialog from "../../modelEditDialog";
import ValidateUtil from "../../../../../../../common/component/form/validateUtil";
import AbstractModelEditor from "../../abstractModelEditor";
import DataUtil from "../../../../../../../common/dataUtil";

namespace NodeInvalidate {

    type LocalState = {
        partial: FormUtil.CheckableValue;
    }
    export type Data = {
        partial?: string;
    }


    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, setStore, dispatcher } = useContext(GlobalContext);

        const [localState, setLocalState] = useState<LocalState>({
            partial: { value: '', errors: [] }
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                localState.partial.value = data.partial ?? '';
                invalidate();
            }
        }, []);

        const targetFroms = [localState.partial];
        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            props.temp.inputOK = true;

            const data: Data = {
                partial: DataUtil.blankToUndefined(localState.partial.value),
            }
            setTempData(data);
        }, [...targetFroms]);

        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Partial key"
                        jsx={<FormUtil.TextField
                            width={200}
                            checkable={localState.partial}
                            setCheckable={(checkable) => {
                                localState.partial = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkStringLength(value, 0, 16),
                                    errorType: "length"
                                },
                                {
                                    checker: (value) => ModelUtil.checkIdChars(value),
                                    errorType: "value"
                                }
                            ]}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'invalidate';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeInvalidate;
