import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import ModelUtil from "../../util/modelUtil";
import DataUtil from "../../../../../../common/dataUtil";
import { GlobalContext } from "../../../../entry/systemEntry";
import ModelEditDialog from "../modelEditDialog";
import assert from "assert";
import ModelElementUtil from "../../util/modelElementUtil";
import StoreSystem from "../../../../../redux/store/storeSystem";
import ScopeManagerBak from "../../option/scopeManagerBak";

namespace NodeExecute {

    type LocalState = {
        eventId: FormUtil.CheckableValue;
        arguments: FormUtil.CheckableValue[];
    }
    export type Data = {
        eventId: string;
        args: string[];
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);
        assert(store.project != null, 'project is null.');

        const [dispatchItems, valueKeys] = useMemo(() => {
            const scope = store.system.scope;
            const dispatchItems = scope.functionItems as ScopeManagerBak.FunctionItem[];

            const stateValueKeys = scope.stateValueKeys as ScopeManagerBak.ValueKeyField[];
            const variableValueKeys = scope.variableValueKeys as ScopeManagerBak.ValueKeyField[];
            // const propFieldValueKeys = scope.propFieldValueKeys as ScopeManager.ValueKeyField[];
            const valueKeys = stateValueKeys
                .concat(variableValueKeys)
                // .concat(propFieldValueKeys);
            return [dispatchItems, valueKeys];
        }, []);

        const [localState, setLocalState] = useState<LocalState>({
            eventId: { value: '', errors: [] },
            arguments: [],
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [localState.eventId];

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                localState.eventId.value = data.eventId;
                localState.arguments = data.args.map(arg => {
                    return { value: arg, errors: [] }
                });
                invalidate();
            }
        }, []);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.concat(localState.arguments).find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            // let mngs: ModelUtil.WrapElement[] = [];
            // if (props.tempData != null) {
            //     mngs = (props.tempData as ModelUtil.NodeDiv).elements;
            // }
            const data: Data = {
                eventId: localState.eventId.value,
                args: localState.arguments.map(argSt => {
                    return argSt.value;
                })
            }
            setTempData(data);
        }, [...targetFroms, localState.arguments]);

        /** イベント引数用のステートを初期化する */
        const initArgStates = () => {
            const eventId = localState.eventId.value;
            if (eventId !== '') {
                const dispatch = dispatchItems.find(e => e.id === localState.eventId.value);
                assert(dispatch != null, 'dispatch is null.');
                localState.arguments = dispatch.args.map(arg => {
                    return { value: '', errors: [] }
                });
            } else {
                localState.arguments = [];
            }
        }

        const argForms: JSX.Element[] = useMemo(() => {
            if (localState.eventId.value === '') return [];
            const dispatch = dispatchItems.find(d => d.id === localState.eventId.value);
            assert(dispatch != null, 'dispatch is null.');
            return dispatch.args.map((arg, i) => {
                const getFormJsx = () => {

                    // console.log(localState.args[i].value);
                    switch (arg.dataType) {
                        case 'string': {
                            return (<FormUtil.TextField
                                width={240}
                                checkable={localState.arguments[i]}
                                setCheckable={(checkable) => {
                                    localState.arguments[i] = checkable;
                                    localState.arguments = localState.arguments.slice();
                                    invalidate();
                                }}
                                isEnabled={true}
                                validates={[
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    },
                                ]}
                            />);
                        }
                        case 'boolean': {
                            const checkable = localState.arguments[i];
                            const setCheckable = (checkable: FormUtil.CheckableValue) => {
                                localState.arguments[i] = checkable;
                            };
                            const Form = (props: {
                                checkable: FormUtil.CheckableValue;
                                setCheckable: (checkable: FormUtil.CheckableValue) => void;
                            }) => {
                                useEffect(() => {
                                    props.setCheckable({ ...props.checkable });
                                }, [props.checkable.value]);
                                return <FormUtil.SwitchTwoFace
                                    label1="FALSE"
                                    label2="TRUE"
                                    width={220}
                                    rate1={50}
                                    rate2={50}
                                    isUse={localState.arguments[i].value === 'true'}
                                    callback={() => {
                                        const flag = checkable.value === 'true';
                                        checkable.value = `${!flag}`;
                                        localState.arguments[i] = { ...checkable };
                                        localState.arguments = localState.arguments.slice();
                                        invalidate();
                                    }}
                                />;
                            }
                            return (<Form checkable={checkable} setCheckable={setCheckable} />);
                        }
                        case 'number': {
                            return (<FormUtil.TextField
                                width={240}
                                checkable={localState.arguments[i]}
                                setCheckable={(checkable) => {
                                    localState.arguments[i] = checkable;
                                    localState.arguments = localState.arguments.slice();
                                    invalidate();
                                }}
                                isEnabled={true}
                                validates={[
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    },
                                ]}
                            />);
                        }
                        case 'struct': {
                            return (<FormUtil.Combobox
                                width={240}
                                checkable={localState.arguments[i]}
                                setCheckable={(checkable) => {
                                    localState.arguments[i] = checkable;
                                    localState.arguments = localState.arguments.slice();
                                    invalidate();
                                }}
                                headBlank
                                list={valueKeys
                                    .filter(v => v.structId === arg.structId)
                                    .map(c => ({ value: c.key, labelText: c.key }))}
                                isEnabled={true}
                                validates={[
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    },
                                ]}
                            />);
                        }
                        default: return <></>;
                    }
                }
                const getDispType = () => {
                    return `@${arg.dataType}${'[]'.repeat(arg.array)}`;
                }
                return (
                    <FormUtil.FormRecord key={i}
                        titleLabel={arg.id}
                        jsx={<>
                            <FormUtil.FixedText
                                width={170}
                                value={getDispType()}
                            />
                            {getFormJsx()}
                        </>}
                    />
                );
            });
        }, [localState.arguments]);

        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Dispatch Id"
                        jsx={<FormUtil.Combobox
                            width={160}
                            checkable={localState.eventId}
                            setCheckable={(checkable) => {
                                localState.eventId = checkable;
                                initArgStates();
                                invalidate();
                            }}
                            headBlank
                            list={dispatchItems.map(d => {
                                return { value: d.id, labelText: d.id }
                            })}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                </>}
            />
            <FormUtil.BorderPanel
                title="arguments"
                isVisible={argForms.length > 0}
                innerJsx={<>{argForms}</>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'execute';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeExecute;
