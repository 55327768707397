import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import ModelUtil from "../../util/modelUtil";
import DataUtil from "../../../../../../common/dataUtil";
import { GlobalContext } from "../../../../entry/systemEntry";
import ModelEditDialog from "../modelEditDialog";
import assert, { throws } from "assert";
import ModelElementUtil from "../../util/modelElementUtil";
import ScopeManagerBak from "../../option/scopeManagerBak";
import NodePrpclbk from "../var/nodePrpclbkEditor";

namespace NodeCompuse {

    export type Data = {
        compId: string;
        props: string[];
        elements?: ModelUtil.WrapElement[];
    }

    type LocalState = {
        compId: FormUtil.CheckableValue;
        props: FormUtil.CheckableValue[];
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);
        assert(store.project != null, 'project is null.');

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;
        const [comps, valueKeys, dispatchers, dtypeInfos] = useMemo(() => {

            const comps = ModelElementUtil.getReferableComps(manageItems.focusNode);
            const ownerComponent = ModelElementUtil.getOwnerCompFromCurrent(manageItems.focusNode);
            /** 自身の親コンポーネントは除外する */
            const filteredComps = comps.filter(c => c.id !== ownerComponent.id);

            const scope = store.system.scope;
            const stateValueKeys = scope.stateValueKeys as ScopeManagerBak.ValueKeyField[];
            const cacheValueKeys = scope.variableValueKeys as ScopeManagerBak.ValueKeyField[];
            const valueKeys = stateValueKeys
                .concat(cacheValueKeys)

            const dtypeInfos = ModelElementUtil.getReferableStructs(manageItems.focusNode);

            const functions = scope.functionItems as ScopeManagerBak.FunctionItem[];
            return [filteredComps, valueKeys, functions, dtypeInfos];
        }, []);

        const [localState, setLocalState] = useState<LocalState>({
            compId: { value: '', errors: [] },
            props: [],
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [localState.compId];

        const getCompdef = () => {
            const compdef = comps.find(c => c.id === localState.compId.value);
            assert(compdef != undefined, 'compdefがundefinedであってはならない。');
            return compdef;
        }

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                localState.compId.value = data.compId;
                localState.props = data.props.map(prop => {
                    return { value: prop, errors: [] }
                });
                invalidate();
            }
        }, []);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.concat(localState.props).find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            // let mngs: ModelUtil.WrapElement[] = [];
            // if (props.tempData != null) {
            //     mngs = (props.tempData as ModelUtil.NodeDiv).elements;
            // }
            
            let elements: undefined | ModelUtil.WrapElement[] = undefined;
            if (props.temp.data != null) {
                elements = (props.temp.data as Data).elements;
            }
            const compdef = getCompdef();
            // console.log(compdef);
            if(compdef.useChild) {
                elements = [];
            }
            const data: Data = {
                compId: localState.compId.value,
                props: localState.props.map(prop => {
                    return prop.value;
                }),
                elements
            }
            setTempData(data);
        }, [...targetFroms, localState.props]);

        /** コンポーネント引数用のステートを初期化する */
        const initPropStates = () => {
            const compId = localState.compId.value;
            if (compId !== '') {
                const comp = comps.find(c => c.id === localState.compId.value);
                assert(comp != null, 'comp is null.');
                const props = ModelElementUtil.getInnerWrapFixed({ data: comp, type: 'compdef' }, 'props');
                localState.props = (props.data as ModelUtil.NodeProps).props.map(propWrap => {
                    return { value: '', errors: [] }
                });
            } else {
                localState.props = [];
            }
        }

        const propForms: JSX.Element[] = useMemo(() => {
            if (localState.compId.value === '') return [];
            const compdef = getCompdef();
            const props = ModelElementUtil.getInnerWrapFixed({ data: compdef, type: 'compdef' }, 'props');
            return (props.data as ModelUtil.NodeProps).props.map((propWrap, i) => {
                if (propWrap.type === 'prpclbk') {
                    const callback = propWrap.data as NodePrpclbk.Data;
                    const args = callback.args.map(w => w.data as ModelUtil.NodeModelField);
                    const getArgItems = (args: ModelUtil.NodeModelField[]) => args
                        .map(a => `${a.id} @${a.dataType}${'[]'.repeat(a.array)}`)
                        .join(', ');
                    const argItems = getArgItems(args);
                    const argLabel = `callback(${argItems})`;
                    return (
                        <FormUtil.FormRecord key={i}
                            titleLabel={callback.id}
                            jsx={<>
                                <FormUtil.FixedText
                                    width={220}
                                    value={argLabel}
                                />
                                <FormUtil.Combobox
                                    width={190}
                                    checkable={localState.props[i]}
                                    setCheckable={(checkable) => {
                                        localState.props[i] = checkable;
                                        localState.props = localState.props.slice();
                                        invalidate();
                                    }}
                                    headBlank
                                    list={dispatchers
                                        // ティスパッチャの引数が完全一致したものだけ表示
                                        .filter(d => {
                                            // const cArgsWrap = ModelElementUtil.getInnerWrapFixed({ type: 'closure', data: d }, 'args');
                                            // const cArgs = (cArgsWrap.data as ModelUtil.NodeArgs).args.map(a => a.data as ModelUtil.NodeModelField);
                                            // console.log(d);
                                            const cArgItems = getArgItems(d.args);
                                            return argItems === cArgItems;
                                        })
                                        .map(c => ({ value: c.id, labelText: c.id }))}
                                    isEnabled={true}
                                    validates={[
                                        {
                                            checker: (value) => ValidateUtil.checkRequired(value),
                                            errorType: "required"
                                        },
                                    ]}
                                />
                            </>}
                        />
                    );
                } else if (propWrap.type === 'prpfld') {

                    const prop = propWrap.data as ModelUtil.NodeModelField;
                    const getFormJsx = () => {

                        if (prop.dataType === 'struct' || prop.array >= 1) {

                            return (<FormUtil.Combobox
                                width={240}
                                checkable={localState.props[i]}
                                setCheckable={(checkable) => {
                                    localState.props[i] = checkable;
                                    localState.props = localState.props.slice();
                                    invalidate();
                                }}
                                headBlank
                                list={valueKeys
                                    .filter(v => v.array === prop.array && v.structId === prop.structId)
                                    .map(c => ({ value: c.key, labelText: c.key }))}
                                isEnabled={true}
                                validates={[
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    },
                                ]}
                            />);
                        } else {
                            switch (prop.dataType) {
                                case 'color':
                                case 'string': {
                                    return (<FormUtil.TextField
                                        width={240}
                                        checkable={localState.props[i]}
                                        setCheckable={(checkable) => {
                                            localState.props[i] = checkable;
                                            localState.props = localState.props.slice();
                                            invalidate();
                                        }}
                                        isEnabled={true}
                                        validates={[
                                            {
                                                checker: (value) => ValidateUtil.checkRequired(value),
                                                errorType: "required"
                                            },
                                        ]}
                                    />);
                                }
                                // case 'boolean': {
                                //     const checkable = localState.props[i];
                                //     const setCheckable = (checkable: FormUtil.CheckableValue) => {
                                //         localState.props[i] = checkable;
                                //     };
                                //     const Form = (props: {
                                //         checkable: FormUtil.CheckableValue;
                                //         setCheckable: (checkable: FormUtil.CheckableValue) => void;
                                //     }) => {
                                //         useEffect(() => {
                                //             props.setCheckable({ ...props.checkable });
                                //         }, [props.checkable.value]);
                                //         return <FormUtil.SwitchTwoFace
                                //             label1="FALSE"
                                //             label2="TRUE"
                                //             width={220}
                                //             rate1={50}
                                //             rate2={50}
                                //             isUse={localState.props[i].value === 'true'}
                                //             callback={() => {
                                //                 const flag = checkable.value === 'true';
                                //                 checkable.value = `${!flag}`;
                                //                 localState.props[i] = { ...checkable };
                                //                 localState.props = localState.props.slice();
                                //                 invalidate();
                                //             }}
                                //         />;
                                //     }
                                //     return (<Form checkable={checkable} setCheckable={setCheckable} />);
                                // }
                                case 'boolean': {
                                    return (<FormUtil.TextField
                                        width={240}
                                        checkable={localState.props[i]}
                                        setCheckable={(checkable) => {
                                            localState.props[i] = checkable;
                                            localState.props = localState.props.slice();
                                            invalidate();
                                        }}
                                        isEnabled={true}
                                        validates={[
                                            {
                                                checker: (value) => ValidateUtil.checkRequired(value),
                                                errorType: "required"
                                            },
                                        ]}
                                    />);
                                }
                                case 'number': {
                                    return (<FormUtil.TextField
                                        width={240}
                                        checkable={localState.props[i]}
                                        setCheckable={(checkable) => {
                                            localState.props[i] = checkable;
                                            localState.props = localState.props.slice();
                                            invalidate();
                                        }}
                                        isEnabled={true}
                                        validates={[
                                            {
                                                checker: (value) => ValidateUtil.checkRequired(value),
                                                errorType: "required"
                                            },
                                        ]}
                                    />);
                                }
                                case 'function': {
                                    return (<FormUtil.TextField
                                        width={240}
                                        checkable={localState.props[i]}
                                        setCheckable={(checkable) => {
                                            localState.props[i] = checkable;
                                            localState.props = localState.props.slice();
                                            invalidate();
                                        }}
                                        isEnabled={true}
                                        validates={[
                                            {
                                                checker: (value) => ValidateUtil.checkRequired(value),
                                                errorType: "required"
                                            },
                                        ]}
                                    />);
                                }
                                default: return <></>;
                            }
                        }
                    }
                    const getDispType = () => {
                        return ModelUtil.getFieldListItem(prop).labelText;
                    }
                    return (
                        <FormUtil.FormRecord key={i}
                            jsx={<>
                                <FormUtil.FixedText
                                    width={310}
                                    value={getDispType()}
                                />
                                {getFormJsx()}
                            </>}
                        />
                    );
                }
                throw new Error("[propWrap.type]に想定していない値が入っている。");
            });
        }, [localState.props]);

        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Component"
                        jsx={<FormUtil.Combobox
                            width={160}
                            checkable={localState.compId}
                            setCheckable={(checkable) => {
                                localState.compId = checkable;
                                initPropStates();
                                invalidate();
                            }}
                            headBlank
                            list={comps.map(c => {
                                return { value: c.id, labelText: c.id }
                            })}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                </>}
            />
            <FormUtil.BorderPanel
                title="props"
                isVisible={propForms.length > 0}
                innerJsx={<>{propForms}</>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'compuse';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeCompuse;
