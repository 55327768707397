import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import TreeUtil from "../../../../../../common/component/tree/treeUtil";
import ModelUtil from "../../util/modelUtil";
import DataUtil from "../../../../../../common/dataUtil";
import { GlobalContext } from "../../../../entry/systemEntry";
import ModelEditDialog from "../modelEditDialog";
import ModelElementUtil from "../../util/modelElementUtil";
import assert from "assert";

namespace NodeAccept {

    type LocalState = {
        condition: FormUtil.CheckableValue;
    }
    export type Data = {
        condition: string;
        elements: ModelUtil.WrapElement[];
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const [localState, setLocalState] = useState<LocalState>({
            condition: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [localState.condition];

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        // const [daos, vars] = useMemo(() => {
        //     let cur = manageItems.focusNode;
        //     while (true) {
        //         const wrap = cur.data as ModelUtil.WrapElement;
        //         if (wrap.type === 'func') break;
        //         assert(cur.parent != null, 'cur.parent is null.');
        //         cur = cur.parent;
        //     }
        //     const wrap = cur.data as ModelUtil.WrapElement;
        //     const daosWrap = ModelElementUtil.getInnerWrapFixed(wrap, 'global', 'daos');
        //     const daos = (daosWrap.data as ModelUtil.NodeDAOs).daos.map(daoWrap => daoWrap.data as ModelUtil.NodeDAO);
        //     const varsWrap = ModelElementUtil.getInnerWrapFixed(wrap, 'global', 'variables');
        //     const vars = (varsWrap.data as ModelUtil.NodeVariables).items.map(varWrap => varWrap.data as ModelUtil.NodeVariable);
        //     return [daos, vars];
        // }, []);

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                localState.condition.value = data.condition;
                invalidate();
            }
        }, []);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            let elements: ModelUtil.WrapElement[] = [];
            if (props.temp.data != null) {
                elements = (props.temp.data as Data).elements;
            }
            const data: Data = {
                condition: localState.condition.value,
                elements
            }
            setTempData(data);
        }, targetFroms);

        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Condition"
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={localState.condition}
                            setCheckable={(checkable) => {
                                localState.condition = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkStringLength(value, 1, 124),
                                    errorType: "length"
                                },
                                // {
                                //     checker: (value) => !existNameList.includes(value),
                                //     errorType: "relation"
                                // }
                            ]}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'accept';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeAccept;
