import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import TreeUtil from "../../../../../../common/component/tree/treeUtil";
import ModelUtil from "../../util/modelUtil";
import DataUtil from "../../../../../../common/dataUtil";
import { GlobalContext } from "../../../../entry/systemEntry";
import ModelEditDialog from "../modelEditDialog";
import ModelElementUtil from "../../util/modelElementUtil";
import assert from "assert";
import RefactoringUtil from "../../util/refactoringUtil";
import NodeStruct from "../nodeStruct";
import NodeCompdef from "../decrare/nodeCompdef";

namespace NodePrpclbk {

    type LocalState = {
        id: FormUtil.CheckableValue;
        isUseRet: boolean;
        dataType: FormUtil.CheckableValue;
        structId: FormUtil.CheckableValue;
        array: FormUtil.CheckableValue;
    }
    export interface Data extends ModelUtil.ArgsData {
        id: string;
        ret?: ModelUtil.Field;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);
        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [ls, setLs] = useState<LocalState>({
            id: { value: '', errors: [] },
            isUseRet: false,
            dataType: { value: 'string', errors: [] },
            structId: { value: '', errors: [] },
            array: { value: '0', errors: [] },
        });
        const invalidate = () => setLs({ ...ls });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const [structs] = useMemo(() => {

            const structs = ModelElementUtil.getReferableStructs(manageItems.focusNode);
            return [structs];
        }, []);

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                ls.id.value = data.id;
                if (data.ret != undefined) {
                    ls.isUseRet = true;
                    const retData = data.ret;
                    ls.dataType.value = retData.dataType;
                    ls.array.value = retData.array.toString();
                    if (retData.structId != undefined) {
                        ls.structId.value = retData.structId;
                    }
                }
                invalidate();
            }
        }, []);

        useEffect(() => {
            let targetFroms = [ls.id, ls.dataType, ls.array, ls.structId];
            if(ls.isUseRet) {
                targetFroms = targetFroms.concat([ls.dataType, ls.structId, ls.array]);
            }
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            let ret: ModelUtil.Field | undefined = undefined;
            if (ls.isUseRet) {
                const dataType = ls.dataType.value as ModelUtil.DataType;
                let modelId: string | undefined = undefined;
                if (ls.structId.value != '') {
                    modelId = ls.structId.value;
                }
                ret = {
                    dataType,
                    structId: modelId,
                    array: Number(ls.array.value),
                }
            }
            let args: ModelUtil.WrapElement[] = [];
            if (props.temp.data != null) {
                args = (props.temp.data as Data).args;
            }
            const data: Data = {
                id: ls.id.value,
                ret,
                args
            }
            setTempData(data);
        }, [ls]);

        const isDispStruct = () => {
            const list: ModelUtil.DataType[] = ['struct'];
            return list.includes(ls.dataType.value as ModelUtil.DataType);
        }

        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Id"
                        jsx={<FormUtil.TextField
                            width={200}
                            checkable={ls.id}
                            setCheckable={(checkable) => {
                                ls.id = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkStringLength(value, 1, 16),
                                    errorType: "length"
                                },
                                {
                                    checker: (value) => ModelUtil.checkCamelCase(value),
                                    errorType: "value"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Return"
                        jsx={<FormUtil.SwitchTwoFace
                            label1="NONE"
                            label2="USE"
                            width={160}
                            rate1={50}
                            rate2={50}
                            isUse={ls.isUseRet}
                            callback={() => {
                                ls.isUseRet = !ls.isUseRet;
                                if (!ls.isUseRet) {
                                    ls.dataType.value = 'string';
                                    ls.array.value = '0';
                                    ls.structId.value = '';
                                }
                                invalidate();
                            }}
                        />}
                    />
                </>}
            />
            <FormUtil.BorderPanel
                title="return"
                isVisible={ls.isUseRet}
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Data Type"
                        jsx={<FormUtil.Combobox
                            width={200}
                            checkable={ls.dataType}
                            setCheckable={(checkable) => {
                                ls.dataType = checkable;
                                if (!isDispStruct()) ls.structId.value = '';
                                invalidate();
                            }}
                            list={ModelUtil.DataTypes
                                // // callbackはpropのみで利用可能
                                // .filter(type => {
                                //     return !(props.nodeType !== 'prop' && type === 'callback');
                                // })
                                .map(type => {
                                    return { value: type, labelText: type }
                                })}
                            validates={!ls.isUseRet ? [] : [
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Struct Id"
                        isVisible={isDispStruct()}
                        jsx={<FormUtil.Combobox
                            width={280}
                            checkable={ls.structId}
                            setCheckable={(checkable) => {
                                ls.structId = checkable;
                                invalidate();
                            }}
                            headBlank
                            list={structs.map(model => {
                                const labelText = `${model.id} (${model.fields.length}fields)`;
                                return { value: model.id, labelText }
                            })}
                            validates={!ls.isUseRet || !isDispStruct() ? [] : [
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Array"
                        // isVisible={(localState.dataType.value as ModelUtil.DataType) !== 'callback'}
                        jsx={<FormUtil.TextField
                            width={50}
                            checkable={ls.array}
                            setCheckable={(checkable) => {
                                ls.array = checkable;
                                invalidate();
                            }}
                            isNumber
                            isEnabled={true}
                            validates={!ls.isUseRet ? [] : [
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkNumberRange(value, 0, 2),
                                    errorType: "length"
                                }
                            ]}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'prpclbk';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }


        override createExtendAction(node: TreeUtil.ElementNode): void {
            const ownerWrap = node.parent?.parent?.data as ModelUtil.WrapElement;
            const compdefData = ownerWrap.data as NodeCompdef.Data;
            const projectRoot = ModelElementUtil.getProjectRootFromCurrent(node);
            // console.log(dtypeData);
            RefactoringUtil.syncCompdefAddProps({
                compdefId: compdefData.id,
                insertIndex: (node.parent?.children.length as number) - 1,
                projectRootNode: projectRoot,
                defaultValue: ''
            });
        }

        override deleteExtendAction(node: TreeUtil.ElementNode): void {
            const ownerWrap = node.parent?.parent?.data as ModelUtil.WrapElement;
            const compdefData = ownerWrap.data as NodeCompdef.Data;
            const projectRoot = ModelElementUtil.getProjectRootFromCurrent(node);
            // console.log(dtypeData);
            RefactoringUtil.syncCompdefRemoveProps({
                compdefId: compdefData.id,
                removeIndex: this.getCurIndex(node),
                projectRootNode: projectRoot,
            });
        }

        override orderExtendAction(node: TreeUtil.ElementNode, offset: number): void {
            const ownerWrap = node.parent?.parent?.data as ModelUtil.WrapElement;
            const compdefData = ownerWrap.data as NodeCompdef.Data;
            const projectRoot = ModelElementUtil.getProjectRootFromCurrent(node);
            // console.log(compdefData);
            RefactoringUtil.syncCompdefOrderProps({
                compdefId: compdefData.id,
                targetIndex: this.getCurIndex(node),
                projectRootNode: projectRoot,
                offset
            });
        }
    }
}

export default NodePrpclbk;
