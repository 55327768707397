import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import TreeUtil from "../../../../../../common/component/tree/treeUtil";
import ModelUtil from "../../util/modelUtil";
import DataUtil from "../../../../../../common/dataUtil";
import { GlobalContext } from "../../../../entry/systemEntry";
import ModelEditDialog from "../modelEditDialog";
import ModelElementUtil from "../../util/modelElementUtil";
import assert from "assert";
import NodeWhen from "./nodeWhen";

namespace NodeCase {

    type LocalState = {
        method: FormUtil.CheckableValue;
        condition: FormUtil.CheckableValue;
        target: FormUtil.CheckableValue;
    }

    /** 条件タイプ */
    export const CaseTypes = ['bool', 'switch', 'when'] as const;
    // export type CaseType = 'bool' | 'switch' | 'range' | 'when'
    export type CaseType = typeof CaseTypes[number];
    export type Data = {
        method: CaseType;
        target?: string;
        condition?: string;
        items: ModelUtil.WrapElement[];
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const [ls, setLs] = useState<LocalState>({
            method: { value: '', errors: [] },
            condition: { value: '', errors: [] },
            target: { value: '', errors: [] },
        });
        const invalidate = () => setLs({ ...ls });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [ls.method, ls.condition, ls.target];

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        // const [daos, vars] = useMemo(() => {
        //     let cur = manageItems.focusNode;
        //     while (true) {
        //         const wrap = cur.data as ModelUtil.WrapElement;
        //         if (wrap.type === 'func') break;
        //         assert(cur.parent != null, 'cur.parent is null.');
        //         cur = cur.parent;
        //     }
        //     const wrap = cur.data as ModelUtil.WrapElement;
        //     const daosWrap = ModelElementUtil.getInnerWrapFixed(wrap, 'global', 'daos');
        //     const daos = (daosWrap.data as ModelUtil.NodeDAOs).daos.map(daoWrap => daoWrap.data as ModelUtil.NodeDAO);
        //     const varsWrap = ModelElementUtil.getInnerWrapFixed(wrap, 'global', 'variables');
        //     const vars = (varsWrap.data as ModelUtil.NodeVariables).items.map(varWrap => varWrap.data as ModelUtil.NodeVariable);
        //     return [daos, vars];
        // }, []);

        const isCreate = useMemo(() => {
            return props.temp.data == null;
        }, []);

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                ls.method.value = data.method;
                if (data.method === 'bool') {
                    assert(data.condition != undefined, 'data.conditionがundefinedであってはならない。');
                    ls.condition.value = data.condition;
                }
                // else if (data.method === 'switch' || data.method === 'range') {
                else if (data.method === 'switch') {
                    assert(data.target != undefined, 'data.targetがundefinedであってはならない。');
                    ls.target.value = data.target;
                }
                invalidate();
            }
        }, []);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            const method = ls.method.value as CaseType;

            let items: ModelUtil.WrapElement[] = [];
            if (props.temp.data != null) {
                items = (props.temp.data as Data).items;
            }
            let condition: string | undefined = undefined;
            let target: string | undefined = undefined;
            if (method === 'bool') condition = ls.condition.value;
            // if (method === 'switch' || method === 'range') target = ls.target.value;
            if (method === 'switch') target = ls.target.value;
            let targetId: string | undefined = undefined;
            const data: Data = {
                method,
                condition: condition,
                target: target,
                items
            }
            setTempData(data);
        }, targetFroms);

        const caseType = ls.method.value as CaseType;
        const useCondition = caseType === 'bool';
        // const useTarget = caseType === 'switch' || caseType === 'range';
        const useTarget = caseType === 'switch';
        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Method"
                        jsx={<FormUtil.Combobox
                            width={200}
                            isReadOnly={!isCreate}
                            checkable={ls.method}
                            setCheckable={(checkable) => {
                                ls.method = checkable;
                                invalidate();
                            }}
                            extend={() => {
                                if (caseType !== 'when') ls.condition.value = '';
                                // if (caseType !== 'switch' && caseType !== 'range') ls.target.value = '';
                                if (caseType !== 'switch') ls.target.value = '';
                            }}
                            headBlank
                            list={CaseTypes.map(type => {
                                return { value: type, labelText: type }
                            })}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Target Id"
                        isVisible={useTarget}
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={ls.target}
                            setCheckable={(checkable) => {
                                ls.target = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={!useTarget ? [] : [
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkStringLength(value, 1, 24),
                                    errorType: "length"
                                },
                                // {
                                //     checker: (value) => !existNameList.includes(value),
                                //     errorType: "relation"
                                // }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Condition"
                        isVisible={useCondition}
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={ls.condition}
                            setCheckable={(checkable) => {
                                ls.condition = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={!useCondition ? [] : [
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                // {
                                //     checker: (value) => ValidateUtil.checkStringLength(value, 1, 64),
                                //     errorType: "length"
                                // },
                                // {
                                //     checker: (value) => !existNameList.includes(value),
                                //     errorType: "relation"
                                // }
                            ]}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'case';
        }

        override createAction(manageItems: ModelUtil.ManageItems, tempData: object): void {
            const node = manageItems.focusNode;
            const element = node.data as ModelUtil.WrapElement;
            element.data = tempData;
            // const modelData = element.data as ModelUtil.NodeFunc;

            const caseData = element.data as Data;
            if (caseData.method === 'bool') {
                for (let i = 0; i < 2; i++) {
                    ModelElementUtil.addElementNodeDeep(node, {
                        type: 'bool',
                        data: {
                            bool: i === 0,
                            elements: []
                        } as ModelUtil.NodeBoolData
                    });
                }
            }
            manageItems.invalidate();
        }


        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeCase;
