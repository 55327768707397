import styled from "styled-components";
import MonacoEditor from "@monaco-editor/react";
import { shikiToMonaco } from "@shikijs/monaco";
import { getHighlighter } from "shiki/bundle/web";
import FormUtil from "../../../../../../../common/component/form/formUtiil";

namespace EditorUtil {

    export const FormulaEditor = (props: {
        checkable: FormUtil.CheckableValue;
        invalidate: () => void;
    }) => {

        return (
            <_EditorWrap>
                <MonacoEditor
                    height={400}
                    language={'javascript'}
                    theme="dark-plus"
                    onMount={(editor, monaco) => {
                        (async () => {
                            const highlighter = await getHighlighter({
                                themes: ["dark-plus"],
                                langs: [],

                            });

                            // monaco.languages.register({ id: "js" });

                            shikiToMonaco(highlighter, monaco);
                        })();
                    }}
                    value={props.checkable.value}
                    onChange={(e) => {
                        props.checkable.value = e?.toString() ?? '';
                        props.invalidate();
                    }}
                />
            </_EditorWrap>
        );
    }
};

export default EditorUtil;

export const _EditorWrap = styled.div`
    display: inline-block;
    margin: 4px  4px 0 4px;
    width: calc(100% - 8px);
`;