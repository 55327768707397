import { useContext, useMemo } from "react";
import styled from "styled-components";
import { GlobalContext } from "../../../entry/systemEntry";
import assert from "assert";
import StoreProject from "../../../../redux/store/storeProject";
import TreeUtil from "../../../../../common/component/tree/treeUtil";
import ModelUtil from "../util/modelUtil";
import ModelElementUtil from "../util/modelElementUtil";
import StoreSystem from "../../../../redux/store/storeSystem";
import NodeStlarg from "../editor/decrare/nodeStlarg";
import NodeStyle from "../editor/decrare/nodeStyle";
import PrefixUtil from "../util/prefixUtil";
import AssertUtil from "../../../../../common/assertUtil";
import StyleChooser from "../editor/decrare/styleChooser";
import StylePropDefiner from "../editor/decrare/style/stylePropDefiner";
import NodePrpclbk from "../editor/var/nodePrpclbkEditor";
import NodeFunction from "../editor/var/func/nodeFunction";
import NodeField from "../editor/var/nodeField";
import NodeIterate from "../editor/nodeIterate";
import ReaderUtil from "../../../gui/readerUtil";

namespace ScopeManagerBak {

    // export type KeyOwner = 'state' | 'variable' | 'iterator' | 'propField' | 'arg';
    export type KeyOwner = 'state' | 'variable' | 'iterator';
    export type ValueKeyField = {
        prefix: string;
        key: string;
        dataType: ModelUtil.DataType;
        array: number;
        structId?: string;
    }

    export type FunctionItem = {
        id: string;
        ret?: ModelUtil.Field;
        args: ModelUtil.NodeModelField[];
    }
    export type StyleItem = {
        id: string;
        // src: string;
        defs: StylePropDefiner.Record[];
        args: NodeStlarg.Data[];
    }
    export type StlargItem = {
        id: string;
    }

    export const isObject = (field: {
        dataType: ModelUtil.DataType;
        array: number;
    }) => field.array >= 1 || field.dataType === 'struct';

    // const getKeyPrefix = (owner: KeyOwner) => {
    //     switch (owner) {
    //         case 'state': return PrefixUtil.STATE;
    //         case 'cache': return 'c';
    //         case 'arg': return 'a';
    //         case 'propField': return 'pf';
    //         case 'iterator': return 'i';
    //     }
    // };

    export const Monitor = () => {

        const { store, setStore, dispatcher } = useContext(GlobalContext);

        assert(store.project != undefined, 'store.project is null.');
        const scope = store.system.scope;

        const jsxes = useMemo(() => {
            const jsxList: JSX.Element[] = [];

            const addRecordJsx = (
                targetName: string,
                items: undefined | any[],
                callback: (item: any) => JSX.Element
            ) => {
                if (items == undefined) return;
                jsxList.push(
                    <_Label key={jsxList.length}>{targetName}</_Label>
                );
                if (items.length > 0) {
                    items.forEach(item => {
                        jsxList.push(
                            <_Record key={jsxList.length}>{callback(item)}</_Record>
                        );
                    });
                } else {
                    jsxList.push(
                        <_Record key={jsxList.length}>-</_Record>
                    );
                }
            }

            const getValueKeyJsx = (valueKey: ValueKeyField) => {
                const array = '[]'.repeat(valueKey.array);
                const [type, color] = valueKey.dataType !== 'struct' ? [valueKey.dataType, "#fddf35"] : [valueKey.structId, "#ff5e5e"];
                const typeJsx = <_Span color={color}>@{type}{array}</_Span>;
                // const prefixJsx = <_Span color="#ecffb6">{valueKey.prefix}</_Span>;
                return <>{`__`}<_Span color="#6ffc38">{valueKey.key}</_Span>{`__`}: {typeJsx}</>;
            }
            const addValueKeyJsx = (targetName: string, valueKeys: undefined | ValueKeyField[]) => {
                addRecordJsx(targetName, valueKeys, getValueKeyJsx);
            }

            /** データ型のJSXを生成 */
            const getStructJsx = (item: ReaderUtil.StructObject) => {
                const idJsx = <_Span color="#fa7edf">{item.id}</_Span>;
                const listStr = item.fields.map(f => f.id).join(', ');
                const argsJsx = <_Span color="#bcf0b794">{`{${listStr}}`}</_Span>;
                return <>{idJsx} {argsJsx}</>;
            }
            addRecordJsx('Structs', scope.structItems, getStructJsx);

            // addValueKeyJsx('Arguments', scope.argValueKeys);
            addValueKeyJsx('States', scope.stateValueKeys);
            addValueKeyJsx('Variables', scope.variableValueKeys);
            // addValueKeyJsx('Property Fields', scope.propFieldValueKeys);
            addValueKeyJsx('Iterates', scope.iterateValueKeys);

            /** 関数のJSXを生成 */
            const getFunctionJsx = (item: FunctionItem) => {
                const idJsx = <_Span color="#38fce2">{item.id}</_Span>;
                const argNames = item.args.map(a => ModelUtil.getFieldType(a));
                const argsJsx = <_Span color="#90e4f396">{argNames.join(', ')}</_Span>;
                const getRetJsx = () => {
                    const ret = item.ret;
                    if (ret == undefined) return <_Span color='#cccccc'>void</_Span>;
                    return <><_Span color='#e60000'>{ModelUtil.getField(ret)}</_Span></>;
                }
                return <>*{idJsx}({argsJsx}){' => '}{getRetJsx()}</>;
            }
            /** スタイルのJSXを生成 */
            const getDesignUnitJsx = (item: StyleItem) => {
                const idJsx = <_Span color="#fc8638">{item.id}</_Span>;
                const argNames = item.args.map(a => a.id);
                const argsJsx = <_Span color="#ece79595">{argNames.join(', ')}</_Span>;
                return <>*{idJsx}{': '}({argsJsx})</>;
            }

            addRecordJsx('Functions', scope.functionItems, getFunctionJsx);
            // addRecordJsx('Property Callbacks', scope.prpclbkItems, getFunctionJsx);
            addRecordJsx('Styles', scope.styleItems, getDesignUnitJsx);

            return jsxList;
        }, [scope]);

        return (<_Wrap>
            {jsxes}
        </_Wrap>);
    }

    /**
     * ノードに対応したスコーププロパティを取得する
     * @param node ノード
     * @returns スコーププロパティ
     */
    export const getScopeProps = (node: TreeUtil.ElementNode): StoreSystem.Scope => {
        const wrap = node.data as ModelUtil.WrapElement;
        const props = StoreSystem.getBlankScope();

        let structs: null | ReaderUtil.StructObject[] = null;
        let states: null | ModelUtil.NodeModelField[] = null;
        let variables: null | ModelUtil.NodeModelField[] = null;
        // let funcargs: null | ModelUtil.NodeModelField[] = null;
        // let prpflds: null | ModelUtil.NodeModelField[] = null;
        let prpclbks: null | NodePrpclbk.Data[] = null;
        let functions: null | NodeFunction.Data[] = null;
        let iterators: null | NodeIterate.Data[] = null;
        let styles: null | NodeStyle.Data[] = null;

        // /** 除外リスト */
        // const ignoreTypes: ModelUtil.NodeType[] = ['dtype'];

        const loadStyles = () => ModelElementUtil.getReferableStyles(node);
        const loadStructs = () => ModelElementUtil.getReferableStructs(node);
        const loadFunctions = () => ModelElementUtil.getReferableFunctions(node);

        // if (ModelElementUtil.isAppSubordinate(node) && !ignoreTypes.includes(wrap.type)) {

        // /** アプリのルートノード */
        // const appRootWrap = ModelElementUtil.getAppRootWrap(node);

        // const loadStates = () => ModelElementUtil.getStatesFromApp(appRootWrap);
        const loadStates = () => ModelElementUtil.getReferableStates(node);
        const loadVariables = () => ModelElementUtil.getReferableVariables(node);
        const loadPrpflds = () => ModelElementUtil.getPropFieldsFromCurrent(node);
        const loadPrpclbks = () => ModelElementUtil.getPrpclbksFromCurrent(node);
        const loadIterators = () => ModelElementUtil.getIteratorsFromCurrent(node);
        const loadFuncargs = () => ModelElementUtil.getArgumentFromCurrent(node);

        /** 関数以下の要素であるか判定 */
        const isFunctionSub = () => ModelElementUtil.isFunctionSub(node);

        let valueKeyAccepter = (field: ValueKeyField): boolean => true;

        switch (wrap.type) {
            case 'focus':
            case 'assign':
            case 'arradd':
            case 'arrdel':
            case 'native':
            case 'execute': {
                structs = loadStructs();
                states = loadStates();
                variables = loadVariables();
                functions = loadFunctions();
                if (ModelElementUtil.isComponentSubordinate(node)) {
                    // prpflds = loadPrpflds();
                    variables = variables.concat(loadPrpflds());
                    prpclbks = loadPrpclbks();
                }
                if (isFunctionSub()) {
                    // funcargs = loadFuncargs();
                    variables = variables.concat(loadFuncargs());
                }
                iterators = loadIterators();
            } break;
            // case 'tagdiv':
            case 'tag':
            case 'text': {
                // case 'tagspan': {
                structs = loadStructs();
                states = loadStates();
                variables = loadVariables().concat(loadPrpflds());
                // prpflds = loadPrpflds();
                prpclbks = loadPrpclbks();
                iterators = loadIterators();
                styles = loadStyles();
                functions = loadFunctions();
                // functions = loadFunctions(). concat(loadPrpclbks());
            } break;
            case 'fetch': {
                structs = loadStructs();
                states = loadStates();
                variables = loadVariables().concat(loadPrpflds());
                // prpflds = loadPrpflds();
                iterators = loadIterators();
            } break;
            case 'compuse': {
                structs = loadStructs();
                states = loadStates();
                variables = loadVariables().concat(loadPrpflds());
                // prpflds = loadPrpflds();
                // prpclbks = loadPrpclbks();
                functions = loadFunctions();
                // functions = loadFunctions().concat(loadPrpclbks());
                iterators = loadIterators();
            } break;
            case 'input': {
                structs = loadStructs();
                variables = loadVariables().concat(loadPrpflds());
                // prpflds = loadPrpflds();
                functions = loadFunctions();
            } break;
            case 'iterate': {
                valueKeyAccepter = (field: ValueKeyField): boolean => {
                    return field.array === 0 && field.dataType === 'number';
                }
                structs = loadStructs();
                states = loadStates();
                variables = loadVariables().concat(loadPrpflds());
                // prpflds = loadPrpflds();
            } break;
            case 'style': {
                structs = loadStructs();
                valueKeyAccepter = (field: ValueKeyField): boolean => {
                    return field.array === 0 && ['color', 'number'].includes(field.dataType);
                }
                if (ModelElementUtil.isComponentSubordinate(node)) {
                    states = loadStates();
                    variables = loadVariables().concat(loadPrpflds());
                    // prpflds = loadPrpflds();
                    iterators = loadIterators();
                }
                styles = loadStyles();
            } break;
            case 'states':
            case 'retention': {
                structs = loadStructs();
                states = loadStates();
                variables = loadVariables();
            } break;
            case 'variable':
            case 'field':
            case 'state': {
                structs = loadStructs();
            } break;
            case 'case':
            case 'bool':
            case 'switch':
            case 'when':
            case 'accept': {
                structs = loadStructs();
                states = loadStates();
                functions = loadFunctions();
                variables = loadVariables();
            } break;
        }

        const build = (
            valueKeys: ValueKeyField[],
            prefix: string,
            field: ModelUtil.NodeModelField,
            address: string[]
        ) => {
            assert(structs != null, 'structs is null.');
            let fieldId = field.id;
            // モデルフィールドの場合、親情報を付与する
            if (address.length > 0) fieldId = `${address.join('.')}.${fieldId}`;
            valueKeys.push({
                ...field,
                prefix,
                key: `${prefix}.${fieldId}`,
            });
            // // 文字列の場合、長さも取得できるようにする
            // if (NodeField.isPremitive(field) && field.dataType === 'string') {
            //     valueKeys.push({
            //         ...field,
            //         dataType: 'number',
            //         prefix,
            //         key: `${prefix}.${fieldId}.${PrefixUtil.STR_LEN}`,
            //     });
            // }
            // // 配列の場合、要素数を取得できるようにする
            // if (field.array >= 1) {
            //     valueKeys.push({
            //         prefix,
            //         key: `${prefix}.${fieldId}.${PrefixUtil.LIST_LEN}`,
            //         dataType: 'number',
            //         array: 0
            //     });
            // } else
            if (field.dataType === 'struct') {
                const model = structs.find(m => m.id === field.structId);
                assert(model != undefined, `dtypeリストに存在しない。search:[${field.structId}], id:${field.id}, list:[${structs.map(t => t.id).join(',')}]`);
                model.fields.forEach(f => {
                    build(valueKeys, prefix, f, address.concat(field.id));
                });
            }
        }

        const getValueKeys = (prefix: string, entities: ModelUtil.NodeModelField[] | null) => {
            if (entities != null) {
                const valueKeys: ValueKeyField[] = [];
                entities.forEach(entity => build(valueKeys, prefix, entity, []));
                return valueKeys;
            }
        }
        props.stateValueKeys = getValueKeys(PrefixUtil.STATE, states);
        props.variableValueKeys = getValueKeys(PrefixUtil.VARIABLE, variables);
        // props.argValueKeys = getValueKeys(PrefixUtil.ARGUMENT, funcargs);
        // props.propFieldValueKeys = getValueKeys(PrefixUtil.PRPFLD, prpflds);

        if (iterators != null) {
            const valueKeys: ValueKeyField[] = [];
            iterators.forEach(it => {
                const addItProps = (suffix: string) => {
                    const owner: KeyOwner = 'iterator';
                    // const prefix = getKeyPrefix(owner);
                    valueKeys.push({
                        prefix: PrefixUtil.ITERATE,
                        key: `${PrefixUtil.ITERATE}.${it.id}.${suffix}`,
                        dataType: 'number',
                        array: 0
                    });
                }
                addItProps(PrefixUtil.LIST_CUR);
                addItProps(PrefixUtil.LIST_LEN);
                props.iterateValueKeys = valueKeys;
            });
        }

        const buildFunctionItems = (mastarItems: NodeFunction.Data[] | null) => {
            if (mastarItems != null) {
                const destItems: FunctionItem[] = [];
                mastarItems.forEach(d => {
                    const argsData = ModelElementUtil.getInnerWrapFixed({ type: 'func', data: d }, 'args').data as ModelUtil.ArgsData;
                    destItems.push({
                        id: `${PrefixUtil.FUNCTION}.${d.id}`,
                        ret: d.ret,
                        args: argsData.args.map(a => {
                            return a.data as ModelUtil.NodeModelField;
                        })
                    });
                });
                return destItems;
            }
        }

        // フィルターを適用
        if (props.stateValueKeys != undefined) props.stateValueKeys = props.stateValueKeys.filter(valueKeyAccepter);
        if (props.variableValueKeys != undefined) props.variableValueKeys = props.variableValueKeys.filter(valueKeyAccepter);
        // if (props.propFieldValueKeys != undefined) props.propFieldValueKeys = props.propFieldValueKeys.filter(valueKeyAccepter);
        // if (props.argValueKeys != undefined) props.argValueKeys = props.argValueKeys.filter(valueKeyAccepter);

        props.functionItems = buildFunctionItems(functions);
        if (prpclbks != null && props.functionItems != undefined) {
            props.functionItems = props.functionItems.concat(prpclbks.map(c => ({
                id: c.id,
                ret: c.ret,
                args: c.args.map(a => a.data)
            })));
        }

        // if (prpclbks != null) {
        //     props.prpclbkItems = prpclbks.map(c => ({
        //         id: `${PrefixUtil.PRPCLBK}.${c.id}`,
        //         args: c.args.map(a => a.data as ModelUtil.NodeModelField)
        //     }));
        // }

        // }

        if (structs != null) {
            props.structItems = structs;
        }
        if (styles != null) {
            const items: StyleItem[] = [];
            // console.log(styles);
            styles.forEach(s => {
                const inhDefs = s.inherits.map(inh => {
                    const inhSt = items.find(it => it.id === inh.refId);
                    assert(inhSt != undefined, AssertUtil.errorFindFromArray('inhSt', inh.refId, items.map(i => i.id)));
                    return inhSt.defs;
                });
                // let src = '';
                // if (inhDefs.length > 0) {
                //     src += inhDefs.join() + '\n'
                // }
                // src += s.src;
                const defs = inhDefs.reduce((prev, cur) => prev.concat(cur), []);
                defs.push(...s.defs);

                const getArgDatas = (refer: StyleChooser.Refer) => {
                    const list: NodeStlarg.Data[] = [];
                    const inhSt = items.find(it => it.id === refer.refId);
                    assert(inhSt != undefined, AssertUtil.errorFindFromArray('inhSt', refer.refId, items.map(i => i.id)));

                    inhSt.args.forEach((arg) => {
                        // キーが存在しなければ未定義（abstract）なので追加
                        if (refer.args.find(a => a.key === arg.id) == undefined) {
                            list.push(arg);
                        }
                    });
                    return list;
                }
                items.push({
                    id: s.id,
                    defs,
                    args: s.inherits.reduce((prev, inh) => {
                        return prev.concat(getArgDatas(inh));
                    }, [] as NodeStlarg.Data[]).concat(
                        s.args.map(wrap => wrap.data as NodeStlarg.Data)
                    )
                });
            });
            props.styleItems = items;
        }
        return props;
    }
}

export default ScopeManagerBak;

const _Wrap = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #57686b;
    overflow-y: auto;
`;

const _Label = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: calc(100% - 4px);
    height: 28px;
    /* background-color: #f800003d; */
    margin: 1px 0 0 2px;
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    padding: 0 0 0 2px;
    box-sizing: border-box;
    line-height: 32px;
`;

const _Record = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: calc(100% - 4px);
    height: 28px;
    background-color: #2936388b;
    margin: 1px 0 0 2px;
    font-size: 18px;
    color: #ffffff88;
    font-weight: 600;
    padding: 0 0 0 2px;
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;
    white-space: nowrap;
`;

const _Span = styled.span<{
    color: string;
}>`
    color: ${props => props.color};
`;