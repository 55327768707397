import { useEffect, useState, useContext, useMemo } from "react";
import AbstractModelEditor from "./abstractModelEditor";
import FormUtil from "../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../common/component/form/validateUtil";
import TreeUtil from "../../../../../common/component/tree/treeUtil";
import ModelUtil from "../util/modelUtil";
import { GlobalContext } from "../../../entry/systemEntry";
import assert from "assert";
import ModelElementUtil from "../util/modelElementUtil";
import ModelEditDialog from "./modelEditDialog";
import DataUtil from "../../../../../common/dataUtil";

namespace NodeIterate {

    export type Data = {
        itrCntFml: string;
        id: string;
        elements: ModelUtil.WrapElement[];
    }

    type LocalState = {
        itrCntFml: FormUtil.CheckableValue;
        id: FormUtil.CheckableValue;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {

        const { store, dispatcher } = useContext(GlobalContext);

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;
        const [daos, states] = useMemo(() => {
            const funcRootwrap = ModelElementUtil.getAppRootWrap(manageItems.focusNode);

            const daos = ModelElementUtil.getFetchesFromApp(funcRootwrap);
            const states = ModelElementUtil.getGlobalStatesFromApp(funcRootwrap);
            return [daos, states];
        }, []);

        const [localState, setLocalState] = useState<LocalState>({
            itrCntFml: { value: '', errors: [] },
            id: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [localState.id, localState.itrCntFml];

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                localState.id.value = data.id;
                localState.itrCntFml.value = data.itrCntFml;
                invalidate();
            }
        }, []);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            let elements: ModelUtil.WrapElement[] = [];
            if (props.temp.data != null) {
                elements = (props.temp.data as Data).elements;
            }
            const data: Data = {
                id: localState.id.value,
                itrCntFml: localState.itrCntFml.value,
                elements
            }
            setTempData(data);
        }, targetFroms);

        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Index Id"
                        jsx={<FormUtil.TextField
                            width={200}
                            checkable={localState.id}
                            setCheckable={(checkable) => {
                                localState.id = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkStringLength(value, 1, 4),
                                    errorType: "length"
                                },
                                {
                                    checker: (value) => ModelUtil.checkIdChars(value),
                                    errorType: "value"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Loop Count"
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={localState.itrCntFml}
                            setCheckable={(checkable) => {
                                localState.itrCntFml = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                // {
                                //     checker: (value) => ModelElementUtil.checkAppliedFmlNum(value, { states, daos }),
                                //     errorType: "value"
                                // }
                                // {
                                //     checker: (value) => !existNameList.includes(value),
                                //     errorType: "relation"
                                // }
                            ]}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'iterate';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeIterate;
