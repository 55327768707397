import { useEffect, useState, useMemo, useContext } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import ModelUtil from "../../util/modelUtil";
import ModelElementUtil from "../../util/modelElementUtil";
import ModelEditDialog from "../modelEditDialog";
import { GlobalContext } from "../../../../entry/systemEntry";
import assert from "assert";
import TreeUtil from "../../../../../../common/component/tree/treeUtil";
import RefactoringUtil from "../../util/refactoringUtil";
import PrefixUtil from "../../util/prefixUtil";
import NodeStruct from "../nodeStruct";
import NodeCompdef from "../decrare/nodeCompdef";
import DefineUtil from "../../util/defineUtil";

namespace NodeField {

    type LocalState = {
        id: FormUtil.CheckableValue;
        dataType: FormUtil.CheckableValue;
        structId: FormUtil.CheckableValue;
        array: FormUtil.CheckableValue;
        defval: FormUtil.CheckableValue;
    }

    export const getPremitiveTypes = (): ModelUtil.DataType[] => {
        return ModelUtil.DataTypes.filter(d => {
            const ignores: ModelUtil.DataType[] = ['struct'];
            return !ignores.includes(d);
        });
    }
    export const isPremitive = (field: ModelUtil.Field) => {
        return field.array === 0 && getPremitiveTypes().includes(field.dataType);
    }

    // const getDefaultValue = (dType: ModelUtil.DataType) => {
    //     switch (dType) {
    //         case 'any':
    //         case 'string': return '';
    //         case 'number': return '0';
    //         case 'boolean': return 'false';
    //         case 'color': return '#000';
    //     }
    //     throw new Error(`dTypeがプリミティブ型でない。dType:[${dType}]`);
    // }

    export const getDefaultValue = (dataType: ModelUtil.DataType) => {
        switch (dataType) {
            case 'any':
            case 'string': return '';
            case 'number': return 0;
            case 'boolean': return false;
            case 'color': return '#000';
            case 'function': return (() => { });
        }
        throw new Error(`dataTypeがプリミティブ型でない。dataType:[${dataType}]`);
    }

    export interface MemberData extends ModelUtil.NodeModelField {
        defval?: string;
    }

    export interface VariableData extends ModelUtil.NodeModelField {
        isRefer: boolean;
        items?: ModelUtil.WrapElement[];
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
        nodeType: ModelUtil.NodeType;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);
        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [localState, setLocalState] = useState<LocalState>({
            id: { value: '', errors: [] },
            dataType: { value: 'string', errors: [] },
            structId: { value: '', errors: [] },
            array: { value: '0', errors: [] },
            defval: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const [dtypes, hasAssign] = useMemo(() => {

            const dtypesAll = ModelElementUtil.getReferableStructs(manageItems.focusNode);
            let dtypes = dtypesAll;
            // フィールド要素の場合、親要素の型は除外する
            if (props.nodeType === 'field') {
                const parentModel = (manageItems.focusNode.parent?.data as ModelUtil.WrapElement).data as NodeStruct.Data;
                dtypes = dtypesAll.filter(m => m.id !== parentModel.id);
            }

            let hasAssign = false;
            if (props.temp.data != null &&
                ModelElementUtil.nodeTypes('variable', 'state').includes(props.nodeType)) {
                const data = props.temp.data as VariableData;
                hasAssign = data.items != undefined && data.items.length > 0;
            }
            return [dtypes, hasAssign];
        }, []);

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as ModelUtil.NodeModelField;
                localState.id.value = data.id;
                localState.dataType.value = data.dataType;
                localState.array.value = data.array.toString();
                switch (props.nodeType) {
                    case 'field': {
                        const memberData = data as MemberData;
                        localState.defval.value = memberData.defval ?? '';
                    } break;
                }
                if (data.structId != undefined) {
                    localState.structId.value = data.structId;
                }
                invalidate();
            }
        }, []);

        const targetFroms = [localState.id, localState.dataType, localState.structId, localState.array, localState.defval];

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            const dataType = localState.dataType.value as ModelUtil.DataType;
            let modelId: string | undefined = undefined;
            if (localState.structId.value != '') {
                modelId = localState.structId.value;
            }
            let data: undefined | ModelUtil.NodeModelField | VariableData = undefined;
            const baseData: ModelUtil.NodeModelField = {
                id: localState.id.value,
                dataType,
                structId: modelId,
                array: Number(localState.array.value)
            }
            switch (props.nodeType) {
                case 'field': {
                    let defval: undefined | string = undefined;
                    // プリミティブ型の場合のみ初期値をセットする
                    // if (isPremitive(baseData)) defval = getDefaultValue(baseData.dataType);
                    if (isPremitive(baseData)) {
                        // console.log(`[${localState.defval.value}]`);
                        defval = localState.defval.value;
                    }
                    const memberData: MemberData = {
                        ...baseData,
                        defval
                    }
                    data = memberData;
                } break;
                case 'state':
                case 'variable': {
                    let items: ModelUtil.WrapElement[] | undefined = undefined;
                    if (props.temp.data != null) {
                        items = (props.temp.data as VariableData).items;
                    }
                    const cacheData: VariableData = {
                        ...baseData,
                        isRefer: false,
                        items
                    }
                    data = cacheData;
                } break;
                default: {
                    data = baseData;
                } break;
            }
            setTempData(data);
        }, targetFroms);

        const isDispModel = () => {
            const list: ModelUtil.DataType[] = ['struct'];
            return list.includes(localState.dataType.value as ModelUtil.DataType);
        }

        const getFieldDisp = () => {
            const data = props.temp.data as ModelUtil.NodeModelField;
            return data == null ? '-' : ModelUtil.getFieldListItem(data).labelText;
        }

        /** ステートがプリミティブかどうか判定 */
        const isPremitiveState = () => {
            const array = Number(localState.array.value);
            const dataType = localState.dataType.value as ModelUtil.DataType;
            return array === 0 && getPremitiveTypes().includes(dataType);
        }

        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Id"
                        jsx={<FormUtil.TextField
                            width={200}
                            checkable={localState.id}
                            setCheckable={(checkable) => {
                                localState.id = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={ValidateUtil.idValidates}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Data Type"
                        jsx={<FormUtil.Combobox
                            width={200}
                            isReadOnly={hasAssign}
                            checkable={localState.dataType}
                            setCheckable={(checkable) => {
                                localState.dataType = checkable;
                                if (!isDispModel()) localState.structId.value = '';
                                invalidate();
                            }}
                            extend={() => {
                                if (!isPremitiveState()) {
                                    localState.defval.value = '';
                                } else {
                                    localState.defval.value = getDefaultValue(localState.dataType.value as ModelUtil.DataType).toString();
                                }
                            }}
                            list={ModelUtil.DataTypes
                                // // callbackはpropのみで利用可能
                                // .filter(type => {
                                //     return !(props.nodeType !== 'prop' && type === 'callback');
                                // })
                                .map(type => {
                                    return { value: type, labelText: type }
                                })}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Struct Id"
                        isVisible={isDispModel()}
                        jsx={<FormUtil.Combobox
                            width={280}
                            isReadOnly={hasAssign}
                            checkable={localState.structId}
                            setCheckable={(checkable) => {
                                localState.structId = checkable;
                                invalidate();
                            }}
                            headBlank
                            list={dtypes.map(model => {
                                const labelText = `${model.id} (${model.fields.length}fields)`;
                                return { value: model.id, labelText }
                            })}
                            validates={!isDispModel() ? [] : [
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Array"
                        // isVisible={(localState.dataType.value as ModelUtil.DataType) !== 'callback'}
                        jsx={<FormUtil.TextField
                            width={50}
                            isReadOnly={hasAssign}
                            checkable={localState.array}
                            setCheckable={(checkable) => {
                                localState.array = checkable;
                                invalidate();
                            }}
                            extend={() => {
                                if (!isPremitiveState()) {
                                    localState.defval.value = '';
                                } else {
                                    localState.defval.value = getDefaultValue(localState.dataType.value as ModelUtil.DataType).toString();
                                }
                            }}
                            isNumber
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkNumberRange(value, 0, 2),
                                    errorType: "length"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Preview"
                        jsx={<FormUtil.FixedText
                            width={280}
                            value={getFieldDisp()}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Default"
                        isEnabled={isPremitiveState()}
                        isVisible={props.nodeType === 'field'}
                        jsx={<FormUtil.TextField
                            width={250}
                            checkable={localState.defval}
                            setCheckable={(checkable) => {
                                localState.defval = checkable;
                                invalidate();
                            }}
                            isNumber={isPremitiveState() && (localState.dataType.value as ModelUtil.DataType) === 'number'}
                            isEnabled={isPremitiveState()}
                            validates={[
                            ]}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        private nodeType: ModelUtil.NodeType;

        constructor(nodeType: ModelUtil.NodeType) {
            super();
            this.nodeType = nodeType;
        }

        getNodeType(): ModelUtil.NodeType {
            return this.nodeType;
        }

        // private updateCallbackArgs = (node: TreeUtil.ElementNode) => {
        //     const element = (node.data as ModelUtil.WrapElement).data as ModelUtil.NodeModelField;
        //     if (element.dataType === 'callback') {
        //         ModelElementUtil.addElementNodeDeep(node, {
        //             type: 'args',
        //             data: {
        //                 args: []
        //             } as ModelUtil.NodeArgs
        //         });
        //     } else {

        //     }
        // }

        override createExtendAction(node: TreeUtil.ElementNode): void {
            const wrap = node.data as ModelUtil.WrapElement;
            const ownerWrap = node.parent?.parent?.data as ModelUtil.WrapElement;
            const compdefData = ownerWrap.data as NodeCompdef.Data;
            const projectRoot = ModelElementUtil.getProjectRootFromCurrent(node);
            // console.log(compdefData);
            switch (wrap.type) {
                case 'prpfld': {
                    RefactoringUtil.syncCompdefAddProps({
                        compdefId: compdefData.id,
                        insertIndex: (node.parent?.children.length as number) - 1,
                        projectRootNode: projectRoot,
                        defaultValue: ''
                    });
                } break;
            }
        }

        override deleteExtendAction(node: TreeUtil.ElementNode): void {
            const wrap = node.data as ModelUtil.WrapElement;
            const ownerWrap = node.parent?.parent?.data as ModelUtil.WrapElement;
            const compdefData = ownerWrap.data as NodeCompdef.Data;
            const projectRoot = ModelElementUtil.getProjectRootFromCurrent(node);
            // console.log(compdefData);
            switch (wrap.type) {
                case 'prpfld': {
                    RefactoringUtil.syncCompdefRemoveProps({
                        compdefId: compdefData.id,
                        removeIndex: this.getCurIndex(node),
                        projectRootNode: projectRoot,
                    });
                } break;
            }
        }

        override orderExtendAction(node: TreeUtil.ElementNode, offset: number): void {
            const wrap = node.data as ModelUtil.WrapElement;
            const ownerWrap = node.parent?.parent?.data as ModelUtil.WrapElement;
            const compdefData = ownerWrap.data as NodeCompdef.Data;
            const projectRoot = ModelElementUtil.getProjectRootFromCurrent(node);
            // console.log(compdefData);
            switch (wrap.type) {
                case 'prpfld': {
                    RefactoringUtil.syncCompdefOrderProps({
                        compdefId: compdefData.id,
                        targetIndex: this.getCurIndex(node),
                        projectRootNode: projectRoot,
                        offset
                    });
                } break;
            }
        }

        // override modifyAction(manageItems: ModelUtil.ManageItems, tempData: object): void {
        //     const node = manageItems.focusNode;
        //     (node.data as ModelUtil.WrapElement).data = tempData;
        //     this.updateCallbackArgs(node);
        //     manageItems.invalidate();
        // }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp}
                nodeType={this.nodeType}
            />);
        }
    }
}

export default NodeField;
