import styled from "styled-components";
import ModelUtil from "../develop/function/util/modelUtil";


namespace ErrorFrame {

    export type Props = {
        nodeType: ModelUtil.NodeType;
        nodeData: string;
        stack: string;
    }
    export const Componrnt = (props: {
        error: Props;
    }) => {

        const error = props.error;
        return (<>
            <_Header>{`An unexpected error occurred in the system. Please contact the developer.`}</_Header>
            <_Title>{`Node Data <${error.nodeType}>`}</_Title>
            <_Data readOnly value={error.nodeData}/>
            <_Title>Stack trace</_Title>
            <_Stack readOnly value={error.stack}/>
        </>);
    }
}

export default ErrorFrame;


const HEADER_WIDTH = 50;
const TITLE_WIDTH = 30;
const DATA_WIDTH = 230;

const _Header = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: ${HEADER_WIDTH}px;
    background-color: #640c1f;
    font-weight: 600;
    font-size:30px;
    color: #cfa7a7;
    overflow: hidden;
    padding: 0 0 0 4px;
`;
const _Title = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: ${TITLE_WIDTH}px;
    background-color: #000000;
    font-size: 18px;
    font-weight: 600;
    color: #a7cfca;
    overflow: hidden;
    padding: 0 0 0 4px;
`;
const _Data = styled.textarea<{
}>`
    display: inline-block;
    width: 100%;
    height: ${DATA_WIDTH}px;
    background-color: #16025f;
    resize: none;
    font-size: 18px;
    color: #f3e657;
    overflow: auto;
    padding: 2px;
    box-sizing: border-box;
    border: none;
    box-shadow: none;
`;
const _Stack = styled.textarea<{
}>`
    display: inline-block;
    width: 100%;
    height: calc(100% - ${HEADER_WIDTH + TITLE_WIDTH * 2 + DATA_WIDTH}px);
    background-color: #320000;
    resize: none;
    font-size: 18px;
    color: white;
    overflow: auto;
    padding: 2px;
    box-sizing: border-box;
    border: none;
    box-shadow: none;
`;