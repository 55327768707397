import assert from "assert";
import TreeUtil from "../../../../../common/component/tree/treeUtil";
import { Store } from "../../../../redux/store/store";
import StructManageTab from "../tree/structManageTab";
import { Dispatcher } from "../../../../redux/dispatcher/dispatcher";
import ModelElementUtil from "../util/modelElementUtil";
import ScopeManagerBak from "./scopeManagerBak";
import { wrap } from "module";
import ModelUtil from "../util/modelUtil";
import StructTreeContext from "../tree/structTreeContext";
import AppReader from "../../../gui/appReader";
import styled from "styled-components";
import NodeApp from "../editor/nodeApp";
import NodeLauncher from "../editor/release/nodeLauncher";
import DataUtil from "../../../../../common/dataUtil";
import MarkNodeFrame from "../markNode/markNodeFrame";

namespace KeyInputManager {

    export type KeySwitchName = 'control' | 'shift';
    export type KeyHold = {
        name: KeySwitchName;
        key: string;
        on: boolean;
    }

    export type Props = {
        e: React.KeyboardEvent<HTMLDivElement>;
        manageState: StructManageTab.LocalState;
        store: Store;
        setStore: React.Dispatch<React.SetStateAction<Store>>;
        dispatcher: Dispatcher;
        invalidate: () => void;
        save: () => void;
        treeRef: React.RefObject<HTMLDivElement>;
        rootNode: TreeUtil.ElementNode;
        setHideMenu: (menu: StructManageTab.HideMenu) => void;
    }
    export const keyDown = (props: Props) => {
        assert(props.manageState.focus != null, 'props.localState.focusがnullであってはならない。');
        const focus = props.manageState.focus;
        const focusNode = focus.node;
        if (focus == null ||
            props.store.system.dialog != null) return;
        const wrap = focus.node.data as ModelUtil.WrapElement;
        const setFocusNode = (node: TreeUtil.ElementNode) => {
            focus.node = node;
            props.store.system.scope = ScopeManagerBak.getScopeProps(node);
            props.invalidate();
        }
        const getKeyHoldState = (name: KeySwitchName) => {
            const hold = props.manageState.keyHolds.find(k => k.name === name);
            assert(hold != undefined, 'hold is undefined.');
            return hold.on;
        };

        const isHead = () => {
            const list = focusNode.parent?.children;
            assert(list != undefined, 'list is undefined.');
            return list.findIndex(l => l == focusNode) === 0;
        }
        const isTail = () => {
            const list = focusNode.parent?.children;
            assert(list != undefined, 'list is undefined.');
            const len = list.length;
            return list.findIndex(l => l == focusNode) === len - 1;
        }
        switch (props.e.key) {
            case 'ArrowRight': {
                if (!getKeyHoldState('shift')) {
                    if (focusNode.children.length > 0) {
                        focusNode.isOpen = true;
                        setFocusNode(focusNode.children[0]);
                    }
                } else {
                    if (!focusNode.isOpen) {
                        focusNode.isOpen = true;
                        props.invalidate();
                    }
                }
            } break;
            case 'ArrowLeft': {
                if (!getKeyHoldState('shift')) {
                    if (focusNode.parent != null) {
                        setFocusNode(focusNode.parent);
                    }
                } else {
                    if (focusNode.isOpen) {
                        focusNode.isOpen = false;
                        props.invalidate();
                    }
                }
            } break;
            case 'ArrowUp': {
                if (!getKeyHoldState('shift')) {
                    if (focusNode.parent == null) return;
                    const curIndex = focusNode.parent.children.findIndex(c => c == focusNode);
                    if (curIndex > 0) {
                        setFocusNode(focusNode.parent.children[curIndex - 1]);
                    }
                } else {
                    if (ModelElementUtil.isSwappableNode(wrap.type) && !isHead()) {
                        ModelElementUtil.swapOrder(focusNode, -1);
                        props.invalidate();
                    }
                }
            } break;
            case 'ArrowDown': {
                if (!getKeyHoldState('shift')) {
                    if (focusNode.parent == null) return;
                    const curIndex = focusNode.parent.children.findIndex(c => c == focusNode);
                    if (curIndex < focusNode.parent.children.length - 1) {
                        setFocusNode(focusNode.parent.children[curIndex + 1]);
                    }
                } else {
                    if (ModelElementUtil.isSwappableNode(wrap.type) && !isTail()) {
                        ModelElementUtil.swapOrder(focusNode, 1);
                        props.invalidate();
                    }
                }
            } break;
            case 'd': {
                if (ModelElementUtil.getCanDisableTypes().includes(wrap.type)) {
                    if (!wrap.disabled) wrap.disabled = true;
                    else wrap.disabled = undefined;
                    props.invalidate();
                }
            } break;
            case 'e': {
                const editor = StructTreeContext.getEditorFromNodeType(wrap.type);
                if (editor != undefined) {
                    const manageItems: ModelUtil.ManageItems = {
                        focusNode: focusNode,
                        setFocusNode,
                        invalidate: props.invalidate
                    }
                    // グローバルのキャッシュを更新
                    props.store.system.freeCache = manageItems;
                    props.dispatcher.struct.openEditDialog({
                        isCreate: false,
                        editor,
                        manageItems
                    });
                }
            } break;
            // テスト起動
            case ' ': {
                try {
                    const projectRootWrap = ModelElementUtil.getProjectRootFromCurrent(focusNode).data;
                    const app = ModelElementUtil.getAppRootWrap(focusNode).data as NodeApp.Data;
                    // props.store.system.canDialogClose = true;
                    // props.store.system.dialog = (<AppReader.Dialog projectRootWrap={projectRootWrap} launchNo={appData.seq}/>);

                    const launchersData = ModelElementUtil.getInnerWrapFixed(projectRootWrap, 'release', 'launchers').data as ModelUtil.NodeGenericItems;
                    const launcher = launchersData.items
                        .map(lw => (lw.data as NodeLauncher.Data))
                        .find(l => l.appId === app.id);
                    if (launcher != undefined) {
                        props.store.system.canDialogClose = true;
                        props.store.system.dialog = (<AppReader.TestDialog projectRootWrap={projectRootWrap} launchNo={launcher.no} />);
                        props.dispatcher.updateStore();
                    }
                } catch (e) {
                    alert('no app!');
                }
            } break;
        }

        if (getKeyHoldState('control')) {
            switch (props.e.key) {
                case 's': {
                    props.save();
                } break;
                case 'x': {
                    props.store.system.clipboad = {
                        source: JSON.parse(JSON.stringify(focusNode.data)),
                        cutAction: () => {
                            const wrap = focusNode.data as ModelUtil.WrapElement;
                            const editor = StructTreeContext.getEditorFromNodeType(wrap.type);
                            if (editor != undefined) {
                                editor.deleteExtendAction(focusNode);
                            }
                            const manageItems: ModelUtil.ManageItems = {
                                focusNode: focusNode,
                                setFocusNode,
                                invalidate: props.invalidate
                            }
                            ModelElementUtil.removeSelf(manageItems);
                        }
                    }
                    props.dispatcher.updateStore();
                } break;
                case 'c': {
                    props.store.system.clipboad = {
                        source: JSON.parse(JSON.stringify(focusNode.data)),
                        cutAction: () => {
                        }
                    }
                    props.dispatcher.updateStore();
                } break;
                case 'v': {
                    const isEnable = props.dispatcher.system.isAcceptClipboardPaste(focusNode);
                    if (isEnable) {
                        const clipboad = props.store.system.clipboad;
                        assert(clipboad != null, 'clipboad is null.');
                        ModelElementUtil.addElementNodeDeep(focusNode, clipboad.source);
                        props.store.system.clipboad = null;
                        // 追加したノードを選択した状態にする
                        focus.node = focusNode.children[focusNode.children.length - 1];
                        clipboad.cutAction();
                        props.invalidate();
                    }
                } break;
                case 'r': {
                    props.setHideMenu('mark-node');
                    
                    const cache: MarkNodeFrame.Cache = {
                        rootNode: props.rootNode,
                        isAdd: false
                    };
                    props.manageState.contextParam = null;
                    props.store.system.freeCache = cache;
                } break;
            }
        }

        props.manageState.keyHolds.forEach(k => {
            if (props.e.key === k.key) k.on = true;
        });
    }
    export const keyUp = (props: Props) => {

        props.manageState.keyHolds.forEach(k => {
            if (props.e.key === k.key) k.on = false;
        });
    }
}

export default KeyInputManager;

const _AppFrame = styled.div<{
    marginTop: number;
    marginLeft: number;
}>`
    display: inline-block;
    position: absolute;
    z-index: 11;
    top: ${props => props.marginTop}px;
    left: ${props => props.marginLeft}px;
    width: calc(100% - ${props => props.marginLeft * 2}px);
    height: calc(100% - ${props => props.marginTop * 2}px);
    background-color: #000405e6;
    /* border: 1px solid #474747; */
    border-radius: 4px;
    box-shadow: 10px 15px 15px #0000004b;
    overflow-y: auto;
`;