import { JsxElement } from "typescript";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../../common/component/form/validateUtil";
import ModelElementUtil from "../../../util/modelElementUtil";
import NodeFocus from "./nodeFocus";
import ModelUtil from "../../../util/modelUtil";
import EditorUtil from "../../ui/common/editorUtil";
import styled from "styled-components";
import ReaderUtil from "../../../../../gui/readerUtil";

namespace DirectForm {

    export type RecieveState = {
        direct: FormUtil.CheckableValue;
    }

    export const Component = (props: {
        focusInfo: NodeFocus.FocusInfo;
        localState: RecieveState;
        invalidate: () => void;
        isObject: boolean;
        structs: ReaderUtil.StructObject[];
        isArrayAdd?: boolean;
    }) => {
        const focusInfo = props.focusInfo;
        const localState = props.localState;
        const invalidate = props.invalidate;
        const isObject = props.isObject;
        const dtypes = props.structs;

        const isArrayAdd = props.isArrayAdd ?? false;

        const getValueForm = (jsx: JSX.Element) => {
            return <FormUtil.FormRecord
                titleLabel={'Value'}
                // isVisible={!isObject}
                jsx={jsx}
            />
        }

        const getFormsJsx = () => {
            switch (focusInfo.lastField.dataType) {
                case 'string': return (getValueForm(
                    <FormUtil.TextField
                        width={400}
                        checkable={localState.direct}
                        setCheckable={(checkable) => {
                            localState.direct = checkable;
                            invalidate();
                        }}
                        validates={isObject ? [] : [
                            // {
                            //     checker: (value) => ValidateUtil.checkRequired(value),
                            //     errorType: "required"
                            // }
                        ]}
                    />
                ));
                case 'any': return (<FormUtil.BorderPanel
                    title="formula"
                    height={120}
                    innerJsx={
                        // <FormUtil.TextArea
                        //     checkable={localState.directVal}
                        //     setCheckable={(checkable) => {
                        //         localState.directVal = checkable;
                        //         invalidate();
                        //     }}
                        //     validates={isObject ? [] : [
                        //         {
                        //             checker: (value) => ValidateUtil.checkRequired(value),
                        //             errorType: "required"
                        //         }
                        //     ]}
                        // />
                        <EditorUtil.FormulaEditor checkable={localState.direct} invalidate={invalidate} />
                    }
                />);
                case 'number': return getValueForm(<FormUtil.TextField
                    width={184}
                    checkable={localState.direct}
                    setCheckable={(checkable) => {
                        localState.direct = checkable;
                        invalidate();
                    }}
                    isNumber
                    validates={isObject ? [] : [
                        {
                            checker: (value) => ValidateUtil.checkRequired(value),
                            errorType: "required"
                        }
                    ]}
                />);
                case 'color': return getValueForm(<>
                    <FormUtil.TextField
                        width={184}
                        checkable={localState.direct}
                        setCheckable={(checkable) => {
                            localState.direct = checkable;
                            invalidate();
                        }}
                        validates={isObject ? [] : [
                            {
                                checker: (value) => ValidateUtil.checkValidHexColor(value),
                                errorType: "value"
                            }
                        ]}
                    />
                    <_Color type="color" value={localState.direct.value} onChange={(e) => {
                        localState.direct.value = e.target.value;
                        invalidate();
                    }} />
                </>);
                case 'boolean': return getValueForm(
                    <FormUtil.SwitchTwoFace
                        label1="False"
                        label2="True"
                        width={180}
                        rate1={55}
                        rate2={45}
                        isUse={localState.direct.value === 'true'}
                        callback={() => {
                            const flag = localState.direct.value === 'true';
                            localState.direct.value = String(!flag);
                            invalidate();
                        }}
                    />
                );
                default: return <></>;
            }
        }
        return (<>
            <FormUtil.FormRecord
                titleLabel={'Type'}
                isVisible={!isObject}
                jsx={<FormUtil.FixedText
                    value={ModelUtil.getField((() => {
                        const field = JSON.parse(JSON.stringify(focusInfo.lastField)) as ModelUtil.Field;
                        if (isArrayAdd) {
                            field.array--;
                        }
                        return field;
                    })())}
                />}
            />
            {getFormsJsx()}
        </>);
    }
}

export default DirectForm;

const _Color = styled.input`
  display: inline-block;
  position: relative;
  width: 50px;
  height: 30px;
  margin: 5px 0 0 4px;
`;