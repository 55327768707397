import styled, { css } from "styled-components";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { GlobalContext } from "../entry/systemEntry";
import { TabbedPane } from "../../../common/component/tab/tabbedPane";
import FormUtil from "../../../common/component/form/formUtiil";
import assert from "assert";
import ModelElementUtil from "../develop/function/util/modelElementUtil";
import ModelUtil from "../develop/function/util/modelUtil";
import AppReader from "./appReader";
import NodeApp from "../develop/function/editor/nodeApp";
import ServerUtil from "../../../common/serverUtil";
import StoreUser from "../../redux/store/storeUser";
import NodeLauncher from "../develop/function/editor/release/nodeLauncher";

namespace ProjectFrame {

    // export type AppProps = {
    // }

    type LocalState = {
        filter: FormUtil.CheckableValue;
        launcherNo: number;
    }

    export const Component = (props: {
        prjRootWrap: ModelUtil.WrapElement;
        devmd: StoreUser.Devmd | null;
    }) => {
        const { store, setStore, dispatcher } = useContext(GlobalContext);
        // assert(store.project != null, 'project is null.');

        const [ls, setLS] = useState<LocalState>({
            filter: { errors: [], value: '' },
            launcherNo: -1
        });
        const invalidate = () => setLS({ ...ls });

        // const prjRootWrap = store.project.rootData;
        const prjRootWrap = props.prjRootWrap;

        const [launchers] = useMemo(() => {
            const launchersWrap = ModelElementUtil.getInnerWrapFixed(prjRootWrap, 'release', 'launchers').data as ModelUtil.NodeGenericItems;
            const launchers = launchersWrap.items
                .map(w => w.data as NodeLauncher.Data)
            return [launchers];
        }, [store]);

        const launchNameJsxes = useMemo(() => {
            return launchers
                .filter(launcher => {
                    return launcher.appId.indexOf(ls.filter.value) !== -1 || launcher.name.indexOf(ls.filter.value) !== -1;
                })
                .map((launcher, i) => {
                    return <_LaunchItem
                        key={i}
                        isEnable={true}
                        isFocus={ls.launcherNo === launcher.no}
                        onClick={() => {
                            if (ls.launcherNo !== launcher.no) {
                                ls.launcherNo = launcher.no;
                            } else {
                                ls.launcherNo = -1;
                            }
                            invalidate();
                        }}
                    >{launcher.name}<_AppId> @{launcher.appId}</_AppId></_LaunchItem>
                });
        }, [launchers, ls.launcherNo, ls.filter]);


        // const appGUIJsx = appIndex === -1 ? <></> : (
        //     <_AppWrap>
        //         <AppReaderTab.Component
        //             targetAppWrap={apps[appIndex]}
        //         />
        //     </_AppWrap>
        // );

        // return (
        //     <_TabWrap>
        //         <TabbedPane
        //             tabElements={[
        //                 { name: 'Menu', cont: menuJsx, enable: true },
        //                 { name: 'Application', cont: appGUIJsx, enable: appIndex !== -1 },
        //             ]}
        //             activeNo={tabIndex}
        //             selectTabIndex={setTabIndex}
        //         />
        //     </_TabWrap>
        // );
        return (
            <_MenuWrap>
                <_LeftDiv>
                    <FormUtil.Record jsx={
                        <FormUtil.TextField
                            checkable={ls.filter}
                            setCheckable={(checkable) => {
                                ls.filter = checkable;
                                invalidate();
                            }}
                            width={450}
                        />
                    } />
                    <_FuncListDiv>{launchNameJsxes}</_FuncListDiv>
                </_LeftDiv>
                <_RightDiv>
                    <FormUtil.ButtonRecord
                        align="left"
                        buttons={[
                            {
                                label: 'Launch',
                                isEnable: ls.launcherNo !== -1,
                                width: 140,
                                callback: () => {
                                    store.system.canDialogClose = true;
                                    store.system.dialog = (<AppReader.TestDialog projectRootWrap={prjRootWrap} launchNo={ls.launcherNo} />);
                                    dispatcher.updateStore();
                                }
                            }
                        ]}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Name"
                        // isVisible={props.devmd != undefined}
                        isVisible={ls.launcherNo !== -1}
                        jsx={<FormUtil.FixedText
                            value={(() => {
                                if (ls.launcherNo === -1) return '-';
                                const launcher = launchers.find(l => l.no === ls.launcherNo);
                                assert(launcher != undefined, 'launcherがundefinedであってはならない。');
                                return launcher.name;
                            })()}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="App Id"
                        // isVisible={props.devmd != undefined}
                        isVisible={ls.launcherNo !== -1}
                        jsx={<FormUtil.FixedText
                            value={(() => {
                                if (ls.launcherNo === -1) return '-';
                                const launcher = launchers.find(l => l.no === ls.launcherNo);
                                assert(launcher != undefined, 'launcherがundefinedであってはならない。');
                                return launcher.appId;
                            })()}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Launch URL"
                        // isVisible={props.devmd != undefined}
                        isVisible={ls.launcherNo !== -1}
                        jsx={<FormUtil.FixedText
                            value={(() => {
                                if (props.devmd == null || ls.launcherNo === -1) return '-';
                                const hash = ServerUtil.getHashedKeyFromDevLaunch(props.devmd.seq, ls.launcherNo);
                                return `${ServerUtil.APP_SERVER_DOMAIN}/#/${ServerUtil.URL_ROUTER_LAUNCH}?v=${hash}`;
                            })()}
                        />}
                    />
                    {(() => {
                        if (ls.launcherNo === -1) return <></>;
                        const launcher = launchers.find(l => l.no === ls.launcherNo);
                        assert(launcher != undefined, 'launcherがundefinedであってはならない。');
                        if (launcher.args.length === 0) return <></>;
                        return (
                            <FormUtil.BorderPanel
                                title="arguments"
                                backgroundColor="#fcf2a21a"
                                innerJsx={<>{launcher.args.map((arg, i) => {
                                    return <FormUtil.FormRecord key={i}
                                        titleLabel={arg.key}
                                        jsx={
                                            <FormUtil.FixedText
                                                value={arg.value}
                                            />
                                        }
                                    />
                                })}</>}
                            />
                        );
                    })()}
                </_RightDiv>
            </_MenuWrap>
        );
    };
}

export default ProjectFrame;

const _TabWrap = styled.div<{
}>`
    display: inline-block;
    margin: 4px 0 0 4px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
`;

const _MenuWrap = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #dde7df;
`;

const _LeftDiv = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%;
    /* background-color: #f7f7f7; */
    vertical-align: top;
`;

const _FuncListDiv = styled.div<{
}>`
    display: inline-block;
    margin: 4px 0 0 8px;
    width: calc(100% - 16px);
    height: calc(100% - 50px);
    background-color: #dfdadac8;
    /* border: 2px solid #fdfdfd;
    box-sizing: border-box; */
`;

const _LaunchItem = styled.div<{
    isEnable: boolean;
    isFocus: boolean;
}>`
    display: inline-block;
    margin: 2px 0 0 0;
    width: 100%;
    height: 34px;
    background-color: #4a4c4e90;
    border-radius: 4px;
    color: #eeeeee;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    padding: 0 0 0 4px;
    box-sizing: border-box;
    ${props => props.isEnable ? '' : css`
        pointer-events: none;
        opacity: 0.5;
    `}
    ${props => !props.isFocus ? '' : css`
        background-color: #41909a;
    `}
    &:hover {
        background-color: #969696;  
        ${props => !props.isFocus ? '' : css`
            background-color: #59b0bb;
        `}
    }
    /* overflow: hidden; */
`;

const _AppId = styled.span<{
}>`
    color: #f84040;
`;

const _RightDiv = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%;
    /* background-color: #cdda9d; */
    vertical-align: top;
`;

const _AppWrap = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #babec2;
    padding: 8px;
    box-sizing: border-box;
`;
