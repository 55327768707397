import assert from "assert";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import DummyUtil from "../../../util/dummyUtil";
import styled, { css } from "styled-components";
import PrefixUtil from "../../../util/prefixUtil";
import ScopeManagerBak from "../../../option/scopeManagerBak";
import { useState } from "react";
import StyleFormulaDefiner from "./styleFormulaDefiner";

namespace StylePropDefiner {

    export type Record = {
        prop: string;
        val?: string;
        defs?: Record[];
    }

    type RecieveState = {
        fmls: StyleFormulaDefiner.Record[];
        defs: Record[];
        isPropEdit: boolean;
    }
    type LocalState = {
        focusIndex: number[];
        prop: FormUtil.CheckableValue;
        val: FormUtil.CheckableValue;
    }

    export const Component = (props: {
        recieveState: RecieveState;
        invalidate: () => void;
        stlargItems: ScopeManagerBak.StlargItem[];
    }) => {

        const [localState] = useState<LocalState>({
            focusIndex: [-1, -1],
            prop: { value: '', errors: [] },
            val: { value: '', errors: [] },
        });

        const recieveState = props.recieveState;
        const invalidate = props.invalidate;
        const stlargItems = props.stlargItems;

        const listFncs = {
            getFocusTop: () => localState.focusIndex[0],
            setFocusTop: (i: number) => {
                localState.focusIndex[0] = i;
                localState.focusIndex[1] = -1;
            },
            subsetFocusTop: (i: number) => {
                localState.focusIndex[0] = localState.focusIndex[0] + i;
                localState.focusIndex[1] = -1;
            },
            getFocusNest: () => localState.focusIndex[1],
            setFocusNest: (i: number) => localState.focusIndex[1] = i,
            isNoFocus: () => localState.focusIndex[0] === -1,
            resetForm: () => {
                localState.prop.value = '';
                localState.val.value = '';
            },
            isCurrNest: () => {
                const topIndex = listFncs.getFocusTop();
                if (topIndex === -1) return false;
                const def = recieveState.defs[topIndex];
                return def.defs != undefined;
            },
            getNestDefs: (focusTopIndex: number) => {
                const nestDefs = recieveState.defs[focusTopIndex].defs;
                assert(nestDefs != undefined, 'nestDefs is undefined.');
                return nestDefs;
            },
            insertDefs: (isNest: boolean) => {
                const newData: Record = !isNest ? {
                    prop: '',
                    val: ''
                } : {
                    prop: '',
                    defs: []
                };
                if (!listFncs.isCurrNest()) {
                    recieveState.defs.push(newData);
                } else {
                    listFncs.getNestDefs(listFncs.getFocusTop()).push(newData);
                }
                recieveState.isPropEdit = true;
            },
            isCurrNestItem: () => listFncs.getFocusTop() !== -1 && listFncs.getFocusNest() !== -1,
            getListTarget: () => {
                if (!listFncs.isCurrNestItem()) {
                    return recieveState.defs;
                } else {
                    const nestDefs = recieveState.defs[listFncs.getFocusTop()].defs;
                    assert(nestDefs != undefined, 'nestDefs is undefined.');
                    return nestDefs;
                }
            },
            getEditTarget: () => {
                const listTarget = listFncs.getListTarget();
                if (!listFncs.isCurrNestItem()) {
                    return listTarget[listFncs.getFocusTop()];
                } else {
                    return listTarget[listFncs.getFocusNest()];
                }
            },
            swapOrder: (isDown: boolean) => {
                const subset = !isDown ? -1 : 1;

                const index = !listFncs.isCurrNestItem() ? listFncs.getFocusTop() : listFncs.getFocusNest();
                const defs = listFncs.getListTarget();

                const cur = defs[index];
                defs[index] = defs[index + subset];
                defs[index + subset] = cur;
                if (!listFncs.isCurrNestItem()) localState.focusIndex[0] += subset;
                else localState.focusIndex[1] += subset;
            },
            isAcceptSwapOrder: (isDown: boolean) => {
                const index = !listFncs.isCurrNestItem() ? listFncs.getFocusTop() : listFncs.getFocusNest();
                if (recieveState.isPropEdit || index === -1) return false;
                if (!isDown) return index > 0;
                else return index < listFncs.getListTarget().length - 1;
            }
        }

        return (
            <FormUtil.BorderPanel
                title="properties"
                height={402}
                innerJsx={<>
                    <FormUtil.ButtonRecord align='left' buttons={[
                        {
                            label: '+Prop', width: 90,
                            isEnable: !recieveState.isPropEdit,
                            callback: () => {
                                listFncs.resetForm();
                                // let insIndex = localState.defs.length - 1;
                                // if (localState.focusIndex !== -1) insIndex = localState.focusIndex;
                                // localState.focusIndex = insIndex;
                                listFncs.insertDefs(false);
                                if (!listFncs.isCurrNest()) {
                                    listFncs.setFocusTop(recieveState.defs.length - 1);
                                } else {
                                    listFncs.setFocusNest(listFncs.getNestDefs(listFncs.getFocusTop()).length - 1);
                                }
                                invalidate();
                            }
                        },
                        {
                            label: '+Nest', width: 90,
                            isEnable: !listFncs.isCurrNest() && !recieveState.isPropEdit,
                            callback: () => {
                                listFncs.resetForm();
                                // let insIndex = localState.defs.length - 1;
                                // if (localState.focusIndex !== -1) insIndex = localState.focusIndex;
                                // localState.focusIndex = insIndex;
                                listFncs.insertDefs(true);
                                listFncs.setFocusTop(recieveState.defs.length - 1);
                                invalidate();
                            }
                        },
                        {
                            label: !recieveState.isPropEdit ? 'Edit' : 'Enter', width: 65,
                            isEnable: (!recieveState.isPropEdit && (listFncs.getFocusTop() !== -1 || listFncs.getFocusNest() !== -1)) ||
                                (recieveState.isPropEdit && localState.prop.value !== ''),
                            callback: () => {
                                const cur = listFncs.getEditTarget();
                                const prop = cur.prop;
                                const val = cur.val;
                                if (!recieveState.isPropEdit) {
                                    localState.prop.value = prop;
                                    if (val != undefined) {
                                        localState.val.value = val;
                                    }
                                    recieveState.isPropEdit = true;
                                } else {
                                    cur.prop = localState.prop.value;
                                    if (val != undefined) {
                                        cur.val = localState.val.value;
                                    }
                                    recieveState.isPropEdit = false;
                                }
                                invalidate();
                            }
                        },
                        {
                            label: 'Cancel', width: 95,
                            isEnable: recieveState.isPropEdit,
                            callback: () => {
                                const cur = listFncs.getEditTarget();
                                if (cur.prop === '') {
                                    listFncs.getListTarget().splice(localState.focusIndex[0], 1);
                                    localState.focusIndex[0]--;
                                }
                                recieveState.isPropEdit = false;
                                invalidate();
                            }
                        },
                        {
                            label: 'Delete', width: 95,
                            isEnable: !recieveState.isPropEdit && localState.focusIndex[0] !== -1,
                            callback: () => {
                                const index = !listFncs.isCurrNestItem() ? listFncs.getFocusTop() : listFncs.getFocusNest();
                                listFncs.getListTarget().splice(index, 1);
                                if (!listFncs.isCurrNestItem()) localState.focusIndex[0]--;
                                else localState.focusIndex[1]--;
                                invalidate();
                            }
                        },
                    ]} />
                    {(() => {
                        const listJsx = recieveState.defs.map((def, i) => {
                            const getFormJsx = (checkable: FormUtil.CheckableValue) => {
                                return <_Input value={checkable.value} onChange={(e) => {
                                    checkable.value = e.target.value;
                                    invalidate();
                                }} />;
                            };
                            const getInfoJsx = (targetDef: Record, target: 'prop' | 'val', isCurrentFocus: boolean) => {
                                const isEdit = isCurrentFocus && recieveState.isPropEdit;
                                if (!isEdit) {
                                    const v = target === 'prop' ? targetDef.prop : targetDef.val;
                                    assert(v != undefined, 'v is undefined.');
                                    if (target === 'prop') {
                                        const isReserve = DummyUtil.CSS_PROP_NAMES.includes(v)
                                        return <_Fixed><_Span color={!isReserve ? '#696969' : '#ff5e00'}>{v}</_Span></_Fixed>;
                                    } else if (target === 'val') {
                                        let value = v;
                                        const splitItem = '***';
                                        const varKeys = stlargItems.map(argItem => {
                                            return `__${PrefixUtil.STYLE_ARGUMENT}.${argItem.id}__`;
                                        }).concat(
                                            props.recieveState.fmls.map(fml => {
                                                return `\${${PrefixUtil.STYLE_FOUMULA}.${fml.id}}`;
                                            })
                                        );
                                        varKeys.forEach(argkey => {
                                            value = value.replaceAll(argkey, `${splitItem}${argkey}${splitItem}`);
                                        });
                                        const jsxes = value.split(splitItem).map((item, i) => {
                                            const isArgkey = varKeys.includes(item);
                                            if (!isArgkey) return <span key={i}>{item}</span>;
                                            else return <_Span key={i} color="#0400ff">{item}</_Span>;
                                        })
                                        return <_Fixed>{jsxes}</_Fixed>;
                                    }
                                } else {
                                    return getFormJsx(target === 'prop' ? localState.prop : localState.val);
                                }
                            }
                            const isCurrentFocus = localState.focusIndex[0] === i;
                            const focusAction = () => {
                                if (recieveState.isPropEdit) return;
                                if (listFncs.getFocusTop() !== i) listFncs.setFocusTop(i);
                                else listFncs.setFocusTop(-1);
                                invalidate();
                            };
                            if (def.val != undefined) {
                                return (<_Record key={i}
                                    isFocus={listFncs.getFocusTop() === i}
                                    onClick={focusAction}
                                >
                                    <_Prop>{getInfoJsx(def, 'prop', isCurrentFocus)}</_Prop>
                                    <_Val>{getInfoJsx(def, 'val', isCurrentFocus)}</_Val>
                                </_Record>);
                            } else if (def.defs != undefined) {
                                return (<_NestFrame key={i}
                                    isFocus={listFncs.getFocusTop() === i}
                                >
                                    <_Record
                                        isFocus={listFncs.getFocusTop() === i && listFncs.getFocusNest() === -1}
                                        onClick={focusAction}
                                    >
                                        <_Prop>{getInfoJsx(def, 'prop', isCurrentFocus && listFncs.getFocusNest() === -1)}</_Prop>
                                        <_Blank><_Fixed>{' {'}</_Fixed></_Blank>
                                    </_Record>
                                    {listFncs.getNestDefs(i).map((def, j) => {
                                        const isCurrentFocus = localState.focusIndex[0] === i && localState.focusIndex[1] === j;
                                        const isFocus = listFncs.getFocusTop() === i && listFncs.getFocusNest() === j;
                                        return (<_Record key={j}
                                            isFocus={isFocus}
                                            onClick={() => {
                                                if (recieveState.isPropEdit) return;
                                                if (!isFocus) {
                                                    listFncs.setFocusTop(i);
                                                    listFncs.setFocusNest(j);
                                                } else {
                                                    listFncs.setFocusTop(-1);
                                                    listFncs.setFocusNest(-1);
                                                }
                                                invalidate();
                                            }}
                                        >
                                            <_Prop>{getInfoJsx(def, 'prop', isCurrentFocus)}</_Prop>
                                            <_Val>{getInfoJsx(def, 'val', isCurrentFocus)}</_Val>
                                        </_Record>);
                                    })}
                                    <_Record
                                        isFocus={false}
                                        onClick={focusAction}
                                    >
                                        <_Blank><_Fixed>{'}'}</_Fixed></_Blank>
                                    </_Record>
                                </_NestFrame>);
                            } else throw new Error('defのフィールドval, defsのどちらかが設定されている必要がある。');
                        });
                        return (<>
                            <_ListFrame>{listJsx}</_ListFrame>
                            <_OrderButtonFrame>
                                <FormUtil.ButtonItem button={{
                                    label: '↑', width: 40,
                                    isEnable: listFncs.isAcceptSwapOrder(false),
                                    callback: () => {
                                        listFncs.swapOrder(false);
                                        invalidate();
                                    }
                                }} />
                                <FormUtil.ButtonItem button={{
                                    label: '↓', width: 40,
                                    isEnable: listFncs.isAcceptSwapOrder(true),
                                    callback: () => {
                                        listFncs.swapOrder(true);
                                        invalidate();
                                    }
                                }} />
                            </_OrderButtonFrame>
                        </>);
                    })()}
                </>}
            />
        );
    }
};

export default StylePropDefiner;


const ListFrameWidth = 520;
const _ListFrame = styled.div<{
}>`
    display: inline-block;
    width: ${ListFrameWidth}px;
    height: calc(100% - 42px);
    background-color: #edf1ef97;
    vertical-align: top;
    overflow-y: auto;
`;
const _Record = styled.div<{
    isFocus: boolean;
}>`
    display: inline-block;
    width: 100%;
    height: 30px;
    /* background-color: #7bd89e; */
    ${props => !props.isFocus ? '' : css`
        background-color: #d87b8f9e;
    `}
`;

const PROP_WIDTH = 160;
const _Prop = styled.div<{
}>`
    display: inline-block;
    width: ${PROP_WIDTH}px;
    height: 100%;
    /* background-color: #fcfcfc; */
    border: 1px solid #000;
    box-sizing: border-box;
    vertical-align: top;
    border-radius: 4px;
`;
const _Val = styled.div<{
}>`
    display: inline-block;
    width: calc(100% - ${PROP_WIDTH}px);
    height: 100%;
    /* background-color: #fcfcfc; */
    border: 1px solid #000;
    box-sizing: border-box;
    vertical-align: top;
    border-radius: 4px;
`;
const _Blank = styled.div<{
}>`
    display: inline-block;
    /* width: calc(100% - ${PROP_WIDTH}px); */
    height: 100%;
    /* background-color: #fcfcfc; */
    vertical-align: top;
`;

const _Input = styled.input<{
}>`
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #fcfcfc;
    border: none;
    box-sizing: border-box;
    font-size: 18px;
    /* font-weight: 600; */
    color: #000007;
`;
const _Fixed = styled.span<{
}>`
    display: inline-block;
    width: 100%;
    height: 100%;
    /* background-color: #fcfcfc; */
    border: none;
    padding: 0 0 0 2px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 600;
    color: #666666;
    overflow-x: hidden;
`;
const _OrderButtonFrame = styled.div<{
}>`
    display: inline-block;
    width: calc(100% - ${ListFrameWidth}px);
    height: calc(100% - 45px);
    /* background-color: #53c7ee3d; */
    vertical-align: top;
    padding: 140px 0 0 0;
    box-sizing: border-box;
`;

const _NestFrame = styled.div<{
    isFocus: boolean;
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    /* height: 30px; */
    padding: 8px;
    box-sizing: border-box;
    /* background-color: #7bd89e; */
    ${props => !props.isFocus ? '' : css`
        background-color: #d8c27b3d;
    `}
`;

const _Span = styled.span<{
    color: string;
}>`
    color: ${props => props.color};
`;