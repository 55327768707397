import { useEffect, useState } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ModelUtil from "../../util/modelUtil";
import ModelElementUtil from "../../util/modelElementUtil";
import ModelEditDialog from "../modelEditDialog";

namespace NodeEffect {

    type LocalState = {
        comment: FormUtil.CheckableValue;
        depFmls: FormUtil.CheckableValue;
    }
    export type Data = {
        comment: string;
        depFmls?: string[];
        mngs: ModelUtil.WrapElement[];
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {

        const [ls, setLs] = useState<LocalState>({
            comment: { value: '', errors: [] },
            depFmls: { value: '', errors: [] },
        });
        const invalidate = () => setLs({ ...ls });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [ls.comment, ls.depFmls];

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                ls.comment.value = data.comment;
                ls.depFmls.value = data.depFmls == undefined ? '' : data.depFmls.join('\n');
                invalidate();
            }
        }, []);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            props.temp.inputOK = true;

            let mngs: ModelUtil.WrapElement[] = [];
            if (props.temp.data != null) {
                mngs = (props.temp.data as Data).mngs;
            }
            let depFmls = undefined;
            if(ls.depFmls.value !== '') {
                depFmls = ls.depFmls.value.split('\n').map(dep => dep);
            }
            const data: Data = {
                comment: ls.comment.value,
                depFmls,
                mngs
            }
            setTempData(data);
        }, targetFroms);

        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Comment"
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={ls.comment}
                            setCheckable={(checkable) => {
                                ls.comment = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                        />}
                    />
                    <FormUtil.BorderPanel
                        title="dependencies"
                        height={400}
                        innerJsx={<>
                            <FormUtil.TextArea
                                checkable={ls.depFmls}
                                setCheckable={(checkable) => {
                                    ls.depFmls = checkable;
                                    invalidate();
                                }}
                                validates={[
                                    {
                                        checker: (value) => {
                                            return true;
                                        },
                                        errorType: 'value'
                                    },
                                ]}
                            />
                        </>}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'effect';
        }

        override createAction(manageItems: ModelUtil.ManageItems, tempData: object): void {
            const node = manageItems.focusNode;
            const element = node.data as ModelUtil.WrapElement;
            element.data = tempData;
            // const modelData = element.data as ModelUtil.NodeFunc;

            ModelElementUtil.addElementNodeDeep(node,
                {
                    type: 'proc',
                    data: {
                        items: []
                    } as ModelUtil.NodeProcedure
                }
            );
            manageItems.invalidate();
        }

        override modifyAction(manageItems: ModelUtil.ManageItems, tempData: object): void {
            const node = manageItems.focusNode;
            (node.data as ModelUtil.WrapElement).data = tempData;
            manageItems.invalidate();
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeEffect;
