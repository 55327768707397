import assert from "assert";
import NodeCompuse from "../develop/function/editor/ui/nodeCompuse";
import ModelUtil from "../develop/function/util/modelUtil";
import ModelElementUtil from "../develop/function/util/modelElementUtil";
import ReaderUtil from "./readerUtil";
import NodePrpclbk from "../develop/function/editor/var/nodePrpclbkEditor";
import PrefixUtil from "../develop/function/util/prefixUtil";
import React, { useEffect, useMemo, useState } from "react";
import NodeCompdef from "../develop/function/editor/decrare/nodeCompdef";
import NodeField from "../develop/function/editor/var/nodeField";
import NodeAssign from "../develop/function/editor/proc/focus/nodeAssign";

namespace CompuseManager {

    export const Component = (props: {
        appCache: ReaderUtil.GlobalAppCache;
        getElementsJsxRec: (elements: ModelUtil.WrapElement[], dynamicProps: ReaderUtil.DynamicProps) => JSX.Element[];
        data: NodeCompuse.Data;
    }) => {

        const compuse = props.data;
        // console.log(compuse);
        /** コンポーネント定義 */
        // const compdef = props.appCache.compdefMap.find(c => c.def.id === compuse.compId);
        const compdef = props.appCache.dynamics.compdefs.find(c => c.id === compuse.compId);
        assert(compdef != undefined, 'compdef is undefined.');
        const compdefWrap: ModelUtil.WrapElement = { type: 'compdef', data: compdef };
        const compProps = ModelElementUtil.getInnerWrapFixed(compdefWrap, 'props').data as ModelUtil.NodeProps;

        const compStateItems = ModelElementUtil.getInnerWrapFixed(compdefWrap, 'store', 'states').data as ModelUtil.NodeGenericItems;
        const compElements = ModelElementUtil.getInnerWrapFixed(compdefWrap, 'elements').data as ModelUtil.NodeElements;

        const buildStates = () => {
            // console.log(`buildStates items:[${compStateItems.items.map(i => (i.data as ModelUtil.NodeModelField).id).join(',')}]`);
            // if(compuse.compId === 'TaskDialog') {

            //     console.log(compStateItems);
            // }
            return compStateItems.items.map(item => {
                const data = item.data as NodeField.VariableData;
                const fieldObj = props.appCache.globalFixedProps.buildInitialStructureObject(data);
                // 初期値が設定されている場合
                if (data.items != undefined && data.items.length > 0) {
                    const dynamics = ReaderUtil.cloneDynamicProps(props.appCache.dynamics);
                    // フレームワークで初期値を設定するために、このスコープでのみ一時的に追加
                    ReaderUtil.addFieldObject(dynamics.componentStates, fieldObj);
                    ReaderUtil.affectOperation({
                        data: {
                            target: 'state',
                            rootId: fieldObj.field.id,
                            levelProps: [],
                            items: data.items,
                        }, appCache: { ...props.appCache, dynamics }
                    });
                }
                return fieldObj;
            });
        }
        // const buildStates = () => {
        //     console.log(`buildStates items:[${compStateItems.items.map(i => (i.data as ModelUtil.NodeModelField).id).join(',')}]`);
        //     compStateItems.items.forEach(item => {
        //         const data = item.data as ModelUtil.NodeModelField;
        //         const fieldObj = props.appCache.globalFixedProps.buildInitialStructureObject(data);
        //         compStates.push(fieldObj);
        //     });
        // }
        const [dummy, setDummy] = useState<any>({});
        // const [compStates, setCompStates] = useState<ReaderUtil.FieldObject[]>(buildStates());
        const compStates = useMemo(() => {
            return buildStates();
        }, []);

        // useEffect(() => {
        //     if(compuse.compId === 'TaskDialog') {
        //         console.log(`CompuseManager Effect!!!!![${JSON.stringify(compStates)}]`);
        //     }
        // }, []);
        // useEffect(() => {
        //     // console.log('CompuseManager Effect!!!!!');
        //     // buildStates();
        //     const states = buildStates();
        //     if(compuse.compId === 'Form') {

        //         console.log(states);
        //     }
        //     // console.log(states);
        //     setCompStates(states);
        //     // setCompStates({...compStates});
        // }, []);

        // // console.log(compStates);
        // if (compStates == undefined) {
        //     // console.log('CompuseManager Effect!!!!!');
        //     return <></>;
        // }

        // const upperLevelCompStates = compdef.dynamics.componentStates;
        const currentDynamics = props.appCache.dynamics;
        /** コンポーネント定義時の動的キャッシュをクローン */
        // if(compuse.compId === 'Form') {

        //     console.log(compdef.dynamics.componentStates);
        // }
        // const cloneDynamics = ReaderUtil.cloneDynamicProps(compdef.dynamics);
        const cloneDynamics = ReaderUtil.cloneDynamicProps(props.appCache.dynamics);
        // const cloneDynamics: ReaderUtil.DynamicProps = { ...compdef.dynamics };

        // 同名は上書きするようにする
        // cloneDynamics.componentStates = cloneDynamics.componentStates.concat(compStates);
        cloneDynamics.componentStates = cloneDynamics.componentStates.slice();
        compStates.forEach(cs => {
            // const sameIndex = cloneDynamics.componentStates.findIndex(s => s.field.id === cs.field.id);
            // if (sameIndex !== -1) {
            //     cloneDynamics.componentStates.splice(sameIndex, 1);
            // }
            // cloneDynamics.componentStates.push(cs);
            ReaderUtil.addFieldObject(cloneDynamics.componentStates, cs);
        });

        const compdefData = compdefWrap.data as NodeCompdef.Data;
        if (compdefData.partialId != undefined) {
            const partialSets: ReaderUtil.PartialSet[] = cloneDynamics.partialSets;
            const invalidate = () => setDummy({});

            partialSets.push({
                id: compdefData.partialId,
                invalidate
            });
            // 再描画関数の更新
            ReaderUtil.updateInvalidateCallback(cloneDynamics);
        }

        // 引数を動的プロパティに追加
        // compProps.props.forEach((p, i) => {
        //     try {
        //         const propValue = compuse.props[i];
        //         switch (p.type) {
        //             case 'prpfld': {
        //                 let objValue: any = null;
        //                 const prpfld = p.data as ModelUtil.NodeModelField;
        //                 objValue = ReaderUtil.getFomulaAplliedValue(propValue, props.appCache);
        //                 assert(objValue != null, `objValue is null. [${prpfld.id}]`);
        //                 const fldObj: ReaderUtil.FieldObject = {
        //                     field: prpfld,
        //                     value: objValue
        //                 };
        //                 cloneDynamics.prpflds.push(fldObj);
        //             } break;
        //             case 'prpclbk': {
        //                 const prpclbk = p.data as NodePrpclbk.Data;
        //                 // console.log(`propValue: ${propValue}, [${dynamicProps.dispatchers.map(d => d.id).join(', ')}]`);
        //                 const prefixKey = propValue.split('.')[0];
        //                 const eventKey = propValue.split('.')[1];
        //                 const getTargetList = () => {
        //                     switch (prefixKey) {
        //                         case PrefixUtil.FUNCTION: return cloneDynamics.funcs;
        //                         case PrefixUtil.PRPCLBK: return cloneDynamics.prpclbks;
        //                     }
        //                     throw new Error(`prefixKeyの値が不正。(prefixKey: '${prefixKey}')`);
        //                 }
        //                 const event = getTargetList().find(d => d.id === eventKey);
        //                 assert(event != undefined, 'event is undefined.');
        //                 // nextDynamicProps.prpclbks.push({
        //                 //     id: prpclbk.id,
        //                 //     funcargs: prpclbk.args.map(a => a.data as ModelUtil.NodeModelField),
        //                 //     procItems: event.procItems
        //                 // });
        //                 cloneDynamics.prpclbks.push({
        //                     id: prpclbk.id,
        //                     funcargs: prpclbk.args.map(a => a.data as ModelUtil.NodeModelField),
        //                     procItems: event.procItems,
        //                     callback: (args: []) => {
        //                         event.callback(args);
        //                     }
        //                 });
        //             } break;
        //         }
        //     } catch (e) {
        //         props.appCache.dispStackTrace({ type: 'compuse', data: compuse }, e);
        //     }
        // });

        // console.log(`compdefId: ${compdef.def.id} props: ${compProps.props.map(p => p.data.id)}`);
        compProps.props.forEach((p, i) => {
            try {
                let propValue = compuse.props[i];
                // console.log(p);
                switch (p.type) {
                    case 'prpfld': {
                        let objValue: any = null;
                        const prpfld = p.data as ModelUtil.NodeModelField;
                        if (ModelUtil.isObjectField(prpfld)) {
                            propValue = `__${propValue}__`;
                        }
                        objValue = ReaderUtil.getFomulaAplliedValue(propValue, props.appCache);
                        assert(objValue != null, `objValue is null. [${prpfld.id}]`);
                        const fldObj: ReaderUtil.FieldObject = {
                            field: prpfld,
                            value: objValue
                        };

                        const variables = cloneDynamics.variables;
                        const sameIndex = variables.findIndex(v => v.field.id === fldObj.field.id);
                        if (sameIndex !== -1) {
                            variables.splice(sameIndex, 1);
                        }
                        cloneDynamics.variables.push(fldObj);
                        // console.log(`add: [${fldObj.field.id}]`);
                    } break;
                    case 'prpclbk': {
                        const prpclbk = p.data as NodePrpclbk.Data;
                        // console.log(`propValue: ${propValue}, [${dynamicProps.dispatchers.map(d => d.id).join(', ')}]`);
                        const prefixKey = propValue.split('.')[0];
                        const funcKey = propValue.split('.')[1];
                        const getTargetList = () => {
                            switch (prefixKey) {
                                case PrefixUtil.FUNCTION: return currentDynamics.funcs;
                                case PrefixUtil.PRPCLBK: return currentDynamics.prpclbks;
                            }
                            throw new Error(`prefixKeyの値が不正。(prefixKey: '${prefixKey}')`);
                        }
                        const func = getTargetList().find(d => d.id === funcKey);
                        assert(func != undefined, 'func is undefined.');
                        // nextDynamicProps.prpclbks.push({
                        //     id: prpclbk.id,
                        //     funcargs: prpclbk.args.map(a => a.data as ModelUtil.NodeModelField),
                        //     procItems: event.procItems
                        // });
                        cloneDynamics.funcs.push({
                            id: prpclbk.id,
                            funcargs: prpclbk.args.map(a => a.data as ModelUtil.NodeModelField),
                            procItems: func.procItems,
                            callback: (args: []) => {
                                func.callback(args);
                            }
                        });
                    } break;
                }
            } catch (e) {
                props.appCache.dispStackTrace({ type: 'compuse', data: compuse }, e);
            }
        });

        // 引数の生成で例外が発生した場合、コンポーネントを表示しない
        if (props.appCache.isError()) {
            return <></>;
        }

        const retentionWrap = ModelElementUtil.getInnerWrapFixed(compdefWrap, 'retention');
        const elementsData: ModelUtil.GenericElements = {
            elements: compElements.elements
        }
        const elementsWrap: ModelUtil.WrapElement = { type: 'elements', data: elementsData };

        // 子要素のコンポーネント定義を生成
        if (compuse.elements != undefined) {
            const propsWrap: ModelUtil.WrapElement = {
                type: 'props',
                data: {
                    props: []
                } as ModelUtil.NodeProps
            };
            let retentionWrap: ModelUtil.WrapElement = {
                type: 'retention',
                data: {
                    items: []
                } as ModelUtil.NodeRetention
            };
            let elementWrap: ModelUtil.WrapElement = {
                type: 'elements',
                data: {
                    elements: compuse.elements
                } as ModelUtil.NodeElements
            };
            const originalRetentionWrap = compuse.elements.find(el => el.type === 'retention');
            if (originalRetentionWrap != undefined) {
                retentionWrap = originalRetentionWrap;
                const originalElementsWrap = compuse.elements.find(el => el.type === 'elements');
                assert(originalElementsWrap != undefined, 'originalElementsWrapがundefinedであってはならない。');
                elementWrap = originalElementsWrap;
            }
            const childCompdef: NodeCompdef.Data = {
                id: PrefixUtil.getCompChildId(compuse.compId),
                mngs: [
                    propsWrap,
                    ModelElementUtil.getStoreInitialWrap(),
                    retentionWrap,
                    elementWrap,
                ]
            };
            const sameIndex = cloneDynamics.compdefs.findIndex(d => d.id === childCompdef.id);
            assert(sameIndex === -1, `childCompdef.id[${childCompdef.id}]が既にcloneDynamics.compdefsに存在する。`)
            cloneDynamics.compdefs.push(childCompdef);
        }

        cloneDynamics.belengComponent = compuse.compId;

        return <>{props.getElementsJsxRec([retentionWrap, elementsWrap], cloneDynamics)}</>;
    }
}

export default CompuseManager;