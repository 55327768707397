import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "./abstractModelEditor";
import FormUtil from "../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../common/component/form/validateUtil";
import TreeUtil from "../../../../../common/component/tree/treeUtil";
import ModelUtil from "../util/modelUtil";
import DataUtil from "../../../../../common/dataUtil";
import { GlobalContext } from "../../../entry/systemEntry";
import ModelEditDialog from "./modelEditDialog";
import ModelElementUtil from "../util/modelElementUtil";
import assert from "assert";
import NodeFunction from "./var/func/nodeFunction";

namespace NodeStruct {
    
    export type Data = {
        id: string;
        fields: ModelUtil.WrapElement[];
    }

    type LocalState = {
        id: FormUtil.CheckableValue;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const [localState, setLocalState] = useState<LocalState>({
            id: { value: '', errors: [] }
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [localState.id];

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as NodeFunction.Data;
                localState.id.value = data.id;
                invalidate();
            }
        }, []);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            let fields: ModelUtil.WrapElement[] = [];
            if (props.temp.data != null) {
                fields = (props.temp.data as Data).fields;
            }
            const data: Data = {
                id: localState.id.value,
                fields
            }
            setTempData(data);
        }, targetFroms);

        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Id"
                        jsx={<FormUtil.TextField
                            width={200}
                            checkable={localState.id}
                            setCheckable={(checkable) => {
                                localState.id = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkStringLength(value, 1, 16),
                                    errorType: "length"
                                },
                                {
                                    checker: (value) => ModelUtil.checkPascalCase(value),
                                    errorType: "value"
                                }
                            ]}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'struct';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeStruct;
