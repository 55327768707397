import assert from "assert";
import ModelUtil from "../system/contents/develop/function/util/modelUtil";
import ValidateUtil from "./component/form/validateUtil";
import { Store } from "../system/redux/store/store";

namespace DataUtil {

    const BORDER = '----------------------------------------';

    export type KeyValue = {
        key: string;
        value: string;
    }
    export type KeyObject = {
        key: string;
        obj: any;
    }

    // const VALUE_REG_EX = /\$\{.*?\}/g;
    export const VALUE_REG_EX = /__.*?__/g;
    export const VALUE_REG_EX_INNER = /__(.*?)__/;

    export type Flag = '1';

    export const blankToUndefined = (value: string) => {
        return value === '' ? undefined : value;
    }

    export const flag = (flag: '1' | undefined) => {
        return flag === '1';
    }

    export const convertKeyValuesToLineText = (keyValues: KeyValue[]) => {
        if (keyValues == undefined) return '';
        return keyValues.map(kv => `${kv.key}:${kv.value}`).join('\n');
    };

    export const convertLineTextToKeyValues = (lineText: string) => {
        let hasError = false;
        const keyValues: KeyValue[] = [];
        if (lineText !== '') {
            lineText.split('\n').some(attr => {
                const items = attr.split(':');
                if (items.length === 2) {
                    keyValues.push({
                        key: items[0], value: items[1]
                    })
                } else {
                    hasError = true;
                    return 1;
                }
            });
        }
        if (!hasError) {
            return keyValues;
        }
    }

    export const getJsonClone = (json: object) => {
        return JSON.parse(JSON.stringify(json));
    }

    export const applyFormula = (value: string) => {
        let ret: any = value;
        try {
            ret = Function(`return (${value})`)();
        } catch (error) {
            // console.log(value);
        }
        return ret;
    }

    const removeComments = (code: string) => {
        return code
            // 行コメントを削除
            .replace(/\/\/.*(?=\n)/g, '')
            // ブロックコメントを削除
            .replace(/\/\*[\s\S]*?\*\//g, '');
    }

    const borderInText = (lineText: string) => {
        return `${BORDER}\n${lineText}\n${BORDER}`;
    }

    export const applyFormulaArgs = (args: any[], formula: string) => {
        let ret: any = removeComments(formula);
        const func = new Function(...(args.map((a, i) => `__arg_${i}`)), `return (${ret})`);
        try {
            ret = func(...args);
        } catch (error) {
            // console.log(args);
            // throw new Error(args.toString());
            const argsText = args.map((a, i) => `__args_${i}(${typeof a}): ${a.toString()}`).join('\n');
            throw new Error(`式が実行できなかった。\n■formula\n${borderInText(formula)}\n■args\n${borderInText(argsText)}\n${(error as Error).stack}`);
        }
        return ret;
    }

    export const swapOrder = (list: any[], targetIndex: number, offset: number) => {
        const swapNode = list[targetIndex];
        list[targetIndex] = list[targetIndex + offset];
        list[targetIndex + offset] = swapNode;
    }

    /**
    * システム日付の取得
    * @returns システム日付(XXXX/XX/XX)
    */
    export const getSystemDate = () => {
        let today = new Date();
        const year = ('0000' + today.getFullYear()).slice(-4);
        const month = ('00' + (today.getMonth() + 1)).slice(-2);
        const day = ('00' + today.getDate()).slice(-2);
        return year + '-' + month + '-' + day;
    };

    /**
    * システム日付・時間の取得
    * @returns システム日付(XXXX-XX-XX XX:XX:XX)
    */
    export const getSystemTime = () => {
        let today = new Date();
        const year = ('0000' + today.getFullYear()).slice(-4);
        const month = ('00' + (today.getMonth() + 1)).slice(-2);
        const day = ('00' + today.getDate()).slice(-2);
        const hour = ('00' + today.getHours()).slice(-2);
        const minute = ('00' + today.getMinutes()).slice(-2);
        const seconds = ('00' + today.getSeconds()).slice(-2);
        return `${year}-${month}-${day} ${hour}:${minute}:${seconds}`;
    };

    /**
    * 日付をXXXX/XX/XXの形に変更
    * @returns 日付(XXXX/XX/XX)
    */
    export const getStringDate = (date: Date) => {
        const year = ('0000' + date.getFullYear()).slice(-4);
        const month = ('00' + (date.getMonth() + 1)).slice(-2);
        const day = ('00' + date.getDate()).slice(-2);
        return year + '/' + month + '/' + day;
    }
    /**
     * 値の代入可否を検証する
     * @param field 代入対象の型
     * @param obj 代入する値
     */
    export const testAcceptAssign = (field: ModelUtil.Field, obj: any) => {
        let isAccept = false;
        const type = typeof obj;

        // 配列の場合
        if (field.array >= 1) {
            if (Array.isArray(obj)) isAccept = true;
        } else {
            if (field.structId != undefined) {
                if (type === 'object') isAccept = true;
            } else {
                switch (field.dataType) {
                    case 'string':
                    case 'color': {
                        if (type === 'string') {
                            // color型である且つカラーコードに変換できなければエラー
                            if (ValidateUtil.checkValidHexColor(obj) || field.dataType !== 'color') isAccept = true;
                        }
                    } break;
                    case 'number': if (type === 'number') isAccept = true; break;
                    case 'boolean': if (type === 'boolean') isAccept = true; break;
                    case 'function': if (type === 'function') isAccept = true; break;
                }
            }
        }

        if (!isAccept) {
            const struct = field.structId == undefined ? '' : `structId: ${field.structId}`;
            throw new Error(`型が合わないため代入できない。focusType:{dataType: ${field.dataType}, array: ${field.array} ${struct}}, assign:${obj}(${type})`);
        }
    }

    export const utf8ToBase64 = (str: string) => {
        // return window.btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt(p1, 16))));
        return window.btoa(unescape(encodeURIComponent(str)));
    }
    export const base64ToUTF8 = (str: string) => {
        return decodeURIComponent(escape(window.atob(str)));
    }
    
    export const loadFile = (store: Store, setStore: React.Dispatch<React.SetStateAction<Store>>) => {
        (async () => {
            const [fileHandle] = await window.showOpenFilePicker();
            const file = await fileHandle.getFile();
            const fileContents = await file.text();
            store.system.fileHandle = fileHandle;
            const text = DataUtil.base64ToUTF8(fileContents);
            const data = JSON.parse(text);
            store.project = data;
            console.log('load file!');
            setStore({ ...store });
        })();
    }

    export const saveFile = (store: Store, setStore: React.Dispatch<React.SetStateAction<Store>>) => {
        let fileHandle = store.system.fileHandle;

        const options = {
            types: [
                {
                    accept: {
                        'text/plain': ['.txt'],
                    },
                },
            ],
        };

        const plainData = JSON.stringify(store.project);
        if (fileHandle != null) {
            (async () => {
                //ファイルへ書き込むための FileSystemWritableFileStream を作成
                const writable = await fileHandle.createWritable();
                //テキストデータの書き込み
                const text = DataUtil.utf8ToBase64(plainData);
                await writable.write(text);
                //ファイルを閉じる
                await writable.close();
                console.log('save file!');
                setStore({ ...store });
            })();
        } else {
            window.showSaveFilePicker(options).then((handle) => {
                (async () => {
                    //ファイルへ書き込むための FileSystemWritableFileStream を作成
                    const writable = await handle.createWritable();
                    //テキストデータの書き込み
                    const text = DataUtil.utf8ToBase64(plainData);
                    await writable.write(text);
                    //ファイルを閉じる
                    await writable.close();
                    store.system.fileHandle = handle;
                    console.log('save!');
                    setStore({ ...store });
                })();
            }).catch(() => {
                console.log('キャンセルされました');
            });
        }
    }
}

export default DataUtil;