import TreeUtil from "../../../../../common/component/tree/treeUtil";
import DataUtil from "../../../../../common/dataUtil";
import NodeCompuse from "../editor/ui/nodeCompuse";
import NodeEntry from "../editor/ui/nodeEntry";
import ModelUtil from "./modelUtil";

namespace RefactoringUtil {

    export type DeligateProps = {
        targetRoot: TreeUtil.ElementNode;
        callback: (node: TreeUtil.ElementNode) => void;
        isScanChildren: (node: TreeUtil.ElementNode) => boolean;
    }

    export const deligate = (request: DeligateProps) => {
        const rec = (node: TreeUtil.ElementNode) => {
            request.callback(node);
            if (request.isScanChildren(node)) node.children.forEach(c => rec(c));
        }
        rec(request.targetRoot);
    }

    export const syncCompdefId = (args: {
        projectRoot: TreeUtil.ElementNode;
        oldId: string;
        newId: string;
    }) => {
        deligate({
            targetRoot: args.projectRoot,
            isScanChildren: (node) => {
                const stopList: ModelUtil.NodeType[] = [];
                return !stopList.includes((node.data as ModelUtil.WrapElement).type);
            },
            callback: (node) => {
                const wrap = node.data as ModelUtil.WrapElement;
                // console.log(wrap.type);
                switch (wrap.type) {
                    case 'compuse': {
                        const data = wrap.data as NodeCompuse.Data;
                        if (data.compId === args.oldId) {
                            data.compId = args.newId
                        }
                    } break;
                    case 'entry': {
                        const data = wrap.data as NodeEntry.Data;
                        if (data.compId === args.oldId) {
                            data.compId = args.newId
                        }
                    } break;
                }
            }
        });
    }

    /**
     * コンポーネント定義の引数を追加した際に、参照先の引数リストに初期値を追加する
     * @param args 
     */
    export const syncCompdefAddProps = (args: {
        compdefId: string;
        projectRootNode: TreeUtil.ElementNode;
        insertIndex: number;
        defaultValue: string;
    }) => {
        deligate({
            targetRoot: args.projectRootNode,
            isScanChildren: (node) => {
                const stopList: ModelUtil.NodeType[] = [];
                return !stopList.includes((node.data as ModelUtil.WrapElement).type);
            },
            callback: (node) => {
                const wrap = node.data as ModelUtil.WrapElement;
                if (wrap.type === 'compuse') {
                    const data = wrap.data as NodeCompuse.Data;
                    // console.log(`${args.compdefId} === ${data.compId}`);
                    if (data.compId === args.compdefId) {
                        data.props.splice(args.insertIndex, 0, args.defaultValue);
                    }
                }
            }
        });
    }

    /**
     * コンポーネント定義の引数を削除した際に、参照先の引数リストからも削除する
     * @param args 
     */
    export const syncCompdefRemoveProps = (args: {
        compdefId: string;
        projectRootNode: TreeUtil.ElementNode;
        removeIndex: number;
    }) => {
        deligate({
            targetRoot: args.projectRootNode,
            isScanChildren: (node) => {
                const stopList: ModelUtil.NodeType[] = [];
                return !stopList.includes((node.data as ModelUtil.WrapElement).type);
            },
            callback: (node) => {
                const wrap = node.data as ModelUtil.WrapElement;
                if (wrap.type === 'compuse') {
                    const data = wrap.data as NodeCompuse.Data;
                    // console.log(`${args.compdefId} === ${data.compId}, removeIndex: ${args.removeIndex}`);
                    if (data.compId === args.compdefId) {
                        data.props.splice(args.removeIndex, 1);
                    }
                }
            }
        });
    }

    /**
     * コンポーネント定義の引数を並べ替えした際に、参照先の引数リストにも反映する
     * @param args 
     */
    export const syncCompdefOrderProps = (args: {
        compdefId: string;
        projectRootNode: TreeUtil.ElementNode;
        targetIndex: number;
        offset: number;
    }) => {
        deligate({
            targetRoot: args.projectRootNode,
            isScanChildren: (node) => {
                const stopList: ModelUtil.NodeType[] = [];
                return !stopList.includes((node.data as ModelUtil.WrapElement).type);
            },
            callback: (node) => {
                const wrap = node.data as ModelUtil.WrapElement;
                if (wrap.type === 'compuse') {
                    const data = wrap.data as NodeCompuse.Data;
                    // console.log(`${args.compdefId} === ${data.compId}, removeIndex: ${args.removeIndex}`);
                    if (data.compId === args.compdefId) {
                        DataUtil.swapOrder(data.props, args.targetIndex, args.offset);
                    }
                }
            }
        });
    }
};

export default RefactoringUtil;