import styled, { css } from "styled-components";
import TreeUtil from "../../../../../common/component/tree/treeUtil";
import ModelUtil from "../util/modelUtil";
import FormUtil from "../../../../../common/component/form/formUtiil";
import { useEffect, useState } from "react";
import assert from "assert";

namespace NodeJsonTab {

    type LocalState = {
        isModify: boolean;
        src: string;
    }

    export const Component = (props: {
        node: TreeUtil.ElementNode | null;
        invalidate: () => void;
    }) => {

        const [ls, setLs] = useState<LocalState>({
            isModify: false,
            src: ''
        });
        const invalidate = () => setLs({ ...ls });


        const getElementJson = () => {
            if (props.node == null) return '';
            const el = props.node.data as ModelUtil.WrapElement;
            return JSON.stringify(el, null, 2);
        }

        useEffect(() => {
            ls.src = getElementJson();
            invalidate();
        }, [props.node?.data]);

        return (<>
            <_JsonViewer isModify={ls.isModify} readOnly={!ls.isModify}
                value={ls.src}
                onChange={(e) => {
                    ls.src = e.target.value;
                    invalidate();
                }}
            />
            <FormUtil.ButtonRecord buttons={[
                {
                    label: 'Cancel', width: 140, isEnable: ls.isModify, callback: () => {
                        ls.isModify = false;
                        ls.src = getElementJson();
                        invalidate();
                    }
                },
                {
                    label: !ls.isModify ? 'Modify' : 'Apply', width: 140, callback: () => {
                        if (!ls.isModify) {
                            ls.isModify = true;
                        } else {
                            ls.isModify = false;
                            assert(props.node != null, 'props.nodeがnullであってはならない。');
                            props.node.data = JSON.parse(ls.src);

                        }
                        props.invalidate();
                    }
                },
            ]} />
        </>);
    }
};

export default NodeJsonTab;

const _JsonViewer = styled.textarea<{
    isModify: boolean;
}>`
    display: inline-block;
    position: relative;
    width: calc(100% - 8px);
    height: calc(100% - ${34 + 8}px);
    margin: 4px 0 0 4px;
    /* width: 100%;
    height: 100%; */
    resize: none;
    font-size: 18px;
    /* font-weight: 600; */
    color: #1b46c6;
    background-color: #a2b6b4;  
    ${props => !props.isModify ? '' : css`
        color: #000000;
        background-color: #d2dcdb;  
    `}
    box-sizing: border-box;
    /* border: solid 2px #865959; */
`;