import pako from 'pako';
// import { createClient } from "@supabase/supabase-js";
import { Base64 } from "js-base64";
import { sha256 } from 'js-sha256';
import Hashids from 'hashids';
import { Store } from "../system/redux/store/store";
import LocalStrageUtil from "../system/util/localStrageUtil";

// const SUPABASE_URL = "https://rxjdrbdnfvfdbjvaljsp.supabase.co";
// const SUPABASE_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJ4amRyYmRuZnZmZGJqdmFsanNwIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTQxNTQwMTgsImV4cCI6MTk2OTczMDAxOH0.Iq-ilL7ayVatIsG--Lq4WpdL_o1h-6RpR4KjWBdr24M';

// const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

namespace ServerUtil {

    // export const URL_PREFIX_TESTAPP = 'test-app';
    export const URL_ROUTER_PROJECT = 'project';
    export const URL_ROUTER_LAUNCH = 'launch';

    // export const DB_SERVER_DOMAIN = 'http://localhost:5022';
    // export const DB_SERVER_DOMAIN = 'https://crud-server001.glitch.me';

    // export const APP_SERVER_DOMAIN = 'http://localhost:4335';
    export const APP_SERVER_DOMAIN = 'https://frontdriven.tech';

    export const VERSION_NAME = 'v1.2.3';

    const DATABASE_NAME = 'fd0';
    const USER_HASH_SALT = 'fd-user';
    const DEV_HASH_SALT = 'fd-dev';

    const HASH_LEN = 8;

    const getHashBuilder = (salt: string, hashLen?: number) => {
        return new Hashids(salt, hashLen ?? HASH_LEN);
    }

    export const getHashedFromSeq = (salt: string, seq: number, hashLen?: number) => {
        const hashids = getHashBuilder(salt, hashLen);
        const hash = hashids.encode(seq);
        const hashedKey = Base64.encode(hash, true);
        return hashedKey;
    }

    export const getHashedFromUserSeq = (seq: number) => {
        const region = LocalStrageUtil.getActiveRegionDef();
        return getHashedFromSeq(region.salt + USER_HASH_SALT, seq);
    }
    export const getHashedKeyFromDevSeq = (seq: number) => {
        const region = LocalStrageUtil.getActiveRegionDef();
        return getHashedFromSeq(region.salt + DEV_HASH_SALT, seq);
    }

    // const getAppSalt = (devSeq: number, appSeq: number) => {
    //     return `app${devSeq}.${appSeq}`;
    // }
    // export const getHashedKeyFromDevApp = (devSeq: number, appSeq: number) => {
    //     const region = LocalStrageUtil.getActiveRegionDef();
    //     const appSalt = String(devSeq);
    //     const devHash = getHashedFromSeq(region.salt + DEV_HASH_SALT, devSeq, HASH_LEN - 2);
    //     const appHash = getHashedFromSeq(region.salt + DEV_HASH_SALT + appSalt, appSeq, 2);
    //     return `${devHash}.${appHash}`;
    // }
    export const getHashedKeyFromDevLaunch = (devSeq: number, launcherNo: number) => {
        const region = LocalStrageUtil.getActiveRegionDef();
        const launchSalt = String(devSeq);
        const devHash = getHashedFromSeq(region.salt + DEV_HASH_SALT, devSeq, HASH_LEN - 2);
        const launchHash = getHashedFromSeq(region.salt + DEV_HASH_SALT + launchSalt, launcherNo, 2);
        return `${devHash}.${launchHash}`;
    }

    export const getDecryptionedSeq = (salt: string, hash: string, hashLen?: number) => {
        const hashids = getHashBuilder(salt, hashLen);
        let decryptioned = -1;
        try {
            const value = hashids.decode(Base64.decode(hash)).toString();
            // console.log(`${hash}: ${value}`);
            if (value !== '') {
                decryptioned = Number(value);
            }
        } catch (e) {
            console.error('decode error');
        }
        return decryptioned;
    }
    export const getDecryptionedDevmdHash = (hash: string) => {
        const region = LocalStrageUtil.getActiveRegionDef();
        return getDecryptionedSeq(region.salt + DEV_HASH_SALT, hash);
    }
    export const getDecryptionedDevLaunchHash = (hash: string) => {
        const region = LocalStrageUtil.getActiveRegionDef();
        const list = hash.split('.');
        const devHash = list[0];
        const appHash = list[1];
        // console.log(hash);
        // console.log(devHash);
        // console.log(appHash);
        const devSeq = getDecryptionedSeq(region.salt + DEV_HASH_SALT, devHash, HASH_LEN - 2);
        // console.log(devSeq);
        const launchSalt = String(devSeq);
        const launcherNo = getDecryptionedSeq(region.salt + DEV_HASH_SALT + launchSalt, appHash, 2);
        // console.log(launcherNo);
        return [devSeq, launcherNo];
    }

    // export const getPreviewURL = (scoreSeq: number) => {
    //     const hashedKey = getHashedFromProjectSeq(scoreSeq);
    //     const url = `${APP_SERVER_DOMAIN}/#/preview?v=${hashedKey}`;
    //     return url;
    // }

    /**
     * クエリリクエストを生成する
     * @param sql 
     * @returns 
     */
    const createQueryRequestInit = (sql: string): RequestInit => {
        return {
            mode: 'cors',
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify({ sql, databaseName: DATABASE_NAME })
            body: JSON.stringify({ sql, name: DATABASE_NAME + '.db' })
        }
    }

    /**
     * select/updateを指定してSQLを実行する。<br>
     * selectの場合、結果をjsonで返す。
     * @param queryType
     * @param sql 
     * @returns 
     */
    export const sendQueryRequestToAPI = (queryType: 'select' | 'update', sql: string): Promise<Response> => {
        const region = LocalStrageUtil.getActiveRegionDef();
        return fetch(region.domain + '/' + queryType,
            createQueryRequestInit(sql)
        );
    }

    export const findUserInfoList = async () => {
        const response = await sendQueryRequestToAPI('select', `SELECT id, email FROM user_tbl`);
        const results = await response.json();
        return results as { id: string, email: string }[];
    };

    export const findUserIdList = async () => {
        const results = await findUserInfoList();
        return (results as { id: string }[]).map(res => res.id);
    };

    export const findUserInfoFromEmail = async (email: string) => {
        const response = await sendQueryRequestToAPI('select', `SELECT seq, id FROM user_tbl WHERE email = '${email}'`);
        const results = await response.json();
        return results as { seq: number, id: string }[];
    };

    export const findUserInfoFromSeq = async (seq: number) => {
        const response = await sendQueryRequestToAPI('select', `SELECT seq, id, password, email FROM user_tbl WHERE seq = ${seq}`);
        const results = await response.json();
        return results as { seq: number, id: string, password: string, email: string }[];
    };

    export const findUserInfoLogin = async (id: string, password: string) => {
        const response = await sendQueryRequestToAPI('select', `SELECT seq, id, password, email FROM user_tbl WHERE id = '${id}' and password = '${password}'`);
        const results = await response.json();
        return results as { seq: number, id: string, password: string, email: string }[];
    };

    export const findUserInfoSeq = async (seq: number) => {
        const response = await sendQueryRequestToAPI('select', `SELECT seq, id, password, email FROM user_tbl WHERE seq = ${seq}`);
        const results = await response.json();
        return results as { seq: number, id: string, password: string, email: string }[];
    };

    export const getNextUserSeq = async () => {
        const response = await sendQueryRequestToAPI('select', `SELECT seq FROM sqlite_sequence WHERE name = 'user_tbl'`);
        const results = await response.json();
        const nextSeq = (results[0]['seq'] as number) + 1;
        ServerUtil.sendQueryRequestToAPI('update', `update sqlite_sequence set seq = seq + 1 where name='user_tbl'`);

        return nextSeq;
    };

    /** ユーザが管理している開発モジュールリストを取得 */
    export const findUserDevmdList = async (userSeq: number) => {
        const response = await ServerUtil.sendQueryRequestToAPI(
            'select',
            `SELECT seq, owner, name, password, outline, source FROM devmd WHERE owner = ${userSeq} order by seq`
        );
        const results = await response.json();
        return results as any[];
    };

    // export const loadFile = (store: Store, setStore: React.Dispatch<React.SetStateAction<Store>>) => {
    //     (async () => {
    //         const [fileHandle] = await window.showOpenFilePicker();
    //         const file = await fileHandle.getFile();
    //         const fileContents = await file.text();
    //         store.fileHandle = fileHandle;
    //         const text = ServerUtil.unZip(fileContents);
    //         const data = JSON.parse(text);
    //         // シナリオであるかどうかの判定
    //         if (!data.hasOwnProperty('scenarioData')) {
    //             store.libraryData = data;
    //         } else {
    //             const scenarioProps = data as StoryUtil.ScenarioFileProps;
    //             store.libraryData = scenarioProps.libraryData;
    //             store.storyData = scenarioProps.scenarioData;
    //         }
    //         store.system.dialog = <ConfirmDialog.Component message="Successfully file loaded!" />;
    //         setStore({ ...store });
    //     })();
    // }

    // export const saveLibraryFile = (store: Store, setStore: React.Dispatch<React.SetStateAction<Store>>) => {
    //     const rootData = store.libraryData as LogicUtil.WrapElement;
    //     const plainData = JSON.stringify(rootData);
    //     saveFile(store, setStore, plainData, 'plgc');
    // }

    // export const saveScenarioFile = (store: Store, setStore: React.Dispatch<React.SetStateAction<Store>>) => {
    //     const data: StoryUtil.ScenarioFileProps = {
    //         libraryData: store.libraryData as LogicUtil.WrapElement,
    //         scenarioData: store.storyData as StoryUtil.StoryData
    //     }
    //     const plainData = JSON.stringify(data);
    //     saveFile(store, setStore, plainData, 'pstr');
    // }

    // export const saveFile = (store: Store, setStore: React.Dispatch<React.SetStateAction<Store>>, plainData: string, extension: string) => {
    //     let fileHandle = store.fileHandle;

    //     const options = {
    //         types: [
    //             {
    //                 accept: {
    //                     'text/plain': [`.${extension}`],
    //                 },
    //             },
    //         ],
    //     };

    //     if (fileHandle != null) {
    //         (async () => {
    //             //ファイルへ書き込むための FileSystemWritableFileStream を作成
    //             const writable = await fileHandle.createWritable();
    //             //テキストデータの書き込み
    //             const text = ServerUtil.gZip(plainData);
    //             await writable.write(text);
    //             //ファイルを閉じる
    //             await writable.close();
    //             // setFileHandle(fileHandle);
    //             // const file = await fileHandle.getFile();
    //             store.system.dialog = <ConfirmDialog.Component message="successfully overwritten!" />;
    //             setStore({ ...store });
    //         })();
    //     } else {
    //         window.showSaveFilePicker(options).then((handle) => {
    //             (async () => {
    //                 //ファイルへ書き込むための FileSystemWritableFileStream を作成
    //                 const writable = await handle.createWritable();
    //                 //テキストデータの書き込み
    //                 const text = ServerUtil.gZip(plainData);
    //                 await writable.write(text);
    //                 //ファイルを閉じる
    //                 await writable.close();
    //                 store.fileHandle = handle;
    //                 // setFileHandle(handle);
    //                 store.system.dialog = <ConfirmDialog.Component message="Successfully written to file!" />;
    //                 setStore({ ...store });
    //             })();
    //         }).catch(() => {
    //             console.log('キャンセルされました');
    //         });
    //     }
    // }

    export const getSHA256Hash = (original: string) => {
        return sha256(original);
    }

    export const utf8_to_b64 = (str: string) => {
        // return window.btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt(p1, 16))));
        return window.btoa(unescape(encodeURIComponent(str)));
    }
    export const b64_to_utf8 = (str: string) => {
        return decodeURIComponent(escape(window.atob(str)));
    }
    // export const b64_to_utf8 = (str: string) => {
    //     return decodeURIComponent(
    //         window.atob(str)
    //             .split('')
    //             .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
    //             .join('')
    //     );
    // }

    // /**
    //  * 文字列を圧縮する
    //  * @param val 圧縮前の文字列
    //  * @returns 圧縮後の文字列
    //  */
    // export const gZip = (val: string) => {
    //     // エンコード
    //     const content = encodeURIComponent(val);
    //     // 圧縮
    //     const result = zlib.gzipSync(content);
    //     // Buffer => base64変換
    //     const value = result.toString('base64');
    //     return value;
    // }

    // /**
    //  * 圧縮された文字列を複号する
    //  * @param val 圧縮された文字列
    //  * @returns 複号後の文字列
    //  */
    // export const unZip = (val: string) => {
    //     // base64 => Bufferに変換
    //     const buffer = Buffer.from(val, 'base64')
    //     // 復号化
    //     const result = zlib.unzipSync(buffer)
    //     // デコード
    //     const str = decodeURIComponent(result.toString())
    //     return str;
    // }

    /**
     * 文字列を圧縮する
     * @param baseStr 圧縮前の文字列
     * @returns 圧縮後の文字列
     */
    export const gZip = (baseStr: string) => {
        const encoder = new TextEncoder(); // 文字列をUint8Arrayにエンコードするために使用
        const textUint8Array = encoder.encode(baseStr);

        // gzip圧縮
        const compressed = pako.gzip(textUint8Array);// Uint8Array を Base64 文字列に変換
        const compressedBase64 = uint8ArrayToBase64(compressed);
        return compressedBase64;
    }
    // Uint8Array を Base64 にエンコードするヘルパー関数
    const uint8ArrayToBase64 = (buffer: Uint8Array) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    /**
     * 圧縮された文字列を複号する
     * @param baseStr 圧縮された文字列
     * @returns 複号後の文字列
     */
    export const unZip = (baseStr: string) => {
        // Base64 文字列を Uint8Array に戻す
        const compressedFromBase64 = Uint8Array.from(atob(baseStr), c => c.charCodeAt(0));
        return pako.inflate(compressedFromBase64, { to: 'string' });
    }

    /**
     * Date型の日時を文字列に変換する
     * @param date Date型の日時
     * @returns 文字列の日時
     */
    export const getStringFromDate = (date: Date) => {

        const year_str = date.getFullYear().toString();
        //月だけ+1すること
        const month_str = (1 + date.getMonth()).toString();
        const day_str = date.getDate().toString();
        const hour_str = date.getHours().toString();
        const minute_str = date.getMinutes().toString();
        const second_str = date.getSeconds().toString();

        let format_str = 'YYYY-MM-DD hh:mm:ss';
        format_str = format_str.replace(/YYYY/g, year_str);
        format_str = format_str.replace(/MM/g, month_str);
        format_str = format_str.replace(/DD/g, day_str);
        format_str = format_str.replace(/hh/g, hour_str);
        format_str = format_str.replace(/mm/g, minute_str);
        format_str = format_str.replace(/ss/g, second_str);

        return format_str;
    };

    // export const getDirectUrl = (seq: number) => {
    //     return `${APP_SERVER_DOMAIN}/#/story?v=${getHashedFromProjectSeq(seq)}`;
    // }



    // export type SendMailParam = {
    //     from: string,
    //     to: string,
    //     subject: string,
    //     text: string
    // }

    // const createSendMailRequestInit = (json: SendMailParam): RequestInit => {
    //     return {
    //         mode: 'cors',
    //         method: 'POST',
    //         headers: {
    //             Accept: 'application/json',
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(json)
    //     }
    // }

    // export const sendQueryRequestToMailAPI = (json: SendMailParam): Promise<Response> => {
    //     return fetch(DB_SERVER_DOMAIN + '/mail',
    //         createSendMailRequestInit(json)
    //     );
    // }
}

export default ServerUtil;