
namespace AffectMonitor {

    export const Monitor = () => {

        return (
            <></>
        );
    }
};

export default AffectMonitor;