import assert from "assert";
import { Store } from "../store/store";
import ModelElementUtil from "../../contents/develop/function/util/modelElementUtil";
import ModelUtil from "../../contents/develop/function/util/modelUtil";
import AbstractModelEditor from "../../contents/develop/function/editor/abstractModelEditor";
import ModelEditDialog from "../../contents/develop/function/editor/modelEditDialog";
import ScopeManagerBak from "../../contents/develop/function/option/scopeManagerBak";
import TreeUtil from "../../../common/component/tree/treeUtil";
import NodeCompuse from "../../contents/develop/function/editor/ui/nodeCompuse";

export class Dispatcher {

    private store: Store;
    private setStore: (store: Store) => void;

    constructor(store: Store, setStore: (store: Store) => void) {
        this.store = store;
        this.setStore = setStore;
    }

    public updateStore = () => {
        this.setStore({ ...this.store });
    }

    public system = {
        // setUserEnv: (userEnv: UserEnv) => {
        // },

        isAcceptClipboardPaste: (node: TreeUtil.ElementNode) => {
            const wrap: ModelUtil.WrapElement = node.data;
            const clipboad = this.store.system.clipboad;
            let isEnable = false;
            if (clipboad != null) {

                const directives: ModelUtil.NodeType[] = ['iterate', 'case', 'accept'];
                const comps: ModelUtil.NodeType[] = ['compuse', 'tag', 'text'];
                const decrares: ModelUtil.NodeType[] = ['variable', 'func', 'style', 'compdef'];
                const procs: ModelUtil.NodeType[] = ['focus', 'execute', 'native', 'log', 'fetch', 'invalidate', 'block'];

                const applyAccept = (...acceptTypes: ModelUtil.NodeType[]) => {
                    isEnable = acceptTypes.includes(clipboad.source.type);
                }
                const applyElements = () => {
                    applyAccept(...comps, ...directives);
                }
                switch (wrap.type) {
                    case 'tag':
                    // case 'tagdiv': {
                    //     const hasRetention = (wrap.data as ModelUtil.NodeDiv).elements.find(e => e.type === 'retention') != undefined;
                    //     if (!hasRetention) applyElements();
                    // } break;
                    case 'iterate':
                    case 'bool':
                    case 'switch':
                    case 'when':
                    case 'compuse':
                    case 'accept': {
                        if (wrap.type === 'compuse') {
                            const data = wrap.data as NodeCompuse.Data;
                            if (data.elements == undefined) break;
                        }
                        applyAccept(...decrares, ...comps, ...directives, ...procs);
                    } break
                    case 'elements': applyElements(); break;
                    case 'bool': {
                        if (ModelElementUtil.isSubordinate(node, 'retention', 'proc')) {
                            console.log('when: retention');
                            applyAccept(...decrares, ...directives, ...procs);
                        } else {
                            console.log('when: false');
                            applyElements();
                        }
                    } break;
                    case 'then':
                    case 'catch':
                    case 'retention':
                    case 'proc': applyAccept(...decrares, ...directives, ...procs, 'return'); break;

                    case 'block': {
                        applyAccept(...decrares, ...comps, ...directives, ...procs, 'effect');
                    } break;
                    case 'apps': applyAccept('app'); break;
                    case 'structs': applyAccept('struct'); break;
                    case 'funcs': applyAccept('func'); break;
                    case 'struct': applyAccept('field'); break;
                    case 'comps': applyAccept('compdef'); break;
                    case 'states': applyAccept('state'); break;
                    case 'styles': applyAccept('style'); break;
                    case 'props': applyAccept('prpfld', 'prpclbk'); break;
                }
            }
            return isEnable;
        },
    }

    public struct = {

        openEditDialog: (arg: {
            isCreate: boolean,
            editor: AbstractModelEditor,
            manageItems: ModelUtil.ManageItems,
            extendAction?: () => void;
        }) => {
            this.store.system.dialog = <ModelEditDialog.Conponent isCreate={arg.isCreate} editor={arg.editor} />;
            // // 新規登録時は仮ノードを追加する
            // if (arg.isCreate) {
            //     const newNode = ModelElementUtil.addVirtualNode(arg.manageItems, arg.editor.getNodeType());
            //     // ダイアログ生成時はスコープを更新する
            //     this.store.system.scope = ScopeManager.getScopeProps(newNode);
            // }
            if (arg.extendAction != undefined) {
                arg.extendAction();
            }
            this.updateStore();
        }
    }
}