import { useContext, useEffect, useState } from "react";

import assert from "assert";
import { GlobalContext } from "../../../../entry/systemEntry";
import ModelUtil from "../../util/modelUtil";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ModelEditDialog from "../modelEditDialog";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import AbstractModelEditor from "../abstractModelEditor";
import ModelElementUtil from "../../util/modelElementUtil";
import NodeEntry from "../ui/nodeEntry";

namespace NodeBlock {

    type LocalState = {
        comment: FormUtil.CheckableValue;
    }
    export type Data = {
        comment: string;
        items: ModelUtil.WrapElement[];
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, setStore, dispatcher } = useContext(GlobalContext);

        const [ls, setLocalState] = useState<LocalState>({
            comment: { value: '', errors: [] }
        });
        const invalidate = () => setLocalState({ ...ls });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });


        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                ls.comment.value = data.comment;
                invalidate();
            }
        }, []);

        const targetFroms = [ls.comment];
        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            props.temp.inputOK = true;

            let items: ModelUtil.WrapElement[] = [];
            if (props.temp.data != null) {
                items = (props.temp.data as Data).items;
            }
            const data: Data = {
                comment: ls.comment.value,
                items
            }
            setTempData(data);
        }, [...targetFroms]);

        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Comment"
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={ls.comment}
                            setCheckable={(checkable) => {
                                ls.comment = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'block';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeBlock;
