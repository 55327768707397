import assert, { throws } from "assert";
import TreeUtil from "../../../../../common/component/tree/treeUtil";
import ModelUtil from "./modelUtil";
import DataUtil from "../../../../../common/dataUtil";
import ScopeManagerBak from "../option/scopeManagerBak";
import VariableChooser from "../editor/proc/variableChooser";
import NodeStyle from "../editor/decrare/nodeStyle";
import NodeCompdef from "../editor/decrare/nodeCompdef";
import NodeStruct from "../editor/nodeStruct";
import NodePrpclbk from "../editor/var/nodePrpclbkEditor";
import StructTreeContext from "../tree/structTreeContext";
import NodeFunction from "../editor/var/func/nodeFunction";
import NodeIterate from "../editor/nodeIterate";
import NodeApp from "../editor/nodeApp";
import ReaderUtil from "../../../gui/readerUtil";
import NodeField from "../editor/var/nodeField";

namespace ModelElementUtil {

    /**
     * Data未確定時の仮想ノード（ツリー上で要素新規追加時）を追加
     * @param manageItems 
     * @param type 
     * @returns 
     */
    export const addVirtualNode = (manageItems: ModelUtil.ManageItems, type: ModelUtil.NodeType): TreeUtil.ElementNode => {
        const itemWrap: ModelUtil.WrapElement = { type, data: null };
        const node = manageItems.focusNode;
        const curElement = node.data as ModelUtil.WrapElement;
        const listName = getListParamName(curElement.type);
        // curElement.data[`${listName}`].push(itemWrap);
        let list: ModelUtil.WrapElement[] | undefined = curElement.data[`${listName}`];
        if(list == undefined) {
            list = [];
            curElement.data[`${listName}`] = list;
        }
        list.push(itemWrap);
        const newNode = TreeUtil.createNode(itemWrap, node);
        node.children.push(newNode);
        node.isOpen = true;
        manageItems.focusNode = newNode;
        manageItems.setFocusNode(newNode);
        manageItems.invalidate();
        return newNode;
    }

    export const getChildrenDataNode = (wrap: ModelUtil.WrapElement): TreeUtil.DataNode[] => {
        const getRec = (listName: string) => {
            const listProp = wrap.data[listName];
            // assert(listProp != undefined, `listProp is undefined [type: ${wrap.type}, listName: ${listName}]`);
            if(listProp != undefined) {
                return (listProp as ModelUtil.WrapElement[]).map(wrap => {
                    const treeNode: TreeUtil.DataNode = {
                        data: wrap,
                        children: getChildrenDataNode(wrap)
                    };
                    return treeNode;
                });
            }
            return [];
        }

        // if (!isIrregularChild(wrap)) {
        // }
        const listName = ModelElementUtil.getListParamName(wrap.type);
        // 子要素がある場合、再帰で処理を行う
        if (listName != null) return getRec(listName);
        return [];
    };

    // const isIrregularChild = (wrap: WrapElement) => {
    //     switch (wrap.type) {
    //         case 'compuse': {
    //             const data = wrap.data as NodeCompuse.Data;
    //             return data.elements == undefined;
    //         }
    //     }
    //     return false;
    // }

    export const getInnerNode = (

        node: TreeUtil.ElementNode, ...typeList: ModelUtil.NodeType[]
    ): TreeUtil.ElementNode | null => {

        let cur: TreeUtil.ElementNode | null = node;
        typeList.some(type => {
            if (cur == null) return 1;
            const target = cur.children.find(child => (child.data as ModelUtil.WrapElement).type === type);
            if (target == undefined) cur = null;
            else cur = target;
        });
        return cur;
    }

    export const getInnerNodeFixed = (
        node: TreeUtil.ElementNode, ...typeList: ModelUtil.NodeType[]
    ): TreeUtil.ElementNode => {
        const innerNode = getInnerNode(node, ...typeList);
        if (innerNode == null) throw new Error('[innerNode] is null.');
        return innerNode as TreeUtil.ElementNode;
    }

    export const getInnerWrap = (
        wrap: ModelUtil.WrapElement, ...typeList: ModelUtil.NodeType[]
    ): ModelUtil.WrapElement | null => {

        let cur: ModelUtil.WrapElement | null = wrap;
        typeList.some(type => {
            if (cur == null) return 1;
            const listName = getListParamName(cur.type);
            if (listName == null) cur = null;
            else {
                const list = cur.data[`${listName}`] as ModelUtil.WrapElement[];
                assert(list != undefined, `listがundefinedであってはならない。listName:[${listName}] data:[${JSON.stringify(cur.data)}]`);
                const target = list.find(child => child.type === type);
                if (target == undefined) cur = null;
                else cur = target;
            }
        });
        return cur;
    }

    export const getInnerWrapFixed = (
        criteriaWrap: ModelUtil.WrapElement, ...typeList: ModelUtil.NodeType[]
    ): ModelUtil.WrapElement => {
        const innerWrap = getInnerWrap(criteriaWrap, ...typeList);
        assert(innerWrap != null, `innerWrap is null.[criteria: ${criteriaWrap.type}, types: ${typeList.join('-')}]`);
        return innerWrap as ModelUtil.WrapElement;
    }

    export const addChild = (wrapElement: ModelUtil.WrapElement, node: TreeUtil.ElementNode) => {
        return insertChild(wrapElement, node, node.children.length);
    }

    export const insertChild = (wrapElement: ModelUtil.WrapElement, node: TreeUtil.ElementNode, listPos: number) => {
        const wrap = node.data as ModelUtil.WrapElement;
        const listName = getListParamName(wrap.type);
        wrap.data[`${listName}`].splice(listPos, 0, wrapElement);
        const newNode = TreeUtil.createNode(wrapElement, node);
        node.children.splice(listPos, 0, newNode);
        return newNode;
    }

    /**
     * 自身を削除して親ノードにフォーカスを移す
     * @param manageItems 
     * @returns 
     */
    export const removeSelf = (manageItems: ModelUtil.ManageItems) => {
        const node = manageItems.focusNode;
        let index = -1;
        const parent = node.parent;
        assert(parent != null, 'parent is null.');
        parent.children.forEach((n, i) => {
            if (n == node) index = i;
        });
        parent.children.splice(index, 1);
        const parentWrap = node.parent?.data as ModelUtil.WrapElement;
        const listName = getListParamName(parentWrap.type);
        assert(listName != null, 'listName is null.');
        const list = parentWrap.data[listName] as ModelUtil.WrapElement[];
        assert(list != undefined, 'list is undefined.');
        list.splice(index, 1);
        manageItems.setFocusNode(node.parent as TreeUtil.ElementNode);
        manageItems.invalidate();
    }

    export const removeChild = (node: TreeUtil.ElementNode, listPos: number, size: number) => {
        const wrap = node.data as ModelUtil.WrapElement;
        const listName = getListParamName(wrap.type);
        wrap.data[`${listName}`].splice(listPos, size);
        node.children.splice(listPos, size);
    }

    export const removeChildAll = (node: TreeUtil.ElementNode) => {
        const wrap = node.data as ModelUtil.WrapElement;
        const listName = getListParamName(wrap.type);
        wrap.data[`${listName}`].length = 0;
        node.children.length = 0;
    }

    export const isHead = (node: TreeUtil.ElementNode) => {
        if (node.parent == null) return false;
        const ownerChildren = node.parent.children;
        return ownerChildren[0] == node;
    }

    export const isTail = (node: TreeUtil.ElementNode) => {
        if (node.parent == null) return false;
        const ownerChildren = node.parent.children;
        return ownerChildren[ownerChildren.length - 1] == node;
    }

    export const getCurrentIndex = (node: TreeUtil.ElementNode) => {
        const parentWrap = node.parent?.data as ModelUtil.WrapElement;
        const listName = getListParamName(parentWrap.type);
        if (listName == null || parentWrap.data == null) return -1;
        const list = parentWrap.data[listName] as ModelUtil.WrapElement[];
        const curIndex = list.findIndex(wrap => wrap == node.data);
        return curIndex;
    }

    export const isTopNode = (node: TreeUtil.ElementNode) => {
        return getCurrentIndex(node) === 0;
    }

    export const isBottomNode = (node: TreeUtil.ElementNode) => {
        assert(node.parent != null, 'node.parent is null.');
        return getCurrentIndex(node) === node.parent?.children.length - 1;
    }

    export const swapOrder = (node: TreeUtil.ElementNode, offset: number) => {
        if (node.parent == null) return;

        // #region 影響箇所の同期
        const wrap = node.data as ModelUtil.WrapElement;
        const editor = StructTreeContext.getEditorFromNodeType(wrap.type);
        if (editor != undefined) {
            editor.orderExtendAction(node, offset);
        }
        // #endregion

        const ownerChildren = node.parent.children;
        const parentWrap = node.parent.data as ModelUtil.WrapElement;
        const listName = getListParamName(parentWrap.type);
        const ownDataChildren = parentWrap.data[`${listName}`] as ModelUtil.WrapElement[];

        let curNo = getCurrentIndex(node);
        if (curNo === -1) return;

        // ツリーノードの入れ替え
        // const swapNode = ownerChildren[curNo];
        // ownerChildren[curNo] = ownerChildren[curNo + offset];
        // ownerChildren[curNo + offset] = swapNode;
        DataUtil.swapOrder(ownerChildren, curNo, offset);
        // データ要素の入れ替え
        // const swapData = ownDataChildren[curNo];
        // ownDataChildren[curNo] = ownDataChildren[curNo + offset];
        // ownDataChildren[curNo + offset] = swapData;
        DataUtil.swapOrder(ownDataChildren, curNo, offset);
    }

    export type ProjectInitialOption = {
        // isOnline: boolean;
        commonItems?: ModelUtil.WrapElement[];
    }
    export const getInitialProject = (option: ProjectInitialOption): ModelUtil.WrapElement => {
        // const reglationList: ModelUtil.WrapElement[] = [];
        // if (option.isOnline) {
        //     reglationList.push({
        //         type: 'users',
        //         data: {
        //             items: []
        //         } as ModelUtil.NodeUsers
        //     });
        //     reglationList.push({
        //         type: 'groups',
        //         data: {
        //             items: []
        //         } as ModelUtil.NodeGroups
        //     });
        // }

        const getCommonItemList = () => {
            if (option.commonItems != undefined) return option.commonItems;
            else {
                // const commonItemList: ModelUtil.WrapElement[] = [];
                // commonItemList.push({
                //     type: 'styles',
                //     data: {
                //         items: []
                //     } as ModelUtil.NodeGenericItems
                // });
                // commonItemList.push({
                //     type: 'structs',
                //     data: {
                //         items: []
                //     } as ModelUtil.NodeModels
                // });
                // commonItemList.push({
                //     type: 'comps',
                //     data: {
                //         comps: []
                //     } as ModelUtil.NodeComps
                // });
                return [
                    {
                        type: 'declares',
                        data: {
                            mngs: getDeclareWraps()
                        } as ModelUtil.NodeGenericMngs
                    }
                ];
            }
        }

        return {
            type: 'project',
            data: {
                mngs: [
                    {
                        type: 'release',
                        data: {
                            mngs: [
                                {
                                    type: 'launchers',
                                    data: {
                                        items: []
                                    } as ModelUtil.NodeGenericItems
                                },
                                {
                                    type: 'permissions',
                                    data: {
                                        items: []
                                    } as ModelUtil.NodeGenericItems
                                }
                            ]
                        } as ModelUtil.NodeGenericMngs
                    },
                    // {
                    //     type: 'regulation',
                    //     data: {
                    //         mngs: reglationList
                    //     } as ModelUtil.NodeRegulation
                    // } as ModelUtil.WrapElement,
                    {
                        type: 'apps',
                        data: {
                            apps: []
                        } as ModelUtil.NodeApps
                    } as ModelUtil.WrapElement,
                    {
                        type: 'common',
                        data: {
                            mngs: getCommonItemList()
                        } as ModelUtil.NodeGenericMngs
                    } as ModelUtil.WrapElement,
                ]
            } as ModelUtil.NodeMngTop
        }
    }

    export const getDeclareWraps = (): ModelUtil.WrapElement[] => {
        return [
            {
                type: 'styles',
                data: {
                    items: []
                } as ModelUtil.NodeGenericItems
            },
            {
                type: 'structs',
                data: {
                    items: []
                } as ModelUtil.NodeGenericItems
            },
            {
                type: 'funcs',
                data: {
                    items: []
                } as ModelUtil.NodeGenericItems
            },
            // {
            //     type: 'consts',
            //     data: {
            //         items: []
            //     } as ModelUtil.NodeGenericItems
            // },
            {
                type: 'comps',
                data: {
                    comps: []
                } as ModelUtil.NodeComps
            }
        ];
    }
    export const getStoreInitialWrap = (): ModelUtil.WrapElement => {
        return {
            type: 'store',
            data: {
                mngs: [
                    {
                        type: 'states',
                        data: {
                            items: []
                        } as ModelUtil.NodeGenericItems
                    },
                ]
            } as ModelUtil.NodeGenericMngs
        };
    }

    const addElementNodeRec = (curNode: TreeUtil.ElementNode, newElement: ModelUtil.WrapElement) => {
        const wrap = curNode.data as ModelUtil.WrapElement;
        const listName = ModelElementUtil.getListParamName(wrap.type);
        if (listName != null) {
            // (wrap.data[`${listName}`] as ModelUtil.WrapElement[]).push(newElement);
            const newNode = TreeUtil.createNode(newElement, curNode);
            curNode.children.push(newNode);

            const childListName = ModelElementUtil.getListParamName(newElement.type);
            if (childListName != null) {
                // console.log(`type: ${newElement.type}, listName: ${childListName}`);
                const childList = newElement.data[`${childListName}`] as ModelUtil.WrapElement[];
                assert(childList != undefined, `childListがundefinedであってはならない。base:${JSON.stringify(newElement.data)}, name:${childListName}}`);
                childList.forEach(childWrap => {
                    addElementNodeRec(newNode, childWrap);
                    // console.log(childWrap.type);
                })
            }
            return newNode;
        }
        return null;
    }

    export const addElementNodeDeep = (parentNode: TreeUtil.ElementNode, newElement: ModelUtil.WrapElement) => {
        const wrap = parentNode.data as ModelUtil.WrapElement;
        const listName = ModelElementUtil.getListParamName(wrap.type);
        assert(listName != null, `listName is null. [type: ${wrap.type}]`);
        wrap.data[`${listName}`].push(newElement);
        return addElementNodeRec(parentNode, newElement);
    }

    export const getListParamName = (type: ModelUtil.NodeType) => {
        switch (type) {
            case 'release':
            case 'app':
            case 'compdef':
            case 'declares':
            case 'store':
            case 'common':
            case 'promise':
            case 'fetch':
            case 'trigger':
            case 'effect':
            case 'project': return 'mngs';
            case 'prpclbk':
            case 'style':
            case 'args': return 'args';
            case 'apps': return 'apps';
            case 'comps': return 'comps';
            case 'tag':
            case 'custtaguse':
            case 'iterate':
            case 'accept':
            case 'bool':
            case 'switch':
            case 'when':
            case 'common':
            case 'compuse':
            case 'elements': return 'elements';
            case 'func':
            case 'case':
            case 'initial':
            case 'permissions':
            case 'launchers':
            case 'permissions':
            case 'proc':
            case 'retention':
            case 'focus':
            case 'variable':
            case 'state':
            case 'styles':
            case 'structs':
            case 'funcs':
            // case 'consts':
            case 'tags':
            case 'then':
            case 'catch':
            case 'block':
            case 'states': return 'items';
            case 'struct': return 'fields';
            case 'props': return 'props';
        }
        return null;
    }
    // export const getListParamName = (wrap: ModelUtil.WrapElement) => {
    //     switch (wrap.type) {
    //         case 'release':
    //         case 'app':
    //         case 'compdef':
    //         case 'declares':
    //         case 'store':
    //         case 'common':
    //         case 'promise':
    //         case 'fetch':
    //         case 'trigger':
    //         case 'effect':
    //         case 'project': return 'mngs';
    //         case 'prpclbk':
    //         case 'style':
    //         case 'args': return 'args';
    //         case 'apps': return 'apps';
    //         case 'comps': return 'comps';
    //         case 'tag':
    //         case 'custtaguse':
    //         case 'iterate':
    //         case 'accept':
    //         case 'bool':
    //         case 'switch':
    //         case 'when':
    //         // case 'range':
    //         case 'common':
    //         case 'compuse':
    //         case 'elements': {
    //             if (wrap.type === 'compuse') {
    //                 const data = wrap.data as NodeCompuse.Data;
    //                 if (data.elements == undefined) break;
    //             }
    //             return 'elements';
    //         }
    //         // case 'tabs': return 'tabs';
    //         case 'func':
    //         case 'case':
    //         case 'initial':
    //         case 'permissions':
    //         // case 'users':
    //         // case 'groups':
    //         case 'launchers':
    //         case 'permissions':
    //         case 'proc':
    //         case 'retention':
    //         case 'focus':
    //         case 'variable':
    //         case 'state':
    //         case 'styles':
    //         case 'structs':
    //         case 'funcs':
    //         // case 'consts':
    //         case 'tags':
    //         case 'then':
    //         case 'catch':
    //         // case 'linkage':
    //         case 'block':
    //         case 'states': {
    //             if (nodeTypes('variable', 'state').includes(wrap.type)) {
    //                 const data = wrap.data as NodeField.VariableData;
    //                 if (data.items == undefined) break;
    //             }
    //             return 'items';
    //         }
    //         case 'struct': return 'fields';
    //         case 'props': return 'props';
    //     }
    //     return null;
    // }

    export const getKeptListWraps = (data: any, listPropName: string): ModelUtil.WrapElement[] => {
        if (data == null) return [];
        else {
            const list = data[listPropName];
            assert(list != undefined, `listがundefinedであってはならない。[listPropName: ${listPropName}, data: ${JSON.stringify(data)}]`);
            return list;
        }
    }

    export const isSwappableNode = (type: ModelUtil.NodeType) => {
        const acceptTypes: ModelUtil.NodeType[] = [
            'app', 'compdef', 'struct', 'state',
            // decrare
            'variable', 'focus', 'style', 'func', 'block', 'tagdef',
            'effect',
            // directive
            'iterate', 'case', 'accept', 'switch', 'when',
            'field', 'stlarg',
            // 'tag', 'tagdiv', 'tagspan', 'taginput', 'tagimg', 'compuse', 'text',
            'tag', 'compuse', 'text',
            // proc
            'focus', 'native', 'execute', 'fetch', 'invalidate', 'log',
            // compdef
            'prpfld', 'prpclbk'
        ];
        return acceptTypes.includes(type);
    }

    export const getAppLocalDtypes = (appRootwrap: ModelUtil.WrapElement): ReaderUtil.StructObject[] => {
        const modelsData = ModelElementUtil.getInnerWrapFixed(appRootwrap, 'declares', 'structs').data as ModelUtil.NodeGenericItems;
        return modelsData.items.map(wrap => {
            const modelData = wrap.data as NodeStruct.Data;
            return { id: modelData.id, fields: modelData.fields.map(wrap => wrap.data as ModelUtil.NodeModelField) };
        });
    }

    export const getAppStructs = (appRootwrap: ModelUtil.WrapElement): NodeStruct.Data[] => {
        const structWraps = ModelElementUtil.getInnerWrapFixed(appRootwrap, 'declares', 'structs').data as ModelUtil.NodeGenericItems;
        return structWraps.items.map(i => i.data);
    }

    /**
     * 変数・式適用後の数値チェック
     * @param value 対象文字列
     * @param props 
     * @returns チェック結果
     */
    export const checkAppliedFmlNumBak = (value: string, props: {
        /** ステートの配列 */
        states?: ModelUtil.NodeModelField[],
        /** 引数の配列 */
        args?: ModelUtil.NodeModelField[],
        /** キャッシュの配列 */
        caches?: ModelUtil.NodeModelField[],
        /** コンポーネントフィールドの配列 */
        prpflds?: ModelUtil.NodeModelField[],
    }) => {
        // 変数を数値（検証のために仮で1）に置換
        if (props.states != undefined) {
            props.states.forEach(s => {
                const key = `\${s.${s.id}}`;
                // console.log(`key=$v{${v.key}} str=${str}`);
                value = value.replaceAll(key, '1');
            });
        }
        if (props.args != undefined) {
            props.args.forEach(a => {
                const key = `\${a.${a.id}}`;
                // console.log(`key=$v{${v.key}} str=${str}`);
                value = value.replaceAll(key, '1');
            });
        }
        if (props.prpflds != undefined) {
            props.prpflds.forEach(f => {
                const key = `\${pf.${f.id}}`;
                // console.log(`key=$v{${v.key}} str=${str}`);
                value = value.replaceAll(key, '1');
            });
        }
        // 式の適用
        value = DataUtil.applyFormula(value);
        return !isNaN(Number(value));
    }
    export const checkAppliedFmlNum = (value: string, valueKeys: ScopeManagerBak.ValueKeyField[]) => {
        valueKeys.forEach(v => {
            // 変数を数値（検証のために仮で1）に置換
            const key = `\${${v.key}}`;
            value = value.replaceAll(key, '1');
        });
        // 式の適用
        value = DataUtil.applyFormula(value);
        return !isNaN(Number(value));
    }

    export const getChoosedField = (chooser: VariableChooser.Chooser, variables: ModelUtil.Variables): ModelUtil.Field => {
        const states = variables.states;
        const caches = variables.caches;
        const models = variables.structs;

        let rootModel: ModelUtil.NodeModelField | undefined = undefined;
        if (chooser.target === 'state') {
            rootModel = states.find(s => s.id === chooser.rootId);
        } else if (chooser.target === 'variable') {
            rootModel = caches.find(c => c.id === chooser.rootId);
        }
        assert(rootModel != undefined, 'rootModel is undefined.');
        /** 走査用のモデル（クローンを書き換えするためディープコピー） */
        let cur = JSON.parse(JSON.stringify(rootModel)) as ModelUtil.NodeModelField;
        chooser.levelProps.forEach(l => {
            if (cur.array > 0) {
                cur.array--;
            } else {
                if (cur.structId != undefined) {
                    const curModel = models.find(m => m.id === cur.structId);
                    assert(curModel != undefined, 'curModel is undefined.');
                    const nextModel = curModel.fields.find(f => f.id === l);
                    assert(nextModel != undefined, 'nextModel is undefined.');
                    cur = JSON.parse(JSON.stringify(nextModel)) as ModelUtil.NodeModelField;;
                }
            }
        });
        return cur;
    }

    export const getChoosedValue = (rootValue: any, chooser: VariableChooser.Chooser, variables: ModelUtil.Variables): any => {
        const states = variables.states;
        const caches = variables.caches;
        const propFields = variables.propFields;
        const funcargs = variables.funcargs;
        const models = variables.structs;

        const getModel = () => {
            switch (chooser.target) {
                case 'state': return states.find(s => s.id === chooser.rootId);
                case 'variable': return caches.find(c => c.id === chooser.rootId);
                // case 'comparg': return propFields.find(p => p.id === chooser.rootId);
                // case 'funcarg': {
                //     assert(funcargs != undefined, 'funcargs is undefined.');
                //     return funcargs.find(a => a.id === chooser.rootId);
                // }
            }
        }
        const rootModel = getModel();
        assert(rootModel != undefined, 'rootModel is undefined.');
        /** 走査用のモデル（クローンを書き換えするためディープコピー） */
        let cur = JSON.parse(JSON.stringify(rootModel)) as ModelUtil.NodeModelField;
        let value = rootValue;
        chooser.levelProps.forEach(l => {
            // const obj = JSON.parse(value);
            if (cur.array >= 1) {
                value = value[Number(l)];
                cur.array--;
            } else {
                if (cur.structId != undefined) {
                    const curModel = models.find(m => m.id === cur.structId);
                    assert(curModel != undefined, 'curModel is undefined.');
                    const nextModel = curModel.fields.find(f => f.id === l);
                    assert(nextModel != undefined, 'nextModel is undefined.');

                    // value = JSON.stringify(obj[l]);
                    value = value[l];

                    cur = JSON.parse(JSON.stringify(nextModel)) as ModelUtil.NodeModelField;;
                }
            }
        });
        return value;
    }
    export const getChoosedValueBak = (rootValue: string, chooser: VariableChooser.Chooser, variables: ModelUtil.Variables): string => {
        const states = variables.states;
        const caches = variables.caches;
        const models = variables.structs;

        let rootModel: ModelUtil.NodeModelField | undefined = undefined;
        if (chooser.target === 'state') {
            rootModel = states.find(s => s.id === chooser.rootId);
        } else if (chooser.target === 'variable') {
            rootModel = caches.find(c => c.id === chooser.rootId);
        }
        assert(rootModel != undefined, 'rootModel is undefined.');
        /** 走査用のモデル（クローンを書き換えするためディープコピー） */
        let cur = JSON.parse(JSON.stringify(rootModel)) as ModelUtil.NodeModelField;
        let value = rootValue;
        chooser.levelProps.forEach(l => {
            const obj = JSON.parse(value);
            if (cur.array >= 1) {
                value = JSON.stringify(obj[Number(l)]);
                cur.array--;
            } else {
                if (cur.structId != undefined) {
                    const curModel = models.find(m => m.id === cur.structId);
                    assert(curModel != undefined, 'curModel is undefined.');
                    const nextModel = curModel.fields.find(f => f.id === l);
                    assert(nextModel != undefined, 'nextModel is undefined.');

                    value = JSON.stringify(obj[l]);

                    cur = JSON.parse(JSON.stringify(nextModel)) as ModelUtil.NodeModelField;;
                }
            }
        });
        return value;
    }

    export const getModifiedChoosedValue = (rootValue: string, assignValue: string, isObject: boolean, chooser: VariableChooser.Chooser, variables: ModelUtil.Variables): string => {
        const states = variables.states;
        const caches = variables.caches;
        const models = variables.structs;

        let rootModel: ModelUtil.NodeModelField | undefined = undefined;
        if (chooser.target === 'state') {
            rootModel = states.find(s => s.id === chooser.rootId);
        } else if (chooser.target === 'variable') {
            rootModel = caches.find(c => c.id === chooser.rootId);
        }
        assert(rootModel != undefined, 'rootModel is undefined.');
        /** 走査用のモデル（クローンを書き換えするためディープコピー） */
        let cur = JSON.parse(JSON.stringify(rootModel)) as ModelUtil.NodeModelField;
        /** 戻り値用にマスタの参照を保持 */
        let masterRef = [JSON.parse(rootValue)];
        let obj = masterRef[0];
        // console.log(assignValue);
        // console.log(masterRef);

        if (chooser.levelProps.length === 0) {
            masterRef[0] = !isObject ? assignValue : JSON.parse(assignValue);
        } else {
            chooser.levelProps.some((l, i) => {
                // 最後の要素は処理しない
                if (i === chooser.levelProps.length - 1) return 1;
                if (cur.array > 0) {
                    obj = obj[l];
                    cur.array--;
                } else {
                    if (cur.structId != undefined) {
                        const curModel = models.find(m => m.id === cur.structId);
                        assert(curModel != undefined, 'curModel is undefined.');
                        const nextModel = curModel.fields.find(f => f.id === l);
                        assert(nextModel != undefined, 'nextModel is undefined.');

                        obj = obj[l];
                        cur = JSON.parse(JSON.stringify(nextModel)) as ModelUtil.NodeModelField;;
                    }
                }
            });
            const tailLevel = chooser.levelProps[chooser.levelProps.length - 1];
            obj[tailLevel] = !isObject ? assignValue : JSON.parse(assignValue);
        }

        // console.log(masterRef[0]);
        return JSON.stringify(masterRef[0]);
    }

    /**
     * アプリ要素配下かどうか判定する
     * @param start 現在のノード
     * @returns 判定結果
     */
    export const isAppSubordinate = (start: TreeUtil.ElementNode) => {
        let cur = start;
        while (true) {
            const wrap = cur.data as ModelUtil.WrapElement;
            if (wrap.type === 'app') return wrap.data != undefined;
            if (cur.parent == null) return false;
            cur = cur.parent;
        }
    }

    /**
     * コンポーネント要素配下かどうか判定する
     * @param start 現在のノード
     * @returns 判定結果
     */
    export const isComponentSubordinate = (start: TreeUtil.ElementNode) => {
        let cur = start;
        while (true) {
            const wrap = cur.data as ModelUtil.WrapElement;
            if (wrap.type === 'compdef') return wrap.data != undefined;
            if (cur.parent == null) return false;
            cur = cur.parent;
        }
    }

    /**
     * アプリのルートノードを返す
     * @param cur 現在のノード
     * @returns アプリのルートノード
     */
    export const getAppRootWrap = (cur: TreeUtil.ElementNode) => {
        let appRoot = cur;
        while (true) {
            const wrap = appRoot.data as ModelUtil.WrapElement;
            if (wrap.type === 'app') break;
            assert(appRoot.parent != null, 'cur.parent is null.');
            appRoot = appRoot.parent;
        }
        return appRoot.data as ModelUtil.WrapElement;
    }

    export const getPropFieldsFromCurrent = (cur: TreeUtil.ElementNode) => {
        if (!isComponentSubordinate(cur)) return [];
        return getPropItemsFromCurrent(cur, 'prpfld') as ModelUtil.NodeModelField[];
    }

    export const getPrpclbksFromCurrent = (cur: TreeUtil.ElementNode) => {
        return getPropItemsFromCurrent(cur, 'prpclbk') as NodePrpclbk.Data[];
    }

    export const getPropItemsFromCurrent = (cur: TreeUtil.ElementNode, type: ModelUtil.NodeType) => {
        let compdefNode = cur;
        while (true) {
            const wrap = compdefNode.data as ModelUtil.WrapElement;
            if (wrap.type === 'compdef') break;
            assert(compdefNode.parent != null, 'cur.parent is null.');
            compdefNode = compdefNode.parent;
        }
        const compdefWrap = compdefNode.data as ModelUtil.WrapElement;

        const props = getInnerWrapFixed(compdefWrap, 'props').data as ModelUtil.NodeProps;
        return props.props
            .filter(p => p.type === type)
            .map(p => p.data);
    }

    export const getCompsFromApp = (appRootwrap: ModelUtil.WrapElement) => {
        const compsWrap = ModelElementUtil.getInnerWrapFixed(appRootwrap, 'declares', 'comps');
        return (compsWrap.data as ModelUtil.NodeComps).comps.map(compWrap => compWrap.data as NodeCompdef.Data);
    }
    export const getFetchesFromApp = (appRootwrap: ModelUtil.WrapElement) => {
        const stylesWrap = ModelElementUtil.getInnerWrapFixed(appRootwrap, 'declares', 'styles');
        return (stylesWrap.data as ModelUtil.NodeGenericItems).items.map(styleWrap => styleWrap.data as NodeStyle.Data);
    }

    export const getModelsFromApp = (appRootwrap: ModelUtil.WrapElement) => {
        const modelsWrap = ModelElementUtil.getInnerWrapFixed(appRootwrap, 'declares', 'structs');
        return (modelsWrap.data as ModelUtil.NodeGenericItems).items.map(modelWrap => modelWrap.data as NodeStruct.Data);
    }

    /**
     * 自身の階層レイヤーを返す
     * @param cur 
     * @returns 
     */
    export const getTreeDepthLayer = (cur: TreeUtil.ElementNode): ModelUtil.NodeType => {
        const node = getBelongNodeFixed(cur, 'app', 'compdef', 'project');
        const wrap = node.data as ModelUtil.WrapElement;
        let type = wrap.type;
        if (type === 'compdef') {
            assert(node.parent != null, 'node.parentがnullであってはならない。');
            // 内部コンポーネントの場合は、上位改装をチェックする
            if ((node.parent.data.type as ModelUtil.NodeType) !== 'comps') {
                type = getTreeDepthLayer(node.parent);
            }
        }
        return type;
    }

    export const isBelong = (cur: TreeUtil.ElementNode, ...breakTypes: ModelUtil.NodeType[]) => {
        let it = cur;
        while (true) {
            const wrap = it.data as ModelUtil.WrapElement;
            if (breakTypes.includes(wrap.type)) return true;
            if (it.parent == null) break;
            it = it.parent;
        }
        return false;
    }

    export const getReferableVariables = (cur: TreeUtil.ElementNode) => {
        const list: ModelUtil.NodeModelField[] = [];

        const belong = getTreeDepthLayer(cur);
        switch (belong) {
            case 'compdef': {
                // const appRoot = getBelongWrapFixed(cur, ['app']);
                // const prjRoot = getBelongWrapFixed(cur, ['project']);
                // const appDatas = getAppItemsBelongDatas(appRoot, 'styles') as NodeStyle.NodeData[];
                // const commonDatas = getProjectCommonBelongDatas(prjRoot, 'styles') as NodeStyle.NodeData[];
                const retentionDatas = getRetentionDatasFromCurrent(cur, 'variable');

                // const thenDatas = getFetchThensFromCurrent(cur);
                // console.log(thenDatas);

                // list.push(...appDatas);
                // list.push(...commonDatas);
                // list.push(...retentionDatas, ...thenDatas);
                list.push(...retentionDatas);
            } break;
            case 'app': {
                // const prjRoot = getBelongWrapFixed(cur, ['project']);
                // const commonDatas = getProjectCommonBelongDatas(prjRoot, 'styles') as NodeStyle.NodeData[];

                // list.push(...commonDatas);
                // deligatePrevNode(cur, (wrapData) => {
                //     list.push(wrapData.data);
                // });
            } break;
            case 'project': {
                // deligatePrevNode(cur, (wrapData) => {
                //     list.push(wrapData.data);
                // });
            } break;
        }
        return list;
    }
    export const isBelengCommon = (cur: TreeUtil.ElementNode) => {
        return getBelongNode(cur, 'common') != null;
    }
    /**
     * 現在のノードから参照可能なコンポーネントを取得する
     * @param cur 
     * @returns 
     */
    export const getReferableComps = (cur: TreeUtil.ElementNode) => {
        const list: NodeCompdef.Data[] = [];

        // const prjRoot = getBelongWrapFixed(cur, 'project');
        // const commonDatas = getProjectCommonBelongDeclares(prjRoot, 'comps') as NodeCompDef.Data[];
        // list.push(...commonDatas);
        // if (!isBelengCommon(cur)) {
        //     const appRoot = getBelongWrapFixed(cur, 'app');
        //     const appComps = getCompsFromApp(appRoot);
        //     list.push(...appComps);
        // }
        // return list;

        const layer = getTreeDepthLayer(cur);
        switch (layer) {
            case 'compdef': {
                const prjRoot = getBelongWrapFixed(cur, 'project');
                const commonDatas = getProjectCommonBelongDeclares(prjRoot, 'comps') as NodeCompdef.Data[];
                list.push(...commonDatas);
                if (!isBelengCommon(cur)) {
                    const appRoot = getBelongWrapFixed(cur, 'app');
                    const appDatas = getAppDeclareBelongDatas(appRoot, 'comps') as NodeCompdef.Data[];
                    list.push(...appDatas);
                }
                const retentionDatas = getRetentionDatasFromCurrent(cur, 'compdef');
                list.push(...retentionDatas);
            } break;
            case 'app': {
                const prjRoot = getBelongWrapFixed(cur, 'project');
                const commonDatas = getProjectCommonBelongDeclares(prjRoot, 'comps') as NodeCompdef.Data[];

                list.push(...commonDatas);
                deligatePrevNode(cur, (wrapData) => {
                    list.push(wrapData.data);
                });
            } break;
            case 'project': {
                deligatePrevNode(cur, (wrapData) => {
                    list.push(wrapData.data);
                });
            } break;
        }
        return list;
    }
    /**
     * 現在のノードから参照可能なステートを取得する
     * @param cur 
     * @returns 
     */
    export const getReferableStates = (cur: TreeUtil.ElementNode) => {
        const list: ModelUtil.NodeModelField[] = [];

        // アプリケーション配下の場合は、グローバルステートを取得
        if (getBelongNode(cur, 'app') != null) {
            const appRoot = getBelongWrapFixed(cur, 'app');
            const appStates = getGlobalStatesFromApp(appRoot);
            list.push(...appStates);
        }
        // コンポーネント配下の場合は、ローカルステートを取得
        if (getBelongNode(cur, 'compdef') != null) {
            const compRoot = getBelongWrapFixed(cur, 'compdef');
            const compStates = getGlobalStatesFromApp(compRoot);
            list.push(...compStates);
        }
        return list;
    }

    /**
     * 現在のノードから参照可能な関数を取得する
     * @param cur 
     * @returns 
     */
    export const getReferableFunctions = (cur: TreeUtil.ElementNode) => {
        const list: NodeFunction.Data[] = [];

        const layer = getTreeDepthLayer(cur);
        switch (layer) {
            case 'compdef': {
                const prjRoot = getBelongWrapFixed(cur, 'project');
                const commonDatas = getProjectCommonBelongDeclares(prjRoot, 'funcs') as NodeFunction.Data[];
                list.push(...commonDatas);
                if (!isBelengCommon(cur)) {
                    const appRoot = getBelongWrapFixed(cur, 'app');
                    const appDatas = getAppDeclareBelongDatas(appRoot, 'funcs') as NodeFunction.Data[];
                    list.push(...appDatas);
                }
                const retentionDatas = getRetentionDatasFromCurrent(cur, 'func');
                list.push(...retentionDatas);
            } break;
            case 'app': {
                const prjRoot = getBelongWrapFixed(cur, 'project');
                const commonDatas = getProjectCommonBelongDeclares(prjRoot, 'funcs') as NodeFunction.Data[];

                list.push(...commonDatas);
                deligatePrevNode(cur, (wrapData) => {
                    if (wrapData.type === 'func') {
                        list.push(wrapData.data);
                    }
                });
            } break;
            case 'project': {
                deligatePrevNode(cur, (wrapData) => {
                    if (wrapData.type === 'func') {
                        list.push(wrapData.data);
                    }
                });
            } break;
        }
        return list;
    }
    /**
     * 現在のノードから参照可能なスタイルを取得する
     * @param cur 
     * @returns 
     */
    export const getReferableStyles = (cur: TreeUtil.ElementNode) => {
        const list: NodeStyle.Data[] = [];

        const layer = getTreeDepthLayer(cur);
        switch (layer) {
            case 'compdef': {
                const prjRoot = getBelongWrapFixed(cur, 'project');
                const commonDatas = getProjectCommonBelongDeclares(prjRoot, 'styles') as NodeStyle.Data[];
                list.push(...commonDatas);
                if (!isBelengCommon(cur)) {
                    const appRoot = getBelongWrapFixed(cur, 'app');
                    const appDatas = getAppDeclareBelongDatas(appRoot, 'styles') as NodeStyle.Data[];
                    list.push(...appDatas);
                }
                const retentionDatas = getRetentionDatasFromCurrent(cur, 'style');
                list.push(...retentionDatas);
            } break;
            case 'app': {
                const prjRoot = getBelongWrapFixed(cur, 'project');
                const commonDatas = getProjectCommonBelongDeclares(prjRoot, 'styles') as NodeStyle.Data[];

                list.push(...commonDatas);
                deligatePrevNode(cur, (wrapData) => {
                    list.push(wrapData.data);
                });
                const retentionDatas = getRetentionDatasFromCurrent(cur, 'style');
                list.push(...retentionDatas);
            } break;
            case 'project': {
                deligatePrevNode(cur, (wrapData) => {
                    list.push(wrapData.data);
                });
            } break;
        }
        return list;
    }

    /**
     * 現在のノードから参照可能な型を取得する
     * @param cur 
     * @returns 
     */
    export const getReferableStructs = (cur: TreeUtil.ElementNode): ReaderUtil.StructObject[] => {
        const list: NodeStruct.Data[] = [];

        // const layer = getTreeDepthLayer(cur);
        // console.log(layer);
        // if ((['app', 'compdef'] as ModelUtil.NodeType[]).includes(layer)) {
        if (isBelong(cur, 'app')) {
            const appRoot = getBelongWrapFixed(cur, 'app');
            const appDatas = getAppDeclareBelongDatas(appRoot, 'structs') as NodeStruct.Data[];
            // console.log(appDatas);
            list.push(...appDatas);
        }
        const prjRoot = getBelongWrapFixed(cur, 'project');
        const commonDatas = getProjectCommonBelongDeclares(prjRoot, 'structs') as NodeStruct.Data[];
        list.push(...commonDatas);
        return list.map(dtype => {
            return {
                id: dtype.id, fields: dtype.fields
                    // 新規登録時はnullなため除外する
                    .filter(wrap => wrap.data != null)
                    .map(wrap => wrap.data as ModelUtil.NodeModelField)
            };
        });
    }

    export const getAppDeclareBelongDatas = (appRoot: ModelUtil.WrapElement, ownerType: ModelUtil.NodeType) => {
        const owner = getInnerWrap(appRoot, 'declares', ownerType);
        assert(owner != null, 'owner is null.');
        return getBelongDatas(owner);
    }
    export const getProjectApps = (prjRoot: ModelUtil.WrapElement) => {
        const owner = getInnerWrap(prjRoot, 'apps');
        assert(owner != null, 'owner is null.');
        return (owner.data as ModelUtil.NodeApps).apps.map(w => w.data as NodeApp.Data);
    }

    /**
     * プロジェクト共通の宣言情報の要素を取得する
     * @param prjRoot 
     * @param ownerType 
     * @returns 
     */
    export const getProjectCommonBelongDeclares = (prjRoot: ModelUtil.WrapElement, ownerType: ModelUtil.NodeType) => {
        const owner = getInnerWrap(prjRoot, 'common', 'declares', ownerType);
        assert(owner != null, 'owner is null.');
        return getBelongDatas(owner);
    }

    export const getBelongDatas = (owner: ModelUtil.WrapElement) => {
        assert(owner != null, 'owner is null.');
        const listName = getListParamName(owner.type);
        assert(listName != null, 'listName is null.');
        const list = owner.data[listName] as ModelUtil.WrapElement[];
        return list
            // 新規登録時はnullなため除外する
            .filter(wrap => wrap.data != null)
            .map(wrap => wrap.data);
    }


    // export const getReferableGlobalData = (cur: TreeUtil.ElementNode, listType: ModelUtil.NodeType, itemType: ModelUtil.NodeType) => {
    //     const dataList: any[] = [];
    //     const add = (wrap: ModelUtil.WrapElement) => {
    //         console.log(`${itemType} - ${wrap.type}`);
    //         if (wrap.type === itemType) {
    //             dataList.push(wrap.data);
    //         }
    //     }
    //     if (isAppSubordinate(cur)) {
    //         const itemsNode = getBelongNode(cur, ['items']);
    //         if (itemsNode != null) {
    //             const node = getInnerNodeFixed(itemsNode, listType)
    //             deligatePrevNode(node, add);
    //         }
    //     }

    //     const commonNode = getBelongNode(cur, ['common']);
    //     if (commonNode != null) {
    //         const node = getInnerNodeFixed(commonNode, listType)
    //         deligatePrevNode(node, add);
    //     }
    //     console.log(dataList);
    //     return dataList;
    // }

    export const getBelongTypeFixed = (cur: TreeUtil.ElementNode, ...breakTypes: ModelUtil.NodeType[]) => {
        const type = getBelongType(cur, ...breakTypes);
        assert(type != null, 'type is null.');
        return type;
    }
    export const getBelongType = (cur: TreeUtil.ElementNode, ...breakTypes: ModelUtil.NodeType[]) => {
        const wrap = getBelongWrap(cur, ...breakTypes);
        return wrap == null ? null : wrap.type;
    }
    export const getBelongWrapFixed = (cur: TreeUtil.ElementNode, ...breakTypes: ModelUtil.NodeType[]) => {
        const wrap = getBelongWrap(cur, ...breakTypes);
        assert(wrap != null, 'wrap is null.');
        return wrap;
    }
    export const getBelongWrap = (cur: TreeUtil.ElementNode, ...breakTypes: ModelUtil.NodeType[]) => {
        const node = getBelongNode(cur, ...breakTypes);
        return node == null ? null : node.data as ModelUtil.WrapElement;
    }
    export const getBelongNodeFixed = (cur: TreeUtil.ElementNode, ...breakTypes: ModelUtil.NodeType[]) => {
        const node = getBelongNode(cur, ...breakTypes);
        assert(node != null, 'node is null.');
        return node;
    }
    export const getBelongNode = (cur: TreeUtil.ElementNode, ...breakTypes: ModelUtil.NodeType[]) => {
        while (true) {
            const wrap = cur.data as ModelUtil.WrapElement;
            if (breakTypes.includes(wrap.type)) return cur;
            if (cur.parent == null) return null;
            cur = cur.parent;
        }
    }

    /**
     * 同階層において自身よりも前の要素に任意の処理を行う
     * @param curNode 自身のノード
     * @param callback 処理
     */
    const deligatePrevNode = (
        curNode: TreeUtil.ElementNode,
        callback: (wrapData: ModelUtil.WrapElement) => void
    ) => {
        assert(curNode.parent != null, 'curNode.parent is null.');
        // 親からみて自身のインデックスを取得
        const curIndex = curNode.parent.children.findIndex(node => node == curNode);
        assert(curIndex !== -1, 'curIndex is invalid.');

        for (let i = 0; i < curIndex; i++) {
            const wrap = curNode.parent.children[i].data as ModelUtil.WrapElement;
            callback(wrap);
        }
    }

    /**
     * アプリのグローバルステートの定義を取得する
     * @param appRootwrap 
     * @returns 
     */
    export const getGlobalStatesFromApp = (appRootwrap: ModelUtil.WrapElement) => {
        const statesWrap = ModelElementUtil.getInnerWrapFixed(appRootwrap, 'store', 'states');
        return (statesWrap.data as ModelUtil.NodeGenericItems).items.map(stateWrap => stateWrap.data as NodeField.VariableData);
    }
    // export const getLinkageEffectFromApp = (appRootwrap: ModelUtil.WrapElement): NodeTrigger.Data[] => {
    //     const linkageWrap = ModelElementUtil.getInnerWrapFixed(appRootwrap, 'store', 'linkage');
    //     // console.log(linkageWrap);
    //     return (linkageWrap.data as ModelUtil.NodeGenericItems).items.map(mng => mng.data as NodeTrigger.Data);
    // }
    export const getStatesFromComp = (compRootwrap: ModelUtil.WrapElement) => {
        const statesWrap = ModelElementUtil.getInnerWrapFixed(compRootwrap, 'store', 'states');
        return (statesWrap.data as ModelUtil.NodeGenericItems).items.map(stateWrap => stateWrap.data as ModelUtil.NodeModelField);
    }

    export const isSubordinate = (start: TreeUtil.ElementNode, ...breakTypes: ModelUtil.NodeType[]) => {
        let cur = start;
        while (true) {
            const wrap = cur.data as ModelUtil.WrapElement;
            if (breakTypes.includes(wrap.type)) return true;
            if (cur.parent == null) return false;
            cur = cur.parent;
        }
    }

    export const isFunctionSub = (start: TreeUtil.ElementNode) => {
        let cur = start;
        while (true) {
            const wrap = cur.data as ModelUtil.WrapElement;
            if (nodeTypes('func').includes(wrap.type)) return true;
            if (cur.parent == null) return false;
            cur = cur.parent;
        }
    }

    export const getProjectRootFromCurrent = (start: TreeUtil.ElementNode) => {
        let cur = start;
        while (true) {
            if (cur.parent != null) cur = cur.parent;
            else break;
        }
        return cur;
    }

    export const getArgumentFromCurrent = (start: TreeUtil.ElementNode): ModelUtil.NodeModelField[] => {
        let eventRoot = start;
        const breakNode: ModelUtil.NodeType[] = ['func'];
        while (true) {
            const wrap = eventRoot.data as ModelUtil.WrapElement;
            if (breakNode.includes(wrap.type)) break;
            assert(eventRoot.parent != null, `eventRoot.parent is null.[${eventRoot.data.type}]`);
            eventRoot = eventRoot.parent;
        }
        const eventWrap = eventRoot.data as ModelUtil.WrapElement;
        const argsWrap = ModelElementUtil.getInnerWrapFixed(eventWrap, 'args');
        return (argsWrap.data as ModelUtil.ArgsData).args.map(argWrap => argWrap.data as ModelUtil.NodeModelField);
    }

    export const getOwnerCompFromCurrent = (start: TreeUtil.ElementNode): NodeCompdef.Data => {
        let temp = start;
        while (true) {
            const wrap = temp.data as ModelUtil.WrapElement;
            if (wrap.type === 'compdef') break;
            assert(temp.parent != null, 'temp.parent is null.');
            temp = temp.parent;
        }
        return (temp.data as ModelUtil.WrapElement).data;
    }

    export const getPrpfldsFromCompdef = (compdefWrap: ModelUtil.WrapElement): ModelUtil.NodeModelField[] => {
        const props = ModelElementUtil.getInnerWrapFixed(compdefWrap, 'props').data as ModelUtil.NodeProps;
        return props.props
            .filter(p => p.type === 'prpfld')
            .map(p => p.data as ModelUtil.NodeModelField);
    }

    export const getPrpclbksFromCompdef = (compdefWrap: ModelUtil.WrapElement): NodePrpclbk.Data[] => {
        const props = ModelElementUtil.getInnerWrapFixed(compdefWrap, 'props').data as ModelUtil.NodeProps;
        return props.props
            .filter(p => p.type === 'prpclbk')
            .map(p => p.data as NodePrpclbk.Data);
    }

    // /**
    //  * 現在のノードからフェッチ配下のthen要素を取得する
    //  */
    // export const getFetchThensFromCurrent = (start: TreeUtil.ElementNode): ModelUtil.NodeModelField[] => {
    //     let searchNode = start;
    //     const list: ModelUtil.NodeModelField[] = [];
    //     while (true) {
    //         const wrap = searchNode.data as ModelUtil.WrapElement;
    //         if (wrap.type === 'then' || wrap.type === 'cache') list.push(wrap.data);
    //         if (wrap.type === 'fetch' || searchNode.parent == null) break;
    //         searchNode = searchNode.parent;
    //     }
    //     return list;
    // }

    // /**
    //  * 現在のノードからキャッシュを取得する
    //  */
    // export const getRetentionCachesFromCurrent = (start: TreeUtil.ElementNode): ModelUtil.NodeModelField[] => {
    //     return getRetentionDatasFromCurrent(start, 'cache');
    // }

    // /**
    //  * 現在のノードからディスパッチャを取得する
    //  */
    // export const getRetentionFunctionsFromCurrent = (start: TreeUtil.ElementNode): NodeFunction.Data[] => {
    //     return getRetentionDatasFromCurrent(start, 'func');
    // }
    // /**
    //  * 現在のノードからスタイルを取得する
    //  */
    // export const getRetentionStylesFromCurrent = (start: TreeUtil.ElementNode): NodeStyle.Data[] => {
    //     return getRetentionDatasFromCurrent(start, 'style');
    // }

    /**
     * 現在のノードから任意のデータを取得する
     * @param start 
     * @returns 
     */
    export const getRetentionDatasFromCurrent = (start: TreeUtil.ElementNode, type: ModelUtil.NodeType): any[] => {
        const dataList: any[] = [];

        // 自身の親ノードを取得
        const startParent = start.parent;
        assert(startParent != null, 'startParent is null.');

        // #region 自身の要素と隣接するデータの取得
        const parentType = (startParent.data as ModelUtil.WrapElement).type;
        // console.log(parentType);
        /** 保持要素の親となる属性 */
        const retentionTypes: ModelUtil.NodeType[] = ['retention', 'bool', 'iterate', 'accept', 'proc', 'then', 'catch'];
        if (parentType === 'elements') {
            // // コンポーネント直下のelementではretentionは利用できないため、空の配列を返す
            // const isComponentRoot = (startParent.parent?.data as ModelUtil.WrapElement).type === 'compdef';
            // if (isComponentRoot) return [];
            const retentionNode = getInnerNodeFixed(startParent.parent as TreeUtil.ElementNode, 'retention');
            retentionNode.children.forEach(node => {
                const wrap = node.data as ModelUtil.WrapElement;
                if (wrap.type === type) {
                    dataList.push(wrap.data);
                }
            });
        } else if (retentionTypes.includes(parentType)) {
            // console.log('cacheを探す');
            // 親からみて自身のインデックスを取得
            const curIndex = startParent.children.findIndex(node => node == start);
            assert(curIndex !== -1, `curIndex is invalid.[listSize: ${startParent.children.length}, start: ${JSON.stringify(start.data)}]`);

            // 同階層の自身より前に宣言されたデータを取得
            for (let i = 0; i < curIndex; i++) {
                const wrap = startParent.children[i].data as ModelUtil.WrapElement;
                if (wrap.type === type) {
                    dataList.push(wrap.data);
                }
            }
        }
        // #endregion

        // #region 再帰的に上位階層を走査して、保持データを取得
        /**
         * 引数の要素リストからデータを回収する
         * @param items 
         */
        const runItems = (items: ModelUtil.WrapElement[]) => {
            items.forEach(item => {
                if (item.type === type) {
                    dataList.push(item.data);
                }
            });
        }

        // const breakNode: ModelUtil.NodeType[] = ['closure', 'func', 'compdef'];
        // const breakNode: ModelUtil.NodeType[] = ['compdef', 'linkage'];
        const breakNode: ModelUtil.NodeType[] = ['comps', 'func'];
        // ブレークのいずれかに属していない場合
        if (getBelongNode(start, ...breakNode) == null) {
            return dataList;
        }

        const getBaseNodeType = () => {
            let searchNode = start;
            while (true) {
                const wrap = searchNode.data as ModelUtil.WrapElement;
                if (breakNode.includes(wrap.type)) return wrap.type;
                if (searchNode.parent == null) break;
                searchNode = searchNode.parent;
            }
            throw new Error('ありえないツリー構成');
        }
        const baseType = getBaseNodeType();

        // 自身の親の1つ上から開始する（同列をチェックしないようにするため）
        let searchNode = startParent.parent;
        assert(searchNode != null, 'searchNode is null.');
        switch (baseType) {
            // case 'closure':
            case 'func':
                // case 'linkage':
                {
                    // const breakNode: ModelUtil.NodeType[] = ['compdef'];
                    while (true) {
                        const wrap = searchNode.data as ModelUtil.WrapElement;
                        // if (searchNode.parent == null || wrap.type === 'dispatcher') break;
                        if (searchNode.parent == null || breakNode.includes(wrap.type)) break;
                        const collectItems = (type: ModelUtil.NodeType) => {
                            const dummyWrap: ModelUtil.WrapElement = { type, data: {} };
                            const listName = ModelElementUtil.getListParamName(dummyWrap.type);
                            assert(listName != undefined, `listNameが取得できない。type:「${type}」`);
                            if (wrap.type === type) runItems(wrap.data[listName]);
                        }
                        collectItems('proc');
                        collectItems('then');
                        collectItems('iterate');
                        collectItems('catch');
                        collectItems('retention');
                        searchNode = searchNode.parent;
                    }
                } break;
            case 'comps': {
                while (true) {
                    const wrap = searchNode.data as ModelUtil.WrapElement;
                    // console.log(wrap.type);
                    if (searchNode.parent == null || wrap.type === 'comps') break;
                    else if (wrap.type === 'then') runItems(wrap.data['items']);
                    else if (wrap.type === 'catch') runItems(wrap.data['items']);
                    else if (wrap.type === 'bool') runItems(wrap.data['elements']);
                    else if (wrap.type === 'accept') runItems(wrap.data['elements']);
                    else if (wrap.type === 'proc') runItems(wrap.data['items']);
                    else if (wrap.type === 'iterate') runItems(wrap.data['elements']);
                    else if (wrap.type === 'when') runItems(wrap.data['elements']);
                    else if (wrap.type === 'retention') runItems(wrap.data['items']);
                    else if (wrap.type === 'elements') {
                        const parentWrap = searchNode.parent.data as ModelUtil.WrapElement;
                        // console.log(parentWrap.type);
                        // コンポーネント直下のElementsはリテンションがないため処理しない
                        // if (parentWrap.type === 'compdef') break;
                        // console.log(searchNode.parent.data);
                        const retention = getInnerWrapFixed(searchNode.parent.data, 'retention').data as ModelUtil.NodeRetention;
                        // console.log(retention);
                        runItems(retention.items);
                    }
                    searchNode = searchNode.parent;
                }
            } break;
            default: throw new Error(`baseTypeがありえない値 baseType:[${baseType}]`);
        }
        // #endregion

        return dataList;
    }

    export const nodeTypes = (...types: ModelUtil.NodeType[]) => {
        return types;
    }

    /**
     * 現在のノードからイテレータを取得する
     * @param start 
     * @returns 
     */
    export const getIteratorsFromCurrent = (start: TreeUtil.ElementNode) => {
        const dataList: NodeIterate.Data[] = [];
        let cur = start;
        while (true) {
            const wrap = cur.data as ModelUtil.WrapElement;
            if (nodeTypes('compdef', 'app', 'declares').includes(wrap.type)) break;
            assert(cur.parent != null, 'cur.parent is null.');
            if (wrap.type === 'iterate') dataList.push(wrap.data);
            cur = cur.parent;
        }
        return dataList;
    }

    export const getCanDisableTypes = (): ModelUtil.NodeType[] => {
        return ['tag', 'compuse', 'text',
            'execute', 'native', 'return', 'invalidate',
            'case', 'accept', 'iterate',
            'effect', 'focus', 'assign',
            'promise', 'log', 'block', 'variable', 'func'
        ];
    }
}

export default ModelElementUtil;