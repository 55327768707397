import { useContext, useEffect, useMemo, useState } from "react";
import assert from "assert";
import ModelEditDialog from "../modelEditDialog";
import ModelUtil from "../../util/modelUtil";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ModelElementUtil from "../../util/modelElementUtil";
import { GlobalContext } from "../../../../entry/systemEntry";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import AbstractModelEditor from "../abstractModelEditor";
import DataUtil from "../../../../../../common/dataUtil";

namespace NodeLauncher {

    type LocalState = {
        no: FormUtil.CheckableValue;
        appId: FormUtil.CheckableValue;
        name: FormUtil.CheckableValue;
        args: FormUtil.CheckableValue;
    }
    export type Data = {
        no: number;
        appId: string;
        name: string;
        args: DataUtil.KeyValue[];
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, setStore, dispatcher } = useContext(GlobalContext);
        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [apps] = useMemo(() => {
            assert(store.project != null, 'store.projectがnullであってはならない');
            const rootData = store.project.rootData;

            const apps = ModelElementUtil.getProjectApps(rootData);
            return [apps];
        }, []);

        const getNextSeq = () => {
            assert(store.project != null, 'store.projectがnullであってはならない');
            const rootData = store.project.rootData;
            const launchersData = ModelElementUtil.getInnerWrapFixed(rootData, 'release', 'launchers').data as ModelUtil.NodeGenericItems;
            const maxSeq = launchersData.items
                .map(a => a.data == undefined ? -1 : (a.data as Data).no)
                .reduce((ret, cur) => ret > cur ? ret : cur, -1);
            return maxSeq + 1;
        }

        const [ls, setLocalState] = useState<LocalState>({
            no: { value: getNextSeq().toString(), errors: [] },
            appId: { value: '', errors: [] },
            name: { value: '', errors: [] },
            args: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...ls });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });


        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                ls.no.value = data.no.toString();
                ls.appId.value = data.appId;
                ls.name.value = data.name;
                ls.args.value = DataUtil.convertKeyValuesToLineText(data.args);
                invalidate();
            }
        }, []);

        const targetFroms = [ls.no, ls.appId, ls.name, ls.args];
        useEffect(() => {
            const args = DataUtil.convertLineTextToKeyValues(ls.args.value);
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined
                || args == undefined) {
                setInputOK(false);
                return;
            }
            props.temp.inputOK = true;

            const data: Data = {
                no: Number(ls.no.value),
                appId: ls.appId.value,
                name: ls.name.value,
                args,
            }
            setTempData(data);
        }, [...targetFroms]);

        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="No"
                        jsx={<FormUtil.TextField
                            width={80}
                            checkable={ls.no}
                            setCheckable={(checkable) => {
                                ls.no = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkStringLength(value, 1, 3),
                                    errorType: "length"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkNumberRange(value, 0, 255),
                                    errorType: "value"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="App"
                        jsx={<FormUtil.Combobox
                            width={300}
                            checkable={ls.appId}
                            setCheckable={(checkable) => {
                                ls.appId = checkable;
                                invalidate();
                            }}
                            headBlank
                            list={apps.map(app => {
                                return { value: app.id, labelText: app.id };
                            })}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Name"
                        jsx={<FormUtil.TextField
                            width={350}
                            checkable={ls.name}
                            setCheckable={(checkable) => {
                                ls.name = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkStringLength(value, 1, 32),
                                    errorType: "length"
                                }
                            ]}
                        />}
                    />
                </>}
            />
            <FormUtil.BorderPanel
                title="arguments"
                height={200}
                innerJsx={<>
                    <FormUtil.TextArea
                        checkable={ls.args}
                        setCheckable={(checkable) => {
                            ls.args = checkable;
                            invalidate();
                        }}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'launcher';
        }

        // override createAction(manageItems: ModelUtil.ManageItems, tempData: object): void {
        //     const node = manageItems.focusNode;
        //     const element = node.data as ModelUtil.WrapElement;
        //     element.data = tempData;
        //     manageItems.invalidate();
        // }

        // override modifyAction(manageItems: ModelUtil.ManageItems, tempData: object): void {
        //     const node = manageItems.focusNode;
        //     (node.data as ModelUtil.WrapElement).data = tempData;
        //     manageItems.invalidate();
        // }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeLauncher;
