import styled, { css } from "styled-components";
import StructManageTab from "./structManageTab";
import MarkNodeFrame from "../markNode/markNodeFrame";
import { useContext } from "react";
import { GlobalContext } from "../../../entry/systemEntry";
import TreeUtil from "../../../../../common/component/tree/treeUtil";

namespace StructHideMenu {

    export const Component = (props: {
        rootNode: TreeUtil.ElementNode;
        manageState: StructManageTab.LocalState;
        menu: StructManageTab.HideMenu;
        setHideMenu: (menu: StructManageTab.HideMenu) => void;
    }) => {

        const { store, setStore, dispatcher } = useContext(GlobalContext);

        const isDispMenu = (menu: StructManageTab.HideMenu) => {
            return (['off', menu] as StructManageTab.HideMenu[]).includes(props.menu);
        }
        return (<_Wrap>
            <_Contents isOpen={props.menu !== 'off'} >
                {(() => {
                    switch (props.menu) {
                        case 'off': return <></>;
                        case 'mark-node': return <MarkNodeFrame.Component manageState={props.manageState}/>;
                    }
                })()}
            </_Contents>
            <_BarWrap>
                <_HideMenuBar
                    isEnable={true}
                    isDisp={isDispMenu('mark-node')}
                    onClick={() => {
                        props.setHideMenu('mark-node');
                        
                        const cache: MarkNodeFrame.Cache = {
                            rootNode: props.rootNode,
                            isAdd: false
                        };
                        props.manageState.contextParam = null;
                        store.system.freeCache = cache;
                    }}
                ><div>Mark Node</div></_HideMenuBar>
                <_HideMenuBar
                    isEnable={true}
                    isDisp={isDispMenu('search')}
                    onClick={() => {

                    }}
                ><div>Search</div></_HideMenuBar>
            </_BarWrap>
        </_Wrap>);
    }
}

export default StructHideMenu;

const _Wrap = styled.div<{
}>`
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    /* width: 250px; */
    white-space: nowrap;
    /* background-color: #13e600; */
`;

const _Contents = styled.div<{
    isOpen: boolean;
}>`
    display: inline-block;
    position: relative;
    width: ${props => props.isOpen ? '630px' : 0};
    /* width: 200px; */
    /* height: 400px; */
    background-color: #a3a3a3;
    opacity: 0.9;
    transition: width 0.1s;
    overflow: hidden;
    vertical-align: top;
`;

const _BarWrap = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 35px;
    /* height: 100%; */
    vertical-align: top;
    /* background-color: #0014c7; */
`;

const _HideMenuBar = styled.div<{
    isEnable: boolean;
    isDisp: boolean;
}>`
    display: block;
    width: 28px;
    height: 130px;
    opacity: ${props => props.isDisp ? 1 : 0};
    margin-top: 4px;
    background-color: #e4e4e4;
    border-top: 1px solid #858585;
    border-right: 1px solid #858585;
    border-bottom: 1px solid #858585;
    border-radius: 0 10px 10px 0;
    font-size: 18px;
    font-weight: 600;
    color: #5a5a5a;
    text-align: center;
    line-height: 16px;
    box-sizing: border-box;
    ${props => props.isEnable ? '' : css`
        opacity: 0.5;
        pointer-events: none;
    `}
    
    & div {
        width: 130px;
        transform-origin: top left;
        transform: rotate(-90deg) translateX(-100%);
        /* background-color: #fff; */
    }

    &:hover {
        background-color: #d1e5eb;
    }
`;