import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../../abstractModelEditor";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../../common/component/form/validateUtil";
import TreeUtil from "../../../../../../../common/component/tree/treeUtil";
import ModelUtil from "../../../util/modelUtil";
import DataUtil from "../../../../../../../common/dataUtil";
import { GlobalContext } from "../../../../../entry/systemEntry";
import ModelEditDialog from "../../modelEditDialog";
import ModelElementUtil from "../../../util/modelElementUtil";
import assert from "assert";
import DefineUtil from "../../../util/defineUtil";

namespace NodeFunction {

    type LocalState = {
        id: FormUtil.CheckableValue;
        isUseRet: boolean;
        dataType: FormUtil.CheckableValue;
        structId: FormUtil.CheckableValue;
        array: FormUtil.CheckableValue;
    }

    export type Data = {
        id: string;
        ret?: ModelUtil.Field;
        items: ModelUtil.WrapElement[];
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
        nodeType: ModelUtil.NodeType;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);
        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [ls, setLs] = useState<LocalState>({
            id: { value: '', errors: [] },
            isUseRet: false,
            dataType: { value: 'string', errors: [] },
            structId: { value: '', errors: [] },
            array: { value: '0', errors: [] },
        });
        const invalidate = () => setLs({ ...ls });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const [structs] = useMemo(() => {

            const structs = ModelElementUtil.getReferableStructs(manageItems.focusNode);
            return [structs];
        }, []);

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                ls.id.value = data.id;
                if(data.ret != undefined) {
                    ls.isUseRet = true;
                    const retData = data.ret;
                    ls.dataType.value = retData.dataType;
                    ls.array.value = retData.array.toString();
                    if (retData.structId != undefined) {
                        ls.structId.value = retData.structId;
                    }
                }
                invalidate();
            }
        }, []);

        useEffect(() => {
            let targetFroms = [ls.id, ls.dataType, ls.array, ls.structId];
            if(ls.isUseRet) {
                targetFroms = targetFroms.concat([ls.dataType, ls.structId, ls.array]);
            }
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            let ret: ModelUtil.Field | undefined  = undefined;
            if(ls.isUseRet) {
                const dataType = ls.dataType.value as ModelUtil.DataType;
                let modelId: string | undefined = undefined;
                if (ls.structId.value != '') {
                    modelId = ls.structId.value;
                }
                ret = {
                    dataType,
                    structId: modelId,
                    array: Number(ls.array.value),
                }
            }
            let items: ModelUtil.WrapElement[] = [];
            if (props.temp.data != null) {
                items = (props.temp.data as Data).items;
            }
            const data: Data = {
                id: ls.id.value,
                ret,
                items
            }
            setTempData(data);
        }, [ls]);

        const isDispStruct = () => {
            const list: ModelUtil.DataType[] = ['struct'];
            return list.includes(ls.dataType.value as ModelUtil.DataType);
        }

        return (<>
            <FormUtil.BorderPanel
                title="base"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Id"
                        jsx={<FormUtil.TextField
                            width={200}
                            checkable={ls.id}
                            setCheckable={(checkable) => {
                                ls.id = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={ValidateUtil.idValidates}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Return"
                        jsx={<FormUtil.SwitchTwoFace
                            label1="NONE"
                            label2="USE"
                            width={160}
                            rate1={50}
                            rate2={50}
                            isUse={ls.isUseRet}
                            callback={() => {
                                ls.isUseRet = !ls.isUseRet;
                                if (!ls.isUseRet) {
                                    ls.dataType.value = 'string';
                                    ls.array.value = '0';
                                    ls.structId.value = '';
                                }
                                invalidate();
                            }}
                        />}
                    />
                </>}
            />
            <FormUtil.BorderPanel
                title="return"
                isVisible={ls.isUseRet}
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Data Type"
                        jsx={<FormUtil.Combobox
                            width={200}
                            checkable={ls.dataType}
                            setCheckable={(checkable) => {
                                ls.dataType = checkable;
                                if (!isDispStruct()) ls.structId.value = '';
                                invalidate();
                            }}
                            list={ModelUtil.DataTypes
                                // // callbackはpropのみで利用可能
                                // .filter(type => {
                                //     return !(props.nodeType !== 'prop' && type === 'callback');
                                // })
                                .map(type => {
                                    return { value: type, labelText: type }
                                })}
                            validates={!ls.isUseRet ? [] : [
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Struct Id"
                        isVisible={isDispStruct()}
                        jsx={<FormUtil.Combobox
                            width={280}
                            checkable={ls.structId}
                            setCheckable={(checkable) => {
                                ls.structId = checkable;
                                invalidate();
                            }}
                            headBlank
                            list={structs.map(model => {
                                const labelText = `${model.id} (${model.fields.length}fields)`;
                                return { value: model.id, labelText }
                            })}
                            validates={!ls.isUseRet || !isDispStruct() ? [] : [
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Array"
                        // isVisible={(localState.dataType.value as ModelUtil.DataType) !== 'callback'}
                        jsx={<FormUtil.TextField
                            width={50}
                            checkable={ls.array}
                            setCheckable={(checkable) => {
                                ls.array = checkable;
                                invalidate();
                            }}
                            isNumber
                            isEnabled={true}
                            validates={!ls.isUseRet ? [] : [
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkNumberRange(value, 0, 2),
                                    errorType: "length"
                                }
                            ]}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        private nodeType: ModelUtil.NodeType;

        constructor(nodeType: ModelUtil.NodeType) {
            super();
            this.nodeType = nodeType;
        }

        getNodeType(): ModelUtil.NodeType {
            return this.nodeType;
        }
        override createAction(manageItems: ModelUtil.ManageItems, tempData: object): void {
            const node = manageItems.focusNode;
            const element = node.data as ModelUtil.WrapElement;
            element.data = tempData;
            // const modelData = element.data as ModelUtil.NodeFunc;

            ModelElementUtil.addElementNodeDeep(node, {
                type: 'args',
                data: {
                    args: []
                } as ModelUtil.ArgsData
            });
            ModelElementUtil.addElementNodeDeep(node, {
                type: 'proc',
                data: {
                    items: []
                } as ModelUtil.NodeProcedure
            });
            manageItems.invalidate();
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp}
                nodeType={this.nodeType}
            />);
        }
    }
}

export default NodeFunction;
