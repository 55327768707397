import { useMemo, useEffect } from "react";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ModelElementUtil from "../../util/modelElementUtil";
import ModelUtil from "../../util/modelUtil";
import assert from "assert";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import NodeUpdate from "../var/nodeUpdate";
import AssertUtil from "../../../../../../common/assertUtil";
import ReaderUtil from "../../../../gui/readerUtil";


namespace VariableChooser {

    // export type RootTargetType = 'state' | 'cache' | 'property';
    // export const RootTargetTypes = ['state', 'cache', 'comparg', 'funcarg'] as const;
    // export const RootTargetTypes = ['state', 'variable', 'comparg', 'funcarg'] as const;
    export const RootTargetTypes = ['state', 'variable'] as const;
    // export type RootTargetType = 'state'|'variable'| 'comparg'| 'funcarg'
    export type RootTargetType = typeof RootTargetTypes[number];

    export interface Chooser {
        target: RootTargetType;
        rootId: string;
        levelProps: string[];
    }

    export type variableProps = {
        states?: ModelUtil.NodeModelField[];
        variables?: ModelUtil.NodeModelField[];
        // funcargs?: ModelUtil.NodeModelField[];
        // prpflds?: ModelUtil.NodeModelField[];
        structs: ReaderUtil.StructObject[];
    }

    export type InnerField = {
        array: number;
        dataType: ModelUtil.DataType;
        struct: ReaderUtil.StructObject | null;
        form: FormUtil.CheckableValue;
    };

    export interface InnerTarget extends ModelUtil.NodeModelField {
        isIndex: boolean;
    }

    /** 呼び出し元から受け取る必要な情報 */
    export type ReceiveState = {
        rootTarget: FormUtil.CheckableValue;
        rootId: FormUtil.CheckableValue;
        innerFieldForms: InnerField[];
    }

    export type Argument = {
        localState: ReceiveState,
        states?: ModelUtil.NodeModelField[],
        variables?: ModelUtil.NodeModelField[],
        // propFields?: ModelUtil.NodeModelField[],
        // funcargs?: ModelUtil.NodeModelField[],
        structs: ReaderUtil.StructObject[],
        extendAction?: () => void;
    }

    export const getAddressSimple = (chooser: Chooser) => {
        let detail = '';
        if (chooser.levelProps.length > 0) {
            detail = '.' + chooser.levelProps
                .map(l => `{${l}}`).join('.');
        }
        return chooser.rootId + detail;
    }

    export const getChooserStates = (chooser: Chooser, vars: variableProps): [ModelUtil.Field, string] => {
        const getTargets = () => {
            switch (chooser.target) {
                case 'state': return vars.states;
                case 'variable': return vars.variables;
                // case 'comparg': return vars.prpflds;
                // case 'funcarg': return vars.funcargs;
            }
        }
        const targets = getTargets();
        assert(targets != undefined, 'targets is undefined.');

        // console.log(targets);
        const root = targets.find(t => t.id === chooser.rootId);
        assert(
            root != undefined,
            AssertUtil.errorFindFromArray('root', chooser.rootId, targets.map(t => t.id))
        );

        let lastField = JSON.parse(JSON.stringify(root)) as ModelUtil.NodeModelField;
        let address = root.id;
        chooser.levelProps.forEach(l => {
            if (lastField.array > 0) {
                lastField.array--;
                address += `[{${l}}]`;
            } else if (lastField.dataType === 'struct') {
                const model = vars.structs.find(m => m.id === lastField.structId);
                assert(model != undefined, 'model is undefined.');
                const nextField = model.fields.find(f => f.id === l);
                assert(nextField != undefined, 'nextField is undefined.');
                lastField = JSON.parse(JSON.stringify(nextField));
                address += `.{${l}}`;
            } else {
                throw new Error('おこりえない分岐。');
            }
        });
        return [lastField, address];
    }

    /** ツリーのデータを画面のフォームに設定する */
    export const mappingDataToForm = (
        arg: Argument,
        data: {
            target: RootTargetType;
            rootId: string;
            levelProps: string[];
        },
    ) => {
        const localState = arg.localState;
        localState.rootTarget.value = data.target;
        localState.rootId.value = data.rootId;
        let root: ModelUtil.NodeModelField | undefined = undefined;
        if (localState.rootTarget.value !== '' && localState.rootId.value !== '') {
            delegateRootModel(arg, (model) => {
                root = model;
            });
        }
        if (root == undefined) return '...';
        root = root as ModelUtil.NodeModelField;
        localState.innerFieldForms = [];
        data.levelProps.forEach((lp, i) => {
            if (i === 0) {
                // 初回のみルートから取得する
                assert(root != undefined, 'root is undefined.');
                const innerForm = createInnerForm({ ...root, isIndex: false }, arg.structs);
                innerForm.form.value = lp;
                localState.innerFieldForms.push(innerForm);
            } else {
                const last = getLastFieldInfo(arg);
                assert(last != undefined, 'last is undefined.');
                const innerForm = createInnerForm(last, arg.structs);
                innerForm.form.value = lp;
                localState.innerFieldForms.push(innerForm);
            }
        });
    }

    /** 下階層のフォーム情報を生成する */
    const createInnerForm = (curField: InnerTarget, models: ReaderUtil.StructObject[]): InnerField => {
        if (curField.array === 0) {
            const curStruct = models.find(m => m.id === curField.structId);
            assert(curStruct != undefined, 'modelInfo is null.');
            return {
                array: 0,
                dataType: curField.dataType,
                struct: curStruct,
                form: { value: '', errors: [] }
            };
        } else {
            let array = curField.array;
            if (curField.isIndex) array--;
            const getStruct = () => {
                if (curField.dataType === 'struct') {
                    const curModelInfo = models.find(m => m.id === curField.structId);
                    assert(curModelInfo != undefined, 'modelInfo is null.');
                    return curModelInfo;
                } else {
                    return null;
                }
            }
            return {
                array,
                dataType: curField.dataType,
                struct: getStruct(),
                form: { value: '', errors: [] }
            };
        }
    }

    /** キャッシュのフィールド情報を生成して返す */
    // export const getCacheFieldInfo = (
    //     cache: ModelUtil.NodeCache,
    //     states: ModelUtil.NodeModelField[],
    //     caches: ModelUtil.NodeCache[],
    //     models: ModelElementUtil.ModelInfo[]
    // ): ModelUtil.NodeModelField => {
    //     switch (cache.method) {
    //         case 'build': {
    //             const buildProps = cache.buildProps as ModelUtil.BuildProps;
    //             return { ...buildProps, id: cache.id };
    //         }
    //         case 'clone': {
    //             const cloneProps = cache.cloneProps as ModelUtil.CloneProps;
    //             let rootModel: ModelUtil.NodeModelField | undefined = undefined;
    //             if (cloneProps.target === 'state') {
    //                 rootModel = states.find(s => s.id === cloneProps.rootId);
    //             } else if (cloneProps.target === 'cache') {
    //                 const cache = caches.find(c => c.id === cloneProps.rootId);
    //                 assert(cache != undefined, 'cache is undefined.');
    //                 rootModel = getCacheFieldInfo(cache, states, caches, models);
    //             }
    //             assert(rootModel != undefined, 'rootModel is undefined.');
    //             /** 走査用のモデル（クローンを書き換えするためディープコピー） */
    //             let cur = JSON.parse(JSON.stringify(rootModel)) as ModelUtil.NodeModelField;
    //             cloneProps.levelProps.forEach(l => {
    //                 if (cur.array > 0) {
    //                     cur.array--;
    //                 } else {
    //                     if (cur.modelId != undefined) {
    //                         const curModel = models.find(m => m.modelId === cur.modelId);
    //                         assert(curModel != undefined, 'curModel is undefined.');
    //                         const nextModel = curModel.fields.find(f => f.id === l);
    //                         assert(nextModel != undefined, 'nextModel is undefined.');
    //                         cur = JSON.parse(JSON.stringify(nextModel)) as ModelUtil.NodeModelField;;
    //                     }
    //                 }
    //             });
    //             return { ...cur, id: cache.id };
    //         }
    //     }
    // }

    const getRootTarget = (localState: ReceiveState) => {
        let target = localState.rootTarget.value;
        if (target === '') return null;
        return target as RootTargetType;
    }

    /** ターゲットに応じてルート要素を取得し、任意の処理を委譲する */
    // const delegateRootModel = (
    //     arg: Argument,
    //     callback: (model: ModelUtil.NodeModelField) => void
    // ) => {
    //     const rootTarget = getRootTarget(arg.localState);
    //     assert(rootTarget != null, 'rootTarget is null.');
    //     switch (rootTarget) {
    //         case 'state': {
    //             const root = arg.states.find(s => s.id === arg.localState.rootId.value);
    //             assert(root != undefined, 'root is undefined.');
    //             callback(root);
    //         } break;
    //         case 'cache': {
    //             // console.log(caches);
    //             // console.log(localState.rootId.value);
    //             const root = caches.find(c => c.id === arg.localState.rootId.value);
    //             assert(root != undefined, 'root is undefined.');
    //             const model = getCacheFieldInfo(root, states, caches, arg.models);
    //             callback(model);
    //         } break;
    //     }
    // }
    const delegateRootModel = (
        arg: Argument,
        callback: (model: ModelUtil.NodeModelField) => void
    ) => {
        const states = arg.states ?? [];
        const caches = arg.variables ?? [];
        // const propFields = arg.propFields ?? [];
        // const funcargs = arg.funcargs ?? [];
        const rootTarget = getRootTarget(arg.localState);
        assert(rootTarget != null, 'rootTarget is null.');
        const getRoot = () => {
            const rootId = arg.localState.rootId.value;
            switch (rootTarget) {
                case 'state': return states.find(s => s.id === rootId);
                case 'variable': return caches.find(c => c.id === rootId);
                // case 'comparg': return propFields.find(p => p.id === rootId);
                // case 'funcarg': return funcargs.find(a => a.id === rootId);
            }
        }
        const root = getRoot();
        assert(root != undefined, 'root is undefined.');
        callback(root);
    }

    /** ステートの選択されている最後の階層の情報を返す */
    export const getLastFieldInfo = (arg: Argument): InnerTarget | null => {
        const localState = arg.localState;
        if (localState.innerFieldForms.length === 0) {
            let root: ModelUtil.NodeModelField | undefined = undefined
            if (localState.rootTarget.value !== '' && localState.rootId.value !== '') {
                delegateRootModel(arg, (model) => {
                    root = model;
                });
            }
            // 未入力の場合
            if (root == undefined) return null;
            root = root as ModelUtil.NodeModelField;
            return { ...root, isIndex: false };
        } else {
            const lastInner = localState.innerFieldForms[localState.innerFieldForms.length - 1];
            // エラーがある場合NULL
            if (lastInner.form.errors.length > 0) return null;

            /** フォームに入力された値 */
            const formVal = lastInner.form.value;
            if (lastInner.array === 0) {
                if (lastInner.struct != null) {
                    const field = lastInner.struct.fields.find(f => f.id === formVal);
                    // 未入力の場合
                    if (field == undefined) return null;
                    // return { ...field, isIndex: false } ?? null;
                    return { ...field, isIndex: false };
                } else {
                    return { id: '', array: lastInner.array, dataType: lastInner.dataType, isIndex: false }
                }
            } else {
                if (lastInner.struct != null) {
                    return { id: '', array: lastInner.array, dataType: lastInner.dataType, structId: lastInner.struct.id, isIndex: true };
                } else {
                    return { id: '', array: lastInner.array, dataType: lastInner.dataType, isIndex: true };
                }
            }
        }
    }

    export const Component = (props: {
        arg: Argument;
        isVisible?: boolean;
        invalidate: () => void;
    }) => {
        const localState = props.arg.localState;
        const invalidate = props.invalidate;
        const models = props.arg.structs;
        const states = props.arg.states;
        const caches = props.arg.variables;
        // const propFields = props.arg.propFields;
        // const funcargs = props.arg.funcargs;

        const last = getLastFieldInfo(props.arg);

        /** 次の階層を展開可能か */
        const isNextEnable = () => {
            if (last == null) return false;
            if (last.dataType === 'struct') return true;
            let array = last.array;
            if (last.isIndex) array--;
            return array > 0;
        }

        /** 次の階層を展開する */
        const deployNextLayer = () => {
            const formList = localState.innerFieldForms.slice();
            localState.innerFieldForms = formList;
            const addInnerForm = (curField: InnerTarget) => {
                const innerForm = createInnerForm(curField, models);
                formList.push(innerForm);
            };
            if (localState.innerFieldForms.length === 0) {
                delegateRootModel(props.arg, (model) => {
                    addInnerForm({ ...model, isIndex: false });
                });
            } else {
                // const last = getLastFieldInfo(localState, states);
                assert(last != null, 'last is null.');
                addInnerForm(last);
            }
            invalidate();
        };

        const getRequestDispName = () => {
            if (last == null) return '-';
            return ModelUtil.getField(last);
        }

        const getTargetPath = () => {
            let root: ModelUtil.NodeModelField | undefined = undefined;
            if (localState.rootTarget.value !== '' && localState.rootId.value !== '') {
                delegateRootModel(props.arg, (model) => {
                    root = model;
                });
            }
            if (root == undefined) return '...';
            root = root as ModelUtil.NodeModelField;
            let path = root.id;
            localState.innerFieldForms.some(inner => {
                if (inner.form.errors.length > 0) return 1;
                if (inner.array === 0 && inner.dataType === 'struct') {
                    path += `.${inner.form.value}`;
                } else {
                    path += `[${inner.form.value}]`;
                }
            });
            return path;
        }

        const getRootModels = () => {
            const rootTarget = getRootTarget(localState) as RootTargetType;
            if (rootTarget == null) return [];
            switch (rootTarget) {
                case 'state': return states ?? [];
                case 'variable': return caches ?? [];
                // case 'comparg': return propFields ?? [];
                // case 'funcarg': return funcargs ?? [];
            }
        }

        const rootModels = useMemo(() => {
            return getRootModels();
        }, [localState.rootTarget]);

        const isRootEnable = localState.rootTarget.value !== '';

        /** 下階層の選択フォームのJSX */
        const innerFieldsJsx: JSX.Element[] = useMemo(() => {
            return localState.innerFieldForms.map((inner, i) => {
                const type = inner.struct == null ? inner.dataType : inner.struct.id;
                const labelName = `${type}${'[]'.repeat(inner.array)}`;

                if (inner.array === 0 && inner.struct != null) {
                    return (
                        <FormUtil.FormRecord key={i}
                            titleLabel={labelName}
                            jsx={<FormUtil.Combobox
                                width={300}
                                checkable={inner.form}
                                setCheckable={(checkable) => {
                                    inner.form = checkable;
                                    localState.innerFieldForms = localState.innerFieldForms.slice();
                                    invalidate();
                                }}
                                extend={() => {
                                    localState.innerFieldForms = localState.innerFieldForms.slice();
                                    localState.innerFieldForms = localState.innerFieldForms.slice(0, i + 1);
                                    if (props.arg.extendAction != undefined) {
                                        props.arg.extendAction();
                                    }
                                }}
                                headBlank
                                list={inner.struct.fields.map(f => {
                                    return ModelUtil.getFieldListItem(f);
                                })}
                                validates={[
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    }
                                ]}
                            />}
                        />
                    );
                } else {
                    return (
                        <FormUtil.FormRecord key={i}
                            titleLabel={labelName}
                            jsx={<FormUtil.TextField
                                width={400}
                                checkable={inner.form}
                                setCheckable={(checkable) => {
                                    inner.form = checkable;
                                    localState.innerFieldForms = localState.innerFieldForms.slice();
                                    invalidate();
                                }}
                                extend={() => {
                                    localState.innerFieldForms = localState.innerFieldForms.slice();
                                    localState.innerFieldForms = localState.innerFieldForms.slice(0, i + 1);
                                    if (props.arg.extendAction != undefined) {
                                        props.arg.extendAction();
                                    }
                                }}
                                validates={[
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    }
                                ]}
                            />}
                        />
                    );
                }
            });
        }, [localState]);

        return (<>
            <FormUtil.BorderPanel
                title="variable chooser"
                isVisible={props.isVisible}
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Target"
                        jsx={<FormUtil.Combobox
                            width={300}
                            isReadOnly={states == undefined || caches == undefined}
                            checkable={localState.rootTarget}
                            setCheckable={(checkable) => {
                                localState.rootTarget = checkable;
                                invalidate();
                            }}
                            extend={() => {
                                localState.rootId.value = '';
                                localState.innerFieldForms = [];
                            }}
                            headBlank
                            list={RootTargetTypes
                                .filter(t => (
                                    t === 'state' && props.arg.states != undefined ||
                                    t === 'variable' && props.arg.variables != undefined
                                    // t === 'comparg' && props.arg.propFields != undefined ||
                                    // t === 'funcarg' && props.arg.funcargs != undefined
                                ))
                                .map(t => {
                                    return { value: t, labelText: t };
                                })}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Root"
                        isEnabled={isRootEnable}
                        jsx={<FormUtil.Combobox
                            width={300}
                            checkable={localState.rootId}
                            setCheckable={(checkable) => {
                                localState.rootId = checkable;
                                invalidate();
                            }}
                            extend={() => {
                                localState.innerFieldForms = [];
                                if (props.arg.extendAction != undefined) {
                                    props.arg.extendAction();
                                }
                            }}
                            headBlank
                            list={rootModels.map(model => ModelUtil.getFieldListItem(model))}
                            validates={!isRootEnable ? [] : [
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                    {innerFieldsJsx}
                    <FormUtil.ButtonRecord align='left' buttons={[
                        {
                            label: 'Next Layer',
                            isEnable: isNextEnable(),
                            width: 180,
                            callback: deployNextLayer
                        },
                        {
                            label: 'Upper Layer',
                            isEnable: localState.innerFieldForms.length > 0,
                            width: 180,
                            callback: () => {
                                localState.innerFieldForms.splice(localState.innerFieldForms.length - 1, 1);
                                localState.innerFieldForms = localState.innerFieldForms.slice();
                                invalidate();
                            }
                        },
                    ]} />
                    <FormUtil.FormRecord
                        titleLabel="Address"
                        jsx={<FormUtil.FixedText
                            width={400}
                            value={getTargetPath()}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Data Type"
                        jsx={<FormUtil.FixedText
                            width={400}
                            value={getRequestDispName()}
                        />}
                    />
                </>}
            />
        </>);
    }
}

export default VariableChooser;