import TreeUtil from "../../../common/component/tree/treeUtil";
import StructManageTab from "../../contents/develop/function/tree/structManageTab";
import ModelElementUtil from "../../contents/develop/function/util/modelElementUtil";
import ModelUtil from "../../contents/develop/function/util/modelUtil";
import StoreTable from "./storeTable";

namespace StoreProject {

    export type Props = {
        tables: StoreTable.TableMaster[];
        rootData: ModelUtil.WrapElement;
        workData: WorkData;
    }

    export type WorkData = {
        treeMemo: TreeUtil.Memo;
        bookmarks: BookmarkData[];
    }

    export type BookmarkData = {
        key: string;
        name: string;
    }

    export const getInitialData = (option: ModelElementUtil.ProjectInitialOption): Props => {
        return {
            tables: [],
            rootData: ModelElementUtil.getInitialProject(option),
            workData: {
                treeMemo: {openNodes:[], scrollX: 0, scrollY: 0, selectNode: ''},
                bookmarks: []
            }
        }
    }
}

export default StoreProject;