namespace PrefixUtil {

    // /** グローバルステート */
    // export const GLOBAL_STATE = 'gs';
    // /** コンポーネントステート */
    // export const COMPONENT_STATE = 'cs';
    /** ステート */
    export const STATE = 's';
    /** Refオブジェクト */
    export const REF = 'ref';
    /** テンポラリ */
    export const TEMP = 't';
    /** 変数 */
    export const VARIABLE = 'v';
    /** 定数 */
    export const CONSTANT = 'c';
    /** 関数 */
    export const FUNCTION = 'f';
    /** ディスパッチ引数 */
    export const ARGUMENT = 'da';
    /** ランチャー引数 */
    export const LAUNCH_ARGUMENT = 'la';
    /** システム */
    export const SYSTEM = 'sy';
    /** スタイル引数 */
    export const STYLE_ARGUMENT = 'sa';
    export const STYLE_FOUMULA = 'sf';
    export const PRPFLD = 'pf';
    export const PRPCLBK = 'pc';
    export const ITERATE = 'it';
    export const LIST_LEN = 'len';
    export const LIST_CUR = 'cur';
    export const STR_LEN = 'len';

    export const getCompChildId = (compdefId: string) => {
        return `_${compdefId}_Child`;
    }
}

export default PrefixUtil;