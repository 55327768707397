import assert from "assert";
import TreeUtil from "../../../../../common/component/tree/treeUtil";
import ModelUtil from "../util/modelUtil";
import ModelElementUtil from "../util/modelElementUtil";

namespace NodeRelationUtil {

    export type DeclareScope = {
        compdefs: TreeUtil.ElementNode[];
        functions: TreeUtil.ElementNode[];
        structs: TreeUtil.ElementNode[];
        variables: TreeUtil.ElementNode[];
        states: TreeUtil.ElementNode[];
        styles: TreeUtil.ElementNode[];
    }

    export const extractDeclareScope = (curNode: TreeUtil.ElementNode): DeclareScope => {
        const scope: DeclareScope = {
            compdefs: [],
            states: [],
            variables: [],
            structs: [],
            functions: [],
            styles: []
        };

        let address: TreeUtil.ElementNode[] = [];
        let cur = curNode;
        while (cur.parent != null) {
            address.push(cur);
            cur = cur.parent;
        }
        address = address.reverse();
        if (address.length === 0) return scope;

        // console.log(address.length);
        const getNodeWrap = (node: TreeUtil.ElementNode) => node.data as ModelUtil.WrapElement;
        const rootNode = cur;
        const firstWay = rootNode.children.find(child => child == address[0]);
        assert(firstWay != null, 'firstWayがundefinedであってはならない。');

        const isBelongAddress = (node: TreeUtil.ElementNode) => address.find(n => n == node) != undefined;


        const appendStates = (statesNode: TreeUtil.ElementNode) => {
            scope.states.push(...statesNode.children);
        }
        const appendDeclares = (declaresNode: TreeUtil.ElementNode) => {
// console.log('appendDeclares');
            const stylesNode = ModelElementUtil.getInnerNodeFixed(declaresNode, 'styles');
            const structsNode = ModelElementUtil.getInnerNodeFixed(declaresNode, 'structs');
            const functionsNode = ModelElementUtil.getInnerNodeFixed(declaresNode, 'funcs');
            const componentsNode = ModelElementUtil.getInnerNodeFixed(declaresNode, 'comps');

            scope.styles.push(...stylesNode.children);
            scope.structs.push(...structsNode.children);
            scope.functions.push(...functionsNode.children);
            scope.compdefs.push(...componentsNode.children);

            const runComponentNode = (compNode: TreeUtil.ElementNode) => {
                const statesNode = ModelElementUtil.getInnerNodeFixed(compNode, 'store', 'states');
                appendStates(statesNode);

                const runTagNodeRec = (tagNode: TreeUtil.ElementNode) => {
                    let childTagNodes = tagNode.children;

                    const retentionNode = tagNode.children.find(tagChild => {
                        return getNodeWrap(tagChild).type === 'retention';
                    });
                    if (retentionNode != undefined) {

                        const elementsNode = tagNode.children.find(tagChild => {
                            return getNodeWrap(tagChild).type === 'elements';
                        });
                        assert(elementsNode != undefined, 'elementsNodeがundefinedであってはならない。');
                        childTagNodes = elementsNode.children;
                    }
                    childTagNodes.forEach(childTagNode => {
                        runTagNodeRec(childTagNode);
                    });
                }

                const retentionNode = ModelElementUtil.getInnerNodeFixed(compNode, 'retention');
                runRetentionNode(retentionNode);

                const elementsNode = ModelElementUtil.getInnerNodeFixed(compNode, 'elements');
                runTagNodeRec(elementsNode);
            }

            const runRetentionNode = (retentionNode: TreeUtil.ElementNode) => {

                retentionNode.children.some(itemNode => {
                    const wrap = getNodeWrap(itemNode);
                    switch (wrap.type) {
                        case 'variable': {
                            scope.variables.push(itemNode);
                        } break;
                        case 'func': {
                            scope.functions.push(itemNode);
                        } break;
                        case 'style': {
                            scope.styles.push(itemNode);
                        } break;
                        case 'compdef': {
                            scope.compdefs.push(itemNode);
                            if(isBelongAddress(itemNode)) {
                                runComponentNode(itemNode);
                            }
                        } break;
                    }

                    if (isBelongAddress(itemNode)) return 1;
                });
            }

            if (isBelongAddress(componentsNode)) {
                componentsNode.children.forEach(compNode => {
                    runComponentNode(compNode);
                });
            }
        }

        switch (getNodeWrap(firstWay).type) {
            case 'common': {
            } break;
            case 'apps': {
                const declaresNode = ModelElementUtil.getInnerNodeFixed(rootNode, 'common', 'declares');

                appendDeclares(declaresNode);

                const appsNode = ModelElementUtil.getInnerNodeFixed(rootNode, 'apps');

                appsNode.children.forEach(appNode => {
                    if (isBelongAddress(appNode)) {

                        const statesNode = ModelElementUtil.getInnerNodeFixed(appNode, 'store', 'states');
                        appendStates(statesNode);

                        const declaresNode = ModelElementUtil.getInnerNodeFixed(appNode, 'declares');
                        appendDeclares(declaresNode);
                    }
                });
            } break;
        }

        // const runDeclareNode = (declareNode: TreeUtil.ElementNode, lastChildNode: TreeUtil.ElementNode | null) => {

        //     declareNode.children.some(child => {
        //         const item = child.data as ModelUtil.WrapElement;
        //         assert(item != undefined, 'itemがundefinedであってはならない。');
        //         // // 非活性は飛ばす（コンティニュー）
        //         // if (item.disabled) return 1;
        //         switch (item.type) {
        //             case 'compdef': {
        //                 scope.compdefs.push(child);
        //             } break;
        //             case 'func': {
        //                 scope.functions.push(child);
        //             } break;
        //             case 'variable': {
        //                 scope.variables.push(child);
        //             } break;
        //             case 'style': {
        //                 scope.styles.push(child);
        //             } break;
        //         }
        //         // 子要素に達したらbreakする
        //         if (lastChildNode == child) return 1;
        //     });
        // }

        // // let cur = curNode;
        // while (cur.parent != null) {
        //     const lastChild = cur;
        //     cur = cur.parent;
        //     const wrap = cur.data as ModelUtil.WrapElement;

        //     // リテンション要素の走査
        //     switch (wrap.type) {
        //         case 'tag':
        //         case 'iterate':
        //         case 'compdef': {
        //             const retentionNode = ModelElementUtil.getInnerNode(cur, 'retention');
        //             if (retentionNode != undefined) runDeclareNode(retentionNode, lastChild);
        //         } break;
        //         case 'effect':
        //         case 'func': {
        //             const procNode = ModelElementUtil.getInnerNode(cur, 'proc');
        //             if (procNode != undefined) runDeclareNode(procNode, lastChild);
        //         } break;
        //     }
        //     // ステート宣言の走査
        //     switch (wrap.type) {
        //         case 'app':
        //         case 'compdef': {
        //             const statesNode = ModelElementUtil.getInnerNode(cur, 'store', 'states');
        //             if (statesNode != undefined) runDeclareNode(statesNode, null);
        //         } break;
        //     }
        // }
        // console.log(scope);
        return scope;
    }
};

export default NodeRelationUtil;