import styled from "styled-components";
import ModelUtil from "../develop/function/util/modelUtil";
import ReaderUtil from "./readerUtil";
import TagUtil from "../develop/function/editor/ui/tag/tagUtil";
import { useEffect, useMemo, useRef, useState } from "react";
import assert from "assert";
import NodeTag from "../develop/function/editor/ui/tag/nodeTag";
import DataUtil from "../../../common/dataUtil";
import HtmlUtil from "../../util/htmlUtl";

namespace AppPartialTag {

    export const Component = (props: {
        appCache: ReaderUtil.GlobalAppCache;
        getElementsJsxRec: (elements: ModelUtil.WrapElement[], dynamicProps: ReaderUtil.DynamicProps) => JSX.Element[];
        data: NodeTag.Data;
    }) => {

        const [dummy, setDummy] = useState<any>({});

        const dispStackTrace = (err: unknown) => {
            props.appCache.dispStackTrace({ type: 'tag', data: props.data }, err);
        }

        const ref = useRef();

        // const upperLevelCompStates = props.appCache.dynamics.componentStates;
        const cloneDynamicProps = ReaderUtil.cloneDynamicProps(props.appCache.dynamics);
        // const cloneDynamicProps: ReaderUtil.DynamicProps = {...props.appCache.dynamics};
        // cloneDynamicProps.componentStates = upperLevelCompStates;

        const refId = props.data.refId;
        if (refId != undefined) {
            cloneDynamicProps.refs.push({
                key: refId,
                obj: ref
            });
        }

        const getFomulaAplliedValue = (base: string) => ReaderUtil.getFomulaAplliedValue(base, props.appCache);

        const data = props.data;
        const localId = data.partialId;
        if (localId != undefined) {
            const partialSets: ReaderUtil.PartialSet[] = cloneDynamicProps.partialSets;
            const invalidate = () => setDummy({});

            // console.log(localPoints);
            partialSets.push({
                id: localId,
                invalidate
            });
            // 再描画関数の更新
            ReaderUtil.updateInvalidateCallback(cloneDynamicProps);
            // console.log(localPoints);
        }

        const refs: DataUtil.KeyObject[] = cloneDynamicProps.refs.slice();
        cloneDynamicProps.refs = refs;

        const buildJsx = () => {

            // cloneDynamicProps.variables = cloneDynamicProps.variables.slice();
            // nextDynamicProps.funcs = nextDynamicProps.funcs.slice();


            const getAppliedStyleSources = () => {
                try {
                    // console.log(props.appCache.dynamics.componentStates);
                    return ReaderUtil.getAppliedStyleSources(data.styles, props.appCache).join('\n');
                } catch (err) {
                    dispStackTrace(err);
                }
            }
            const src = getAppliedStyleSources();

            // const innerJsx = HtmlUtil.VoidElements.includes(data.element) ? null : props.getElementsJsxRec(data.elements, nextDynamicProps);
            // props.appCache.dynamics = cloneDynamicProps;
            const innerJsx = data.elements.length > 0 ? props.getElementsJsxRec(data.elements, cloneDynamicProps) : null;
            // const innerJsx = props.generateJsxes();
            // const innerJsx = <>{props.jsx}</>;

            const attrs: any = {};
            try {
                if (data.attrs != undefined) {
                    data.attrs.forEach(attr => {
                        let value = getFomulaAplliedValue(attr.value);
                        // 値が関数の場合、例外処理でラッピングする
                        if (value instanceof Function) {
                            const func = value as (...args: any) => any;
                            value = (...args: any) => {
                                try {
                                    return func(...args);
                                } catch (err) {
                                    props.appCache.dispStackTrace({ type: 'tag', data }, err);
                                }
                            }
                        }
                        // console.log(`${attr.key} = ${value}`);
                        attrs[attr.key] = value;
                    });
                }
            } catch (err) {
                dispStackTrace(err);
            }
            return (
                <_Wrap
                    ref={ref}
                    as={data.element}
                    src={src}
                    {...attrs}
                >
                    {innerJsx}
                </_Wrap>
            );
        }

        const memoDeps = props.data.memoDeps;
        let deps: any[] = [{}];
        if (memoDeps != undefined) {
            deps = memoDeps.map(dep => getFomulaAplliedValue(dep));
        }
        const jsx = useMemo(buildJsx, deps);

        return jsx;
        // }, [props.buildProps.globalStates, dummy]);

        // return jsx;
    }

    /**
     * useEffectを動的に呼び出すための仮想コンポーネント
     * @param props 
     * @returns 空のjsx
     */
    export const EffectTemp = (props: ReaderUtil.EffectObject) => {
        // console.log(props);
        useEffect(() => {
            props.callback();
        }, props.depItems);
        return <></>;
    }

    /**
     * useMemoを動的に呼び出すための仮想コンポーネント
     * @param props 
     * @returns 空のjsx
     */
    export const MemoTemp = (props: ReaderUtil.EffectObject) => {
        // console.log(props);
        useEffect(() => {
            props.callback();
        }, props.depItems);
        return <></>;
    }
}

export default AppPartialTag;


const _Wrap = styled.div<{
    src: string;
}>`${props => props.src}`;

