import { useContext, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { GlobalContext } from "../../../entry/systemEntry";
import FormUtil from "../../../../../common/component/form/formUtiil";
import StructManageTab from "../tree/structManageTab";
import assert from "assert";
import StoreProject from "../../../../redux/store/storeProject";
import TreeUtil from "../../../../../common/component/tree/treeUtil";
import Styles from "../../../../../def/design/styles";

namespace MarkNodeFrame {

    export type Cache = {
        rootNode: TreeUtil.ElementNode;
        isAdd: boolean;
    }

    type LocalState = {
        isEdit: boolean;
        name: FormUtil.CheckableValue;
        focus: number;
    }

    export const Component = (props: {
        manageState: StructManageTab.LocalState;
    }) => {
        const { store, setStore, dispatcher } = useContext(GlobalContext);

        const [ls, setLs] = useState<LocalState>({
            isEdit: false,
            name: { value: '', errors: [] },
            focus: -1
        });
        const invalidate = () => setLs({ ...ls });

        // const bookmarks = useMemo(() => {
        //     assert(store.project != null, 'store.projectがnullであってはならない。');
        //     return store.project.workData.bookmarks;
        // }, []);
        const bookmarks = props.manageState.bookmarks;

        useEffect(() => {
            const cache = store.system.freeCache as Cache;
            if (cache.isAdd) {
                ls.isEdit = true;
                ls.focus = bookmarks.length - 1;
                ls.name.value = bookmarks[ls.focus].name;
                invalidate();
            }
        }, []);

        const isSelected = ls.focus !== -1;
        return (
            <_Wrap>
                <FormUtil.Record
                    jsx={<>
                        <FormUtil.TextField
                            width={410}
                            isReadOnly={!ls.isEdit}
                            checkable={ls.name}
                            setCheckable={(checkable) => {
                                ls.name.value = checkable.value;
                                invalidate();
                            }}
                        />
                        <FormUtil.ButtonItem button={
                            {
                                label: 'Cancel',
                                width: 100, isEnable: isSelected && ls.isEdit,
                                callback: () => {
                                    ls.isEdit = false;
                                    ls.name.value = bookmarks[ls.focus].name;
                                    invalidate();
                                }
                            }
                        } />
                        <FormUtil.ButtonItem button={
                            !ls.isEdit ? {
                                label: 'Rename',
                                width: 106,
                                isEnable: isSelected,
                                callback: () => {
                                    ls.isEdit = true;
                                    invalidate();
                                }
                            } : {
                                label: 'Regist',
                                width: 106,
                                callback: () => {
                                    ls.isEdit = false;
                                    bookmarks[ls.focus].name = ls.name.value;
                                    invalidate();
                                }
                            }
                        } />
                    </>}
                />
                <_ListWrap
                    isEdit={ls.isEdit}
                >
                    {bookmarks.map((b, i) => {
                        return (
                            <_Record key={i}
                                isFocus={ls.focus === i}
                                onClick={() => {
                                    ls.focus = i;
                                    ls.name.value = b.name;
                                    invalidate();
                                }}
                            >
                                <_Name>{b.name}</_Name><_Key>{` [${b.id}]`}</_Key>
                            </_Record>
                        );
                    })}
                </_ListWrap>
                <FormUtil.ButtonRecord buttons={[
                    {
                        label: 'Delete', width: 100,
                        isEnable: isSelected && !ls.isEdit,
                        callback: () => {
                            bookmarks.splice(ls.focus, 1);
                            ls.focus = -1;
                            invalidate();
                        }
                    },
                    {
                        label: 'Switch',
                        width: 100,
                        isEnable: isSelected && !ls.isEdit,
                        callback: () => {
                            const ms = props.manageState;

                            const cache = store.system.freeCache as Cache;
                            // let targetNode = cache.rootNode;
                            // const bm = bookmarks[ls.focus];
                            // bm.key.split('.').forEach((indexStr) => {
                            //     const index = Number(indexStr);
                            //     targetNode = targetNode.children[index];
                            //     // console.log(index);
                            // });
                            ms.criteriaNode = bookmarks[ls.focus].node;
                            store.system.freeCache = null;
                            ms.hideMenu = 'off';
                            dispatcher.updateStore();
                        }
                    }
                ]}
                />
            </_Wrap>
        );
    }
}

export default MarkNodeFrame;

const _Wrap = styled.div`
    display: inline-block;
    width: 100%;
    height: 500px;
    background-color: #565b6b;
`;

const _ListWrap = styled.div<{
    isEdit: boolean;
}>`
    display: block;
    position: relative;
    width: 100%;
    height: calc(100% - 80px);
    background-color: #000000e1;
    ${props => !props.isEdit ? '' : Styles.CSS_DISABLE}
`;

const _Record = styled.div<{
    isFocus: boolean;
}>`
    display: block;
    width: 100%;
    height: 35px;
    background-color: #bdc4d1e0;
    ${props => !props.isFocus ? '' : css`
        background-color: #8dc8ccdf;
    `}
    margin: 1px 0 0 0;
    font-size: 22px;
    font-weight: 600;
    &:hover {
    background-color: #d4d9e2df;
        ${props => !props.isFocus ? '' : css`
            background-color: #a8dde0df;
        `}
    }
`;

const _Name = styled.span`
    color: #c44343;
`;
const _Key = styled.span`
    color: #f7f7f763;
`;